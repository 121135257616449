import { QueryParams } from '../core/enums/queryParams';

const isPlainText = (str: string) => str.includes('@');

const isUrlEncoded = (str: string) => {
	const regex = /%[0-9a-f]{2}/i;
	return regex.test(str);
};

const recursivelyDecodeEmail = (
	str: string,
	attempt = 1,
	decodeURIComponentFunc = decodeURIComponent
): string => {
	if (str && attempt <= 10) {
		if (isUrlEncoded(str)) {
			const decodedString = decodeURIComponentFunc(str);

			return isUrlEncoded(str) ?
				recursivelyDecodeEmail(decodedString, ++attempt, decodeURIComponentFunc) :
				decodedString;
		} else if (!isPlainText(str)) {
			try {
				const decodedString = atob(str);
				return isUrlEncoded(decodedString) ? recursivelyDecodeEmail(decodedString, ++attempt, decodeURIComponentFunc) :
					decodedString;
			} catch (error) {
				console.error('Unable to decode base64 email param.');
			}
		}
	}

	return str;
};

export const getEmailParam = (
	locationSearch = location.search
): string => {
	let emailParamValue = '';

	try {
		emailParamValue = locationSearch.split('?')[1]?.split(`${QueryParams.Email}=`)[1]?.split('&')[0] || '';
	} catch {
		console.error('Unable to get email param from search.');
	}

	return recursivelyDecodeEmail(emailParamValue);
};
