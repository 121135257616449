/* eslint-disable no-console */
import React, { useEffect, useState, FunctionComponent } from 'react';
import { ITransaction, Transaction } from '../../core/domain/module';
import { Messages } from '../../core/enums/messages';
import Spinner from '../Spinner/Spinner';
import styles from '../Loading/Loading.module.scss';

type Props = {
	transaction?: ITransaction;
	forcedMessage?: Messages;
	disableBackground?: boolean;
	setTimeoutFunc?: typeof setTimeout;
};

let messagesShown: Messages[] = [];

export const Loading: FunctionComponent<Props> = ({ transaction = Transaction.Instance(), forcedMessage, disableBackground, setTimeoutFunc = setTimeout }) => {
	const [transactionMessage, setTransactionMessage] = useState(forcedMessage || Messages.Empty);
	const [backgroundNone, setBackgroundNone] = useState(disableBackground);

	useEffect(() => {
		const subId = transaction.Message.Subscribe(async (message) => {
			messagesShown.push(message || Messages.Empty);
			if (message) {
				setTransactionMessage(messagesShown[0]);
			} else {
				setTimeoutFunc(() => {
					if (!messagesShown.slice().pop()) {
						messagesShown = [];
						setTransactionMessage(Messages.Empty);
					}
				}, 500);
			}
		});

		return () => {
			transaction.Message.Cancel(subId);
		};
	}, [forcedMessage]);

	useEffect(() => {
		if (!transactionMessage) {
			setBackgroundNone(disableBackground);
		}
	}, [transactionMessage]);

	return (
		<>
			{transactionMessage && (
				<>
					<div className={styles.overlay} />
					<div className={styles.modalWrap} style={backgroundNone ? { background: 'none' } : {}}>
						<div className={styles.modalInner}>
							<p className={styles.mainCta} dangerouslySetInnerHTML={{ __html: transactionMessage }}></p>
							<Spinner colorOverride="#4c4d4e" />
							<p className={styles.subText}>Please don't close this window.</p>
							<img className={styles.verifiedImage} src="/img/verified-secure-private.jpg" alt="Verified Secure and Private" />
						</div>
					</div>
				</>
			)}
		</>
	);
};
