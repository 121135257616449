import { Analytics, EventTypes } from '../core/services/analytics/module';
import { Transaction } from '../core/domain/module';
import { safelyExecute } from './safelyExecute';

export async function executeWithinBoundary(
	func: () => Promise<any | void>,
	transaction = Transaction.Instance(),
	analyticsService = Analytics.Instance(),
	safelyExecuteFunc = safelyExecute
) {
	await safelyExecuteFunc(func, (error) => {
		if (error instanceof Error) {
			analyticsService.Track(EventTypes.Error, {
				event_name: 'clientSideError',
				error_name: error.name,
				error_text: error.message,
				error_stack: error.stack?.slice(0, 200),
				user_id: transaction.Data.userObject?.id ? transaction.Data.userObject?.id : 'undefined'
			});
		}
	});
}
