/* eslint-disable complexity */
import { Field, ITransaction, Transaction } from '../../../core/domain/module';
import { Step } from '../../../core/domain/transaction/step';
import { DismissibleErrors, Messages } from '../../../core/enums/module';
import { Analytics, InternalEventNames, Steps } from '../../../core/services/analytics/module';
import { Auth } from '../../../core/services/auth/auth';
import { FieldTypes } from '../../fieldTypes';
import { required } from '../../fieldValidations/module';
import { DefaultSteps } from '../../default';
import { AmacIntegration } from '../../../core/services/amacIntegration/amacIntegration';
import { sendToMemberSiteIfMember } from '../../../utilities/sendToMemberSiteIfMember';

export enum PasswordLoginFields {
	Password = 'password'
}

const fields: Record<PasswordLoginFields, Field> = {
	[PasswordLoginFields.Password]: new Field('Password', 'Password', FieldTypes.Password, 'A password is required.', [
		required
	])
};

export const PasswordLogin = (
	auth = Auth.Instance(),
	analytics = Analytics.Instance(),
	transaction?: ITransaction,
	amacIntegrationEnabled = AmacIntegration.isEnabled(),
	sendToMemberSiteFunc = sendToMemberSiteIfMember
) =>
	new Step(fields, async () => {
		transaction = transaction || Transaction.Instance();

		analytics.TrackInternal({
			event_name: InternalEventNames.EnteredPassword,
			step: Steps.One
		});

		const fields = transaction.Steps[DefaultSteps.PasswordLogin].Fields;
		const email = transaction.Data.email;
		const password = fields[PasswordLoginFields.Password].GetValue();

		transaction.Message.Publish(Messages.LoggingIn);
		const loginResult = await auth.login(email, password);
		transaction.Message.Publish(Messages.Empty);
		if (loginResult.wasSuccessful) {
			analytics.TrackInternal({
				event_name: InternalEventNames.LogInSuccess,
				step: Steps.One
			});
			if (amacIntegrationEnabled) {
				sendToMemberSiteFunc();
			}
			return {
				wasSuccessful: true
			};
		} else {
			transaction.Error.Publish(DismissibleErrors.ErrorLoggingIn);
			return {
				wasSuccessful: false
			};
		}
	});
