import { Routes } from '../core/enums/routes';
import { Steps } from '../core/services/analytics/enums/steps';

export const RouteStepRecord: Record<Routes, Steps> = {
	[Routes.Root]: Steps.One,
	[Routes.Email]: Steps.One,
	[Routes.Existing]: Steps.One,
	[Routes.SignupVerification]: Steps.One,
	[Routes.SignupPassword]: Steps.One,
	[Routes.SignupNextRegistration]: Steps.One,
	[Routes.SignupRegistration]: Steps.One,
	[Routes.SignupLevel]: Steps.Two,
	[Routes.SignupChart]: Steps.Two,
	[Routes.SignupFrequency]: Steps.Two,
	[Routes.SignupSpouse]: Steps.Two,
	[Routes.SignupNextShipping]: Steps.Three,
	[Routes.SignupShipping]: Steps.Three,
	[Routes.SignupNextPayment]: Steps.Four,
	[Routes.SignupPayment]: Steps.Four,
	[Routes.SignupSummary]: Steps.Four,
	[Routes.SignupWelcome]: Steps.Four,
	[Routes.ProductPTU]: Steps.Four,
	[Routes.SingleClickPurchase]: Steps.Four,
	[Routes.SSO]: Steps.Four,
	[Routes.PostTransactionUpdatePassword]: Steps.Four,
	[Routes.PostTransactionReferral]: Steps.Four,
	[Routes.CartAbandonment]: Steps.One,
	[Routes.Error]: Steps.One
};
