import Environments from '@delta-defense/client-environments';
import { Result } from '../../domain/result';
import { Giveaway } from '../../models/giveaway';
import { IJasmine, Jasmine } from '../jasmine/jasmine';
import { IRest, Rest } from '../rest/rest';
import { Apis } from '@delta-defense/client-environments/environments/enums/apis';

const bonusDrawerGiveawayImageUri = '/img/entry-to-win.png';

export type GiveawayWithEntriesList = Giveaway & {
	entries: {
		image: string;
		description: string;
	}[]
}

export interface IGiveaways {
	getGiveawayCampaign(campaignId: string): Promise<Result<GiveawayWithEntriesList>>;
}

export class Giveaways implements IGiveaways {
	private static instance: IGiveaways | null = null;
	public static Instance(
		rest = Rest.Instance(),
		jasmine = Jasmine.Instance(),
		giveawaysProxyDomain = Environments.getProxyEndpointForApi(Apis.Giveaways)
	): IGiveaways { return this.instance || (this.instance = new Giveaways(rest, jasmine, giveawaysProxyDomain)); }
	public static Destroy = () => Giveaways.instance = null;

	private giveawayCampaignCache: Record<string, Result<GiveawayWithEntriesList>> = {};

	private constructor(
		private rest: IRest,
		private jasmine: IJasmine,
		private giveawaysProxyDomain: string
	) { }

	public async getGiveawayCampaign(campaignId: string): Promise<Result<GiveawayWithEntriesList>> {
		return this.giveawayCampaignCache[campaignId] ||
			(this.giveawayCampaignCache[campaignId] = await (async () => {
				const response = await this.rest.Get<{ data: Giveaway }>(`${this.giveawaysProxyDomain}/campaigns/${campaignId}`);
				if (response.ok && response.body?.data?.attributes) {
					const hasGrandPrize = response.body.data.attributes.showFinePrint;
					const hasGunADay = response.body.data.attributes.hasDailyDrawing;
					const offerStillValid = Date.now() < new Date(response.body.data.attributes.end).getTime();
					const success = (hasGrandPrize || hasGunADay) && offerStillValid;

					const giveawayWithEntriesList: GiveawayWithEntriesList = {
						...response.body.data,
						entries: []
					};

					hasGrandPrize && await this.setGrandPrizeGiveawayEntry(giveawayWithEntriesList);
					hasGunADay && await this.setGunADayGiveawayEntry(giveawayWithEntriesList);

					return {
						wasSuccessful: success,
						value: success ? giveawayWithEntriesList : undefined
					};
				} else {
					return {
						wasSuccessful: false
					};
				}
			})());
	}

	private setGrandPrizeGiveawayEntry(giveawayWithEntriesList: GiveawayWithEntriesList): void {
		const description = giveawayWithEntriesList.attributes.description;

		if (description) {
			giveawayWithEntriesList.entries.push({
				description,
				image: bonusDrawerGiveawayImageUri
			});
		}
	}

	private async setGunADayGiveawayEntry(giveawayWithEntriesList: GiveawayWithEntriesList): Promise<void> {
		const response = await this.jasmine.getGadSchedule();
		if (response.ok && response.body) {
			const image = response.body.image;
			const description = (() => {
				const value = parseFloat(response.body.value || '0');
				return `Chance to Win a ${response.body.name || 'new gun'} ($${value.toFixed(2)} Value)`;
			})();

			if (description && image) {
				giveawayWithEntriesList.entries.push({
					description: description,
					image: image
				});
			}
		}
	}
}
