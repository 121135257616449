import { SwiftWindFunnelIds, Levels } from '../core/enums/module';

type SwiftWindValue = { numberOfItems: number; monetaryValue?: number };

export type SwiftWindConfig = {
	description: string;
	imgSrc: string;
	value: SwiftWindValue | Record<Levels, SwiftWindValue>;
};

const swiftWindConfigurations: Record<SwiftWindFunnelIds, SwiftWindConfig> = {
	[SwiftWindFunnelIds.SixTacticalGuides]: {
		description: 'Tactical Guides and Book',
		imgSrc: 'https://www.usconcealedcarry.com/wp-content/uploads/2024/06/Checkout-Drawer-2-PDF-as-Bonus.png',
		value: {
			numberOfItems: 6,
			monetaryValue: 192
		}
	},
	[SwiftWindFunnelIds.SevenTacticalResources]: {
		description: 'Tactical Resources',
		imgSrc: 'https://www.usconcealedcarry.com/wp-content/uploads/2024/06/Checkout-Drawer-2-PDF-as-Bonus.png',
		value: {
			[Levels.Gold]: {
				numberOfItems: 7,
				monetaryValue: 199
			},
			[Levels.Platinum]: {
				numberOfItems: 9,
				monetaryValue: 373
			},
			[Levels.Elite]: {
				numberOfItems: 10,
				monetaryValue: 410
			}
		}
	},
	[SwiftWindFunnelIds.FiftyDollarsOffTraining]: {
		description: '$50 OFF In-Person Firearms Training',
		imgSrc: 'https://www.deltadefense.com/jasmine-assets/img/202408/50-dollar-bill.webp',
		value: {
			numberOfItems: 1
		}
	},
	[SwiftWindFunnelIds.MemberCard]: {
		description: 'Member Card with Post Incident Instructions',
		imgSrc: 'https://www.usconcealedcarry.com/wp-content/uploads/2024/08/membership-card-checkout.png',
		value: {
			numberOfItems: 1
		}
	},
	[SwiftWindFunnelIds.PartnerDiscounts]: {
		description: '$1,000+ in Self-Defense Discounts',
		imgSrc: 'https://www.usconcealedcarry.com/wp-content/uploads/2024/09/self-defense-discounts.webp',
		value: {
			numberOfItems: 1
		}
	}
};

export const getSwiftWindConfig = (funnelId: string): SwiftWindConfig | undefined => {
	return swiftWindConfigurations[funnelId];
};

export type SwiftWindBonus = {
	description: string;
	imgSrc: string;
	numberOfItems: number;
	monetaryValue: number;
};

export const getSwiftWindBonus = (
	funnelOrConfig: SwiftWindFunnelIds | SwiftWindConfig,
	level: Levels
): SwiftWindBonus | undefined => {
	const config = typeof funnelOrConfig == 'string' ? getSwiftWindConfig(funnelOrConfig) : funnelOrConfig;
	if (config) {
		const value: SwiftWindValue = config.value[level] || config.value;
		return (
			value && {
				description: config.description,
				imgSrc: config.imgSrc,
				numberOfItems: value.numberOfItems,
				monetaryValue: value.monetaryValue || 0
			}
		);
	}
};
