import { StateRestricted } from './core/enums/module';
import { StateAbbr } from './core/enums/stateAbbreviations';
import { StateNames } from './core/enums/stateNames';

export type StateOptions = {
	label: StateNames;
	value: StateAbbr;
	membershipsAvailable: boolean;
	restrictions?: StateRestricted[];
};

export const stateOptions: StateOptions[] = [
	{ label: StateNames.Alabama, value: StateAbbr.Alabama, membershipsAvailable: true },
	{
		label: StateNames.Alaska,

		value: StateAbbr.Alaska,
		membershipsAvailable: true,
		restrictions: [StateRestricted.AmmunitionShipping]
	},
	{ label: StateNames.Arizona, value: StateAbbr.Arizona, membershipsAvailable: true },
	{ label: StateNames.Arkansas, value: StateAbbr.Arkansas, membershipsAvailable: true },
	{
		label: StateNames.California,

		value: StateAbbr.California,
		membershipsAvailable: true,
		restrictions: [StateRestricted.AmmunitionShipping]
	},
	{ label: StateNames.Colorado, value: StateAbbr.Colorado, membershipsAvailable: true },
	{
		label: StateNames.Connecticut,

		value: StateAbbr.Connecticut,
		membershipsAvailable: true,
		restrictions: [StateRestricted.AmmunitionShipping]
	},
	{ label: StateNames.Delaware, value: StateAbbr.Delaware, membershipsAvailable: true },
	{
		label: StateNames.DistrictOfColumbia,

		value: StateAbbr.DistrictOfColumbia,
		membershipsAvailable: true,
		restrictions: [StateRestricted.AmmunitionShipping]
	},
	{ label: StateNames.Florida, value: StateAbbr.Florida, membershipsAvailable: true },
	{ label: StateNames.Georgia, value: StateAbbr.Georgia, membershipsAvailable: true },
	{
		label: StateNames.Hawaii,

		value: StateAbbr.Hawaii,
		membershipsAvailable: true,
		restrictions: [StateRestricted.AmmunitionShipping]
	},
	{ label: StateNames.Idaho, value: StateAbbr.Idaho, membershipsAvailable: true },
	{
		label: StateNames.Illinois,

		value: StateAbbr.Illinois,
		membershipsAvailable: true,
		restrictions: [StateRestricted.AmmunitionShipping]
	},
	{ label: StateNames.Indiana, value: StateAbbr.Indiana, membershipsAvailable: true },
	{ label: StateNames.Iowa, value: StateAbbr.Iowa, membershipsAvailable: true },
	{ label: StateNames.Kansas, value: StateAbbr.Kansas, membershipsAvailable: true },
	{ label: StateNames.Kentucky, value: StateAbbr.Kentucky, membershipsAvailable: true },
	{ label: StateNames.Louisiana, value: StateAbbr.Louisiana, membershipsAvailable: true },
	{ label: StateNames.Maine, value: StateAbbr.Maine, membershipsAvailable: true },
	{ label: StateNames.Maryland, value: StateAbbr.Maryland, membershipsAvailable: true },
	{
		label: StateNames.Massachusetts,
		value: StateAbbr.Massachusetts,
		membershipsAvailable: true,
		restrictions: [StateRestricted.AmmunitionShipping]
	},
	{ label: StateNames.Michigan, value: StateAbbr.Michigan, membershipsAvailable: true },
	{ label: StateNames.Minnesota, value: StateAbbr.Minnesota, membershipsAvailable: true },
	{ label: StateNames.Mississippi, value: StateAbbr.Mississippi, membershipsAvailable: true },
	{ label: StateNames.Missouri, value: StateAbbr.Missouri, membershipsAvailable: true },
	{ label: StateNames.Montana, value: StateAbbr.Montana, membershipsAvailable: true },
	{ label: StateNames.Nebraska, value: StateAbbr.Nebraska, membershipsAvailable: true },
	{ label: StateNames.Nevada, value: StateAbbr.Nevada, membershipsAvailable: true },
	{ label: StateNames.NewHampshire, value: StateAbbr.NewHampshire, membershipsAvailable: true },
	{
		label: StateNames.NewJersey,
		value: StateAbbr.NewJersey,
		membershipsAvailable: false,
		restrictions: [StateRestricted.AmmunitionShipping]
	},
	{ label: StateNames.NewMexico, value: StateAbbr.NewMexico, membershipsAvailable: true },
	{
		label: StateNames.NewYork,
		value: StateAbbr.NewYork,
		membershipsAvailable: false,
		restrictions: [StateRestricted.AmmunitionShipping]
	},
	{ label: StateNames.NorthCarolina, value: StateAbbr.NorthCarolina, membershipsAvailable: true },
	{ label: StateNames.NorthDakota, value: StateAbbr.NorthDakota, membershipsAvailable: true },
	{ label: StateNames.Ohio, value: StateAbbr.Ohio, membershipsAvailable: true },
	{ label: StateNames.Oklahoma, value: StateAbbr.Oklahoma, membershipsAvailable: true },
	{ label: StateNames.Oregon, value: StateAbbr.Oregon, membershipsAvailable: true },
	{ label: StateNames.Pennsylvania, value: StateAbbr.Pennsylvania, membershipsAvailable: true },
	{ label: StateNames.PuertoRico, value: StateAbbr.PuertoRico, membershipsAvailable: false },
	{ label: StateNames.RhodeIsland, value: StateAbbr.RhodeIsland, membershipsAvailable: true },
	{ label: StateNames.SouthCarolina, value: StateAbbr.SouthCarolina, membershipsAvailable: true },
	{ label: StateNames.SouthDakota, value: StateAbbr.SouthDakota, membershipsAvailable: true },
	{ label: StateNames.Tennessee, value: StateAbbr.Tennessee, membershipsAvailable: true },
	{ label: StateNames.Texas, value: StateAbbr.Texas, membershipsAvailable: true },
	{ label: StateNames.Utah, value: StateAbbr.Utah, membershipsAvailable: true },
	{ label: StateNames.USVirginIslands, value: StateAbbr.USVirginIslands, membershipsAvailable: false },
	{ label: StateNames.Vermont, value: StateAbbr.Vermont, membershipsAvailable: true },
	{ label: StateNames.Virginia, value: StateAbbr.Virginia, membershipsAvailable: true },
	{ label: StateNames.Washington, value: StateAbbr.Washington, membershipsAvailable: false },
	{ label: StateNames.WestVirginia, value: StateAbbr.WestVirginia, membershipsAvailable: true },
	{ label: StateNames.Wisconsin, value: StateAbbr.Wisconsin, membershipsAvailable: true },
	{ label: StateNames.Wyoming, value: StateAbbr.Wyoming, membershipsAvailable: true },
	{ label: StateNames.AA, value: StateAbbr.AA, membershipsAvailable: false },
	{ label: StateNames.AE, value: StateAbbr.AE, membershipsAvailable: false },
	{ label: StateNames.AP, value: StateAbbr.AP, membershipsAvailable: false }
];
