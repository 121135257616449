import { Product } from '@delta-defense/frontend-analytics/models/module';
import { ITransaction } from '../core/domain/transaction/ITransaction';
import { CheckoutTypes } from '../core/services/analytics/enums/module';
import { BundleProduct, SubscriptionProduct } from '../core/models/module';

const mapSubscriptionProduct = (sp: SubscriptionProduct, isSpouse = false): Product => {
	const level = sp.attributes.tier.toLowerCase().includes('magazine') ? 'ccm' : sp.attributes.tier.split(' ')[0];
	let frequency;
	if (sp?.attributes.paymentFrequency.toLowerCase() === 'monthly') {
		frequency = 'Monthly';
	} else if (sp?.attributes.paymentFrequency.toLowerCase() === 'annual') {
		frequency = 'Annual';
	}
	return {
		brand: 'USCCA',
		category:
			sp.attributes?.tags && sp.attributes.tags.find((tag) => tag.urlSafe === 'concealed-carry-magazine')
				? CheckoutTypes.Magazine
				: CheckoutTypes.Membership,
		name: sp.attributes.description,
		price: sp.attributes.price,
		product_id: sp.id,
		quantity: 1,
		variant: frequency,
		level,
		spouse: isSpouse ? 'true' : 'false'
	};
};

const mapBundleProduct = (bp: BundleProduct): Product => {
	return {
		brand: 'USCCA',
		category: 'Promo Products',
		name: bp?.attributes?.description || '',
		price: 0,
		product_id: bp?.id || '',
		quantity: 1
	};
};

export const getProductsToTrack = (transaction: ITransaction): Product[] => {
	let productsToTrack: Product[] = [];
	const purchaseProducts = transaction.Data.purchaseProducts;

	if (purchaseProducts?.primary) {
		productsToTrack.push(mapSubscriptionProduct(purchaseProducts.primary));
	}

	if (purchaseProducts?.spouse) {
		productsToTrack.push(mapSubscriptionProduct(purchaseProducts.spouse, true));
	}

	productsToTrack = productsToTrack.concat((transaction.Data.bundleProducts || []).map(mapBundleProduct));

	return productsToTrack;
};
