export enum ProductTiers {
	CCM = 'Concealed Carry Magazine',
	Silver = 'Silver Membership',
	Gold = 'Gold Membership',
	Platinum = 'Platinum Membership',
	Elite = 'Elite Membership',
	GenericSpouse = 'Spouse Membership',
	GoldSpouse = 'Gold Spouse Membership',
	PlatinumSpouse = 'Platinum Spouse Membership',
	EliteSpouse = 'Elite Spouse Membership'
}

export const ProductTiersLevelMap = new Map<ProductTiers, string>([
	[ProductTiers.CCM, 'CCM'],
	[ProductTiers.Elite, 'Elite'],
	[ProductTiers.EliteSpouse, 'Elite'],
	[ProductTiers.Gold, 'Gold'],
	[ProductTiers.GoldSpouse, 'Gold'],
	[ProductTiers.Platinum, 'Platinum'],
	[ProductTiers.PlatinumSpouse, 'Platinum']
]);
