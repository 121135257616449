import { Transaction } from '../core/domain/module';
import { QueryParams } from '../core/enums/queryParams';
import { SubscriptionProduct } from '../core/models/subscription/subscriptionProduct';
import { CookieKeys, Cookies } from '../core/services/module';
import { getQueryParameter } from './getQueryParameter';

export type PurchaseData = {
	membership?: {
		productId: string;
		referralCode?: string;
		addons?: {
			spouse: {
				productId: string;
				name?: string;
				surname?: string;
				email?: string;
			};
		};
	};
	magazine?: {
		productId: string;
	};
};

export const getPurchaseData = (
	noReferral = false,
	transaction = Transaction.Instance(),
	cookies = Cookies.Instance(),
	locationSearch = location.search
): PurchaseData => {
	const purchaseProducts = transaction.Data.purchaseProducts || {};
	const spouseInfo = transaction.Data.spouseInfo;
	const referral = getQueryParameter(QueryParams.ReferralCode, locationSearch) || cookies.get(CookieKeys.Referral);

	const activeSubs =
		transaction.Data.customerSubscriptions?.filter((sub) => sub?.attributes?.status === 'active') || [];
	const currentSpouseSub = activeSubs.find((sub) => ['spouse-membership'].includes(sub?.attributes?.type));

	let purchaseData: PurchaseData;

	if (purchaseProducts.primary?.attributes.tier != 'Concealed Carry Magazine') {
		purchaseData = {
			membership: getMembershipData(purchaseProducts, referral, noReferral, spouseInfo, !currentSpouseSub)
		};
	} else {
		purchaseData = {
			magazine: {
				productId: purchaseProducts.primary?.id || ''
			}
		};
	}

	return purchaseData;
};

function getMembershipData(
	purchaseProducts: { primary?: SubscriptionProduct; spouse?: SubscriptionProduct },
	referral: string,
	noReferral: boolean,
	spouseInfo: { email?: string; firstName?: string; lastName?: string } | undefined,
	spouseIsNew: boolean
) {
	const membership = {
		productId: purchaseProducts.primary?.id || '',
		referralCode: referral && !noReferral ? referral : undefined,
		addons: purchaseProducts.spouse
			? {
					spouse: {
						productId: purchaseProducts.spouse.id,
						name: spouseInfo?.firstName,
						surname: spouseInfo?.lastName,
						email: spouseInfo?.email,
						isNew: spouseIsNew
					}
			  }
			: undefined
	};

	Object.keys(membership).forEach((k) => {
		if (!membership[k]) {
			delete membership[k];
		}
	});

	return membership;
}
