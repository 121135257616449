import { QueryParams } from '../core/enums/queryParams';

export interface CheckoutURLSearchParams extends URLSearchParams {
	get(name: QueryParams): string | null;
	set(name: QueryParams, value: string): void;
	has(name: QueryParams): boolean;
	delete(name: QueryParams): boolean;
}

export const getQueryParameter = (
	param: QueryParams,
	locationSearch = location.search
): string | null => {
	const params = new URLSearchParams(locationSearch);
	return params.get(param);
};

export const getQueryParameters = (
	locationSearch = location.search
): CheckoutURLSearchParams => {
	return new URLSearchParams(locationSearch) as CheckoutURLSearchParams;
};
