export enum InteractionEventNames {
	ModalFired = 'modal_fired',
	CtaClick = 'cta_click',
	LevelClick = 'level_click',
	UseShippingAddressChecked = 'use_shipping_address_checked',
	UseShippingAddressUnchecked = 'use_shipping_address_unchecked',
	RadioButtonClick = 'radio_button_click',
	CheckboxCheck = 'checkbox_check',
	CheckboxUncheck = 'checkbox_uncheck',
	LinkClick = 'link_click',
	AddressValidationModal = 'address_validation_modal'
}
