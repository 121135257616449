import { Levels } from '../../core/enums/levels';
import { BundleProduct } from '../../core/models/bundleProduct';

export enum BundlesByTierKeys {
	Elite = 'elite',
	Platinum = 'platinum',
	Gold = 'gold',
	EliteAndPlatinum = 'eliteAndPlatinum',
	PlatinumAndGold = 'platinumAndGold',
	EliteAndGold = 'eliteAndGold',
	AllTiers = 'allTiers'
}

export type BundlesByTier = {
	[BundlesByTierKeys.Elite]: BundleProduct[];
	[BundlesByTierKeys.Platinum]: BundleProduct[];
	[BundlesByTierKeys.Gold]: BundleProduct[];
	[BundlesByTierKeys.EliteAndPlatinum]: BundleProduct[];
	[BundlesByTierKeys.PlatinumAndGold]: BundleProduct[];
	[BundlesByTierKeys.EliteAndGold]: BundleProduct[];
	[BundlesByTierKeys.AllTiers]: BundleProduct[];
}

export const BundlesByTierKeysLabelRecord: Record<BundlesByTierKeys, string> = {
	[BundlesByTierKeys.Elite]: 'Elite members',
	[BundlesByTierKeys.Platinum]: 'Platinum members',
	[BundlesByTierKeys.Gold]: 'Gold members',
	[BundlesByTierKeys.EliteAndPlatinum]: 'Platinum & Elite members',
	[BundlesByTierKeys.PlatinumAndGold]: 'Platinum & Gold members',
	[BundlesByTierKeys.EliteAndGold]: 'Gold & Elite members',
	[BundlesByTierKeys.AllTiers]: 'All new members'
};

function updateBundlesByTier(allBundles: Record<Levels, BundleProduct[]>, currentTier: Levels, valueToUpdate: BundlesByTier) {
	const [altTierOne, altTierTwo] = Object.keys(allBundles).filter((t) => t !== currentTier) as Levels[];
	const returnValueKeys = Object.keys(valueToUpdate);
	if (allBundles[currentTier]) {
		// eslint-disable-next-line complexity
		allBundles[currentTier].forEach((b1) => {
			const isInAltOne = !!allBundles[altTierOne].find((b2) => b2?.id === b1?.id);
			const isInAltTwo = !!allBundles[altTierTwo].find((b3) => b3?.id === b1?.id);
			if (isInAltOne && isInAltTwo) {
				const alreadyIsInReturnValue = valueToUpdate.allTiers.find((b) => b?.id === b1?.id);
				if (!alreadyIsInReturnValue) {
					valueToUpdate.allTiers.push(b1);
				}
			} else if (isInAltOne || isInAltTwo) {
				const keyNeeded = returnValueKeys.find(
					(k) =>
						(k.toLowerCase().includes(currentTier) && k.toLowerCase().includes(altTierOne)) ||
						(k.toLowerCase().includes(currentTier) && k.toLowerCase().includes(altTierTwo))
				);
				if (keyNeeded && valueToUpdate[keyNeeded] && Array.isArray(valueToUpdate[keyNeeded])) {
					const alreadyIsInReturnValue = valueToUpdate[keyNeeded].find((b) => b?.id === b1?.id);
					if (!alreadyIsInReturnValue) {
						valueToUpdate[keyNeeded].push(b1);
					}
				}
			} else {
				const alreadyIsInReturnValue = valueToUpdate[currentTier].find((b) => b?.id === b1?.id);
				if (!alreadyIsInReturnValue) {
					valueToUpdate[currentTier].push(b1);
				}
			}
		});
	}
	return valueToUpdate;
}

export function returnBundlesByTier(allBundles: Record<Levels, BundleProduct[]>) {
	let returnValue: BundlesByTier = {
		elite: [],
		platinum: [],
		gold: [],
		eliteAndPlatinum: [],
		platinumAndGold: [],
		eliteAndGold: [],
		allTiers: []
	};
	returnValue = updateBundlesByTier(allBundles, Levels.Elite, returnValue);
	returnValue = updateBundlesByTier(allBundles, Levels.Platinum, returnValue);
	returnValue = updateBundlesByTier(allBundles, Levels.Gold, returnValue);
	return returnValue;
}
