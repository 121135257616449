import { IFeatureFlagRepository } from '@delta-defense/feature-flag-service/service/iFeatureFlagRepository';
import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { Routes } from '../../core/enums/module';
import { Analytics, IAnalytics, Locations, backButtonClicked } from '../../core/services/analytics/module';
import { getRouteBackLink } from '../../utilities/getRouteBackLink';
import { Icons } from '../../icons';
import styles from './backButton.module.scss';

type Props = {
	getPathnameFunc?: () => string;
	LinkComponent?: typeof Link;
	analytics?: IAnalytics;
	additionalClass?: string;
	features?: IFeatureFlagRepository
};

export const BackButton: React.FunctionComponent<Props> = ({
	getPathnameFunc = () => globalThis.location.pathname,
	LinkComponent = Link,
	analytics = Analytics.Instance(),
	additionalClass
}) => {
	const [backLinkLocation, setBackLinkLocation] = useState<string>();

	useEffect(() => {
		setBackLinkLocation(getRouteBackLink(getPathnameFunc() as Routes) || '');
	});

	return (<>
		{!!backLinkLocation && <>
			<LinkComponent href={`${backLinkLocation}${location.search}`}>
				<a onClick={() => {
					analytics.TrackInteraction(backButtonClicked(Locations.Content));
				}} className={`${styles.backLinkHeader} ${additionalClass ? additionalClass : ''}`}>{Icons.LeftArrow}Back</a>
			</LinkComponent>
		</>}
	</>
	);
};
