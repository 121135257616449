/* eslint-disable complexity */
import { ITransaction, Transaction } from '../../../core/domain/module';
import { Field } from '../../../core/domain/transaction/field';
import { Step } from '../../../core/domain/transaction/step';
import { Messages } from '../../../core/enums/messages';
import {
	Analytics,
	InternalEventNames,
	Steps
} from '../../../core/services/analytics/module';
import { Auth } from '../../../core/services/auth/auth';
import { FieldTypes } from '../../fieldTypes';
import { email, required } from '../../fieldValidations/module';
import { DefaultSteps } from '../../default';
import { BrowserStorage, Tracking } from '../../../core/services/module';
import { LocalStorageKeys } from '../../../core/services/browserStorage/keys/localStorageKeys';

export enum EmailCheckFields {
	Email = 'email'
}

export type EmailCheckStepResult = {
	accountExists: boolean;
};

const fields: Record<EmailCheckFields, Field> = {
	[EmailCheckFields.Email]: new Field(
		'Email',
		'Email Address',
		FieldTypes.Email,
		'A valid email address is required.',
		[required, email]
	)
};

export const EmailCheck = (
	transaction?: ITransaction,
	analytics = Analytics.Instance(),
	auth = Auth.Instance(),
	mainWindow = window,
	localStorageService = BrowserStorage.LocalInstance(),
	tracking = Tracking.Instance()
) =>
	new Step<EmailCheckStepResult>(fields, async () => {
		transaction = transaction || Transaction.Instance();
		localStorageService.removeItem(LocalStorageKeys.EmailThatPasswordWasGeneratedFor);

		const fields = transaction.Steps[DefaultSteps.EmailCheck].Fields;
		analytics.TrackInternal({
			event_name: InternalEventNames.TotalNumberOfEmails,
			step: Steps.One
		});

		const email = fields[EmailCheckFields.Email].GetValue();
		transaction.Data.email = email;

		transaction.Message.Publish(Messages.CheckingYourEmail);
		const accountExistsResult = await auth.doesAccountExist(email);
		transaction.Message.Publish(Messages.Empty);
		if (accountExistsResult.wasSuccessful) {
			const storedAltEmails = localStorageService.getItem(LocalStorageKeys.UnsubscribeEmail);
			if (storedAltEmails) {
				const emailList: string[] = [];
				storedAltEmails.toUnSubOnPurchase.forEach((v) => {
					if (v !== email) {
						emailList.push(v);
					}
				});
				if (storedAltEmails.finalEmail !== email) {
					emailList.push(storedAltEmails.finalEmail);
				}
				if (emailList.length) {
					localStorageService.setItem(LocalStorageKeys.UnsubscribeEmail, { ...storedAltEmails, toUnSubOnPurchase: emailList, finalEmail: email });
				}
			}
			analytics.Identify({ email: email });
			mainWindow.deltaAnalytics?.submitLead({
				email,
				tID: tracking.getTid()
			});
		}

		return {
			wasSuccessful: accountExistsResult.wasSuccessful,
			value: {
				accountExists: !!accountExistsResult.value
			}
		};
	});
