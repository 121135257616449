import { InteractionEvent } from '../../eventTypes/interactionEvent';
import { InteractionEventNames, Locations, Steps } from '../../enums/module';
import { RouteStepRecord } from '../../../../../utilities/routeStepRecord';
import { stepToFormFieldRecord } from '../../stepToFormFieldRecord';

export const backButtonClicked = (
	location: Locations,
	locationPathname = window.location.pathname
): InteractionEvent => {
	const step = RouteStepRecord[locationPathname] || Steps.One;
	return {
		event_name: InteractionEventNames.CtaClick,
		events: {
			click_text: 'Back',
			form_field: stepToFormFieldRecord[step],
			location: location,
			sub_location: `Step ${step}`
		},
		step: step
	};
};
