import { Field, Transaction } from '../../../core/domain/module';
import { Step } from '../../../core/domain/transaction/step';
import { Messages } from '../../../core/enums/messages';
import { Subscriptions } from '../../../core/services/subscriptions/subscriptions';
import { Bundle } from '../../../core/services/bundle/bundle';
import { FieldTypes } from '../../fieldTypes';
import { DefaultSteps } from '../../default';
import { PaymentFrequencies } from '../../../core/enums/paymentFrequencies';

export enum LevelSelectionFields {
	LevelSelection = 'levelSelection'
}

const fields = {
	[LevelSelectionFields.LevelSelection]: new Field(
		LevelSelectionFields.LevelSelection,
		'Level Selection',
		FieldTypes.LevelSelectionChart,
		'',
		[]
	)
};

export const LevelSelection = (
	subscriptions = Subscriptions.Instance(),
	bundle = Bundle.Instance(),
	transactionFunc = () => Transaction.Instance()
) =>
	new Step(fields, async () => {
		const transaction = transactionFunc();
		transaction.Message.Publish(Messages.LoadingProductsForLevel);
		const productLevel = transaction.Steps[DefaultSteps.LevelSelection].Fields[LevelSelectionFields.LevelSelection].GetValue();
		const productsByTierResult = await subscriptions.getProductsByTier(
			productLevel
		);
		const productsRetrieved = !!(productsByTierResult.wasSuccessful && productsByTierResult.value);
		transaction.Message.Publish(Messages.Empty);

		if (productsRetrieved) {
			transaction.Data.productData = productsByTierResult.value;
			transaction.SetPurchaseProducts(PaymentFrequencies.Monthly);
		}

		await bundle.setBundleProductsInTransaction(true);

		return {
			wasSuccessful: productsRetrieved
		};
	});
