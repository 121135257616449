import { getProductTcv } from './getProductTcv';
import { BundleProduct, SubscriptionProduct } from '../core/models/module';

export function getProductsForAnalytics(
	purchaseProducts?: {
		primary?: SubscriptionProduct,
		spouse?: SubscriptionProduct
	},
	bundleProducts?: BundleProduct[]
) {
	const productsToReturn = new Array();
	if (purchaseProducts) {
		Object.keys(purchaseProducts).forEach(function (s) {
			const product = purchaseProducts[s];
			if (product) {
				const frequency = product.attributes.paymentFrequency.charAt(0).toUpperCase() + product.attributes.paymentFrequency.slice(1);
				const level = product.attributes.tier.toLowerCase().includes('magazine') ? 'ccm' : product.attributes.tier.split(' ')[0];
				const spouse = s === 'primary' ? 'false' : 'true';
				const tcv = getProductTcv(frequency.toLowerCase(), level.toLowerCase(), product.attributes.discountPercentage, spouse);

				productsToReturn.push({
					name: product.attributes.description,
					id: product.id,
					price: product.attributes.price.toFixed(2).toString(),
					brand: 'USCCA',
					category: level === 'ccm' ? 'Magazine' : 'Membership',
					level: level,
					variant: frequency,
					quantity: 1,
					spouse: spouse,
					tcv: tcv.toFixed(2)
				});
			}
		});

		if (bundleProducts) {
			Object.keys(bundleProducts).forEach(function (s) {
				const product = bundleProducts[s];
				if (product) {
					productsToReturn.push({
						name: product.attributes.description,
						id: product.id,
						price: '0.00',
						brand: 'USCCA',
						category: 'Promo Products',
						quantity: 1,
						tcv: '0.00'
					});
				}
			});
		}
	}

	return productsToReturn;
}
