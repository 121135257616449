export enum DismissibleErrors {
	CreditCardError = 'credit_card_error',
	UserSessionExpired = 'user_session_expired',
	ErrorUpdatingUser = 'error_updating_user',
	ErrorUpdatingCustomer = 'error_updating_customer',
	ErrorCreatingCustomer = 'error_creating_customer',
	ErrorCreatingAccount = 'error_creating_account',
	ErrorUpdatingPassword = 'error_updating_password',
	ErrorLoggingIn = 'error_logging_in',
	ErrorPurchasingPtu = 'error_purchasing_ptu',
	InvalidVerificationCode = 'invalid_verification_code',
	ExpiredVerificationCode = 'expired_verification_code',
	ErrorSubmittingVerificationCode = 'error_submitting_verification_code',
	ErrorProcessingOrder = 'error_processing_order',
	EmailRequestedWithNoAddressSpecified = 'email_requested_with_no_address_specified',
	ErrorSendingVerificationEmail = 'error_sending_verification_email',
	ErrorLoadingCartPreview = 'error_loading_cart_preview',
	InvalidMembershipLocation = 'invalid_membership_location',
	UserTryingToDowngrade = 'user_trying_to_downgrade',
	CreditCardSecurityError = 'error_recaptcha',
	AMACExtensionAlreadyRedeemed = 'amac_extension_already_redeemed',
	AMACExtensionEncounteredError = 'amac_extension_encountered_error'
}
