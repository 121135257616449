export enum PaymentFields {
  MembershipTerms = 'membershipTerms',
  CCMTerms = 'ccmTerms',
  SigSauerGeneralTerms = 'sigSauerGeneralTerms',
  SigSauerAmmoTerms = 'sigSauerAmmoTerms',
  SigSauerOpticTerms = 'sigSauerOpticTerms',
  SigSauerGiftCard = 'sigSauerGiftCardTerms',
  BerettaGeneralTerms = 'berettaGeneralTerms',
  Beretta250Terms = 'beretta250Terms',
  GiftCardGeneralTerms = 'giftCardGeneralTerms',
  GiftCard75Terms = 'giftCard75Terms',
  BrantleyGilbertTerms = 'brantleyGilbertTerms',
  UseCardOnFile = 'useCardOnFile',
  PewPewTerms = 'pewPewTerms',
  PrimaryArmsOptic = 'primaryArmsOptic'
}
