export enum Routes {
	Root = '/',
	SSO = '/sso',
	Email = '/signup/email',
	Existing = '/existing',
	SignupVerification = '/signup/verification',
	SignupPassword = '/signup/password',
	SignupNextRegistration = '/signup/next-registration',
	SignupRegistration = '/signup/registration',
	SignupLevel = '/signup/level',
	SignupChart = '/signup/chart',
	SignupFrequency = '/signup/frequency',
	SignupSpouse = '/signup/spouse',
	SignupNextShipping = '/signup/next-shipping',
	SignupShipping = '/signup/shipping',
	SignupNextPayment = '/signup/next-payment',
	SignupPayment = '/signup/payment',
	SignupSummary = '/signup/summary',
	SignupWelcome = '/signup/welcome',
	SingleClickPurchase = '/single-click-purchase',
	PostTransactionUpdatePassword = '/signup/update-password',
	PostTransactionReferral = '/signup/post-transaction-referral',
	ProductPTU = '/signup/bonus-offer',
	CartAbandonment = '/ca',
	Error = '/error'
}
