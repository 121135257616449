import { Jasmine } from '../core/services/jasmine/jasmine';
import { Tracking } from '../core/services/tracking/tracking';

export const getGiveawayId = async (
	tracking = Tracking.Instance(),
	jasmine = Jasmine.Instance()
): Promise<string | null> => {
	const funnelId = (await tracking.getFunnelId()).value;
	const funnelData = funnelId ? await jasmine.getFunnelData(funnelId) : null;

	return funnelData?.value?.giveaway_id || null;
};
