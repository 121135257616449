export enum InternalEventNames {
	LongRunningSubmission = 'long_running_submission',
	TotalNumberOfEmails = 'total_number_of_emails',
	AccountExists = 'account_exists',
	NewEmailEntered = 'new_email_entered',
	LogInSuccess = 'log_in_success',
	VerificationCodeEntered = 'verification_code_entered',
	VerificationCodeSent = 'verification_code_sent',
	VerificationCodeResent = 'verification_code_resent',
	VerificationCodeSuccess = 'verification_code_success',
	AlreadyLoggedIn = 'already_logged_in',
	EnteredPassword = 'entered_password',
	FailedToGetDigitalSignature = 'failed_to_get_digital_signature',
	FailedToGetGunADayData = 'failed_to_get_gun_a_day_data',
	FailedToGetTrackingInfoFromCookie = 'failed_to_get_tracking_info_from_cookie',
	FailedToGetPreviewForNewSubscription = 'failed_to_get_preview_for_new_subscription',
	ReAttemptingToGetCustomerData = 're_attempting_to_get_customer_data',
	ReAttemptingToGetUserData = 're_attempting_to_get_user_data',
	FeatureFlagsLoaded = 'feature_flags_loaded',
	FeatureFlagsTimeout = 'feature_flags_timeout',
	ValidationFail = 'validation_fail',
	PTUProcessingError = 'ptu_processing_error',
	SuccessfullyRestoredCart = 'successfully_restored_cart',
	FailedToRestoreCart = 'failed_to_restore_cart',
	ResubmittingOrderAfterFailure = 'resubmitting_order_after_failure',
	UsedAltEmail = 'used_alt_email',
	FeatureFlagChecked = 'feature_flag_checked'
}
