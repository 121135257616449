import dayjs from 'dayjs';
import { Routes, QueryParams } from '../core/enums/module';
import { Analytics, DataLayerEvents } from '../core/services/analytics/module';
import { Auth, IAuth } from '../core/services/auth/auth';
import { ISubscriptions, Subscriptions } from '../core/services/subscriptions/subscriptions';
import { Tracking } from '../core/services/tracking/tracking';
import { getQueryParameter } from './getQueryParameter';
import { LeadData } from '../core/models/leadData';

enum AccountTypes {
	Spouse = 'spouse',
	Primary = 'primary'
}

enum MemberLevels {
	Silver = 'silver',
	Gold = 'gold',
	Platinum = 'platinum',
	Elite = 'elite',
	Lifetime = 'lifetime'
}

enum UserTypes {
	Member = 'member',
	CCM = 'ccm',
	FreeAccount = 'free account',
	Unknown = 'unknown'
}

type GAEventData = {
	page: {
		category: string;
		subcategory: string;
		type: string;
	};
	funnel_id?: string;
	user?: {
		loggedIn: 1 | 0;
		id?: string;
		type: UserTypes;
	};
	member?: {
		accountType: AccountTypes;
		level: MemberLevels | UserTypes.CCM | null;
		startDate: string;
		expirationDate: string;
		status?: string;
	};
	lead?: any;
};

export const loadDataLayer = async (
	subscriptions = Subscriptions.Instance(),
	auth = Auth.Instance(),
	analytics = Analytics.Instance(),
	locationPathname = location.pathname,
	tracking = Tracking.Instance()
): Promise<GAEventData> => {
	const pathnameMap = new Map<string, { pageName: string; pageType: string }>([
		[
			'/invoice',
			{
				pageName: 'Invoice',
				pageType: 'order confirmation'
			}
		],
		[
			Routes.SignupWelcome,
			{
				pageName: 'Invoice',
				pageType: 'order confirmation'
			}
		],
		[
			'/error',
			{
				pageName: 'Error',
				pageType: 'error'
			}
		]
	]);

	const { pageName, pageType } = pathnameMap.get(locationPathname) || { pageName: 'Form', pageType: 'checkout' };

	const dataToSend: GAEventData = {
		page: {
			category: 'Checkout',
			subcategory: pageName,
			type: pageType
		}
	};

	if (auth.accessTokenObject?.userId) {
		await setUserAndMemberDataForAuthedUser(subscriptions, dataToSend, auth);

		if (dataToSend.user?.type === UserTypes.Member) {
			analytics.FireDataLayerEvent(DataLayerEvents.MemberLoaded, dataToSend);
		} else {
			analytics.FireDataLayerEvent(DataLayerEvents.LeadLoaded, dataToSend);
		}
	} else {
		dataToSend.user = {
			loggedIn: 0,
			type: UserTypes.Unknown
		};
	}

	const trackingInfo: Partial<LeadData & { tid: string }> = await tracking.getTrackingFields();
	const funnelId = await tracking.getFunnelId();
	trackingInfo.tid = trackingInfo.tID;
	delete trackingInfo.tID;
	dataToSend.lead = trackingInfo;
	if (funnelId?.value) {
		dataToSend.funnel_id = funnelId.value;
	}

	analytics.FireDataLayerEvent(DataLayerEvents.Loaded, dataToSend);
	analytics.FireDataLayerEvent(DataLayerEvents.OptimizeActivate);

	return dataToSend;
};

// eslint-disable-next-line complexity
async function setUserAndMemberDataForAuthedUser(subscriptions: ISubscriptions, dataToSend: GAEventData, auth: IAuth) {
	const customerDataResult = await subscriptions.getCustomer();
	const activeSubs =
		customerDataResult.value?.subscriptions?.filter((sub) => sub?.attributes.status === 'active') || [];
	const primarySub = activeSubs.find((sub) => ['membership', 'magazine'].includes(sub?.attributes?.type));
	const spouseSub = activeSubs.find((sub) => sub?.attributes?.type === 'spouse-membership');

	let memberLevel: MemberLevels | UserTypes.CCM | null = null;
	let type = UserTypes.FreeAccount;
	const product = primarySub?.attributes?.product.toLowerCase() || '';
	const levelFromUrl = getQueryParameter(QueryParams.Level);

	Object.values(MemberLevels).forEach((level) => {
		if (product.includes(level) || levelFromUrl === level) {
			memberLevel = level;
			type = UserTypes.Member;
		}
	});

	if (product.includes('magazine') || levelFromUrl === UserTypes.CCM) {
		memberLevel = UserTypes.CCM;
		type = UserTypes.CCM;
	}

	dataToSend.user = {
		loggedIn: 1,
		id: auth?.accessTokenObject?.userId || '',
		type: type
	};

	if (primarySub || spouseSub) {
		const subscriptionRole = spouseSub && !primarySub ? spouseSub : primarySub;

		dataToSend.member = {
			accountType: spouseSub && !primarySub ? AccountTypes.Spouse : AccountTypes.Primary,
			level: memberLevel,
			startDate: dayjs(subscriptionRole?.attributes.termStart).format('YYYY-MM-DD'),
			expirationDate: dayjs(subscriptionRole?.attributes.termEnd).format('YYYY-MM-DD'),
			status: subscriptionRole?.attributes?.status
		};
	}
}
