/* eslint-disable max-lines */
export const getProductTcv = (requestedFrequency, requestedLevel, discountPercentage, hasSpouse) => {
	const tcvs = [
		{
			frequency: 'annual',
			discount: 0,
			level: 'elite',
			spouse: 'false',
			price: 499,
			tcv: 499
		},
		{
			frequency: 'monthly',
			discount: 0,
			level: 'elite',
			spouse: 'false',
			price: 49,
			tcv: 588
		},
		{
			frequency: 'annual',
			discount: 10,
			level: 'elite',
			spouse: 'false',
			price: 449.1,
			tcv: 449.1
		},
		{
			frequency: 'monthly',
			discount: 10,
			level: 'elite',
			spouse: 'false',
			price: 44.09,
			tcv: 529.08
		},
		{
			frequency: 'annual',
			discount: 15,
			level: 'elite',
			spouse: 'false',
			price: 424.15,
			tcv: 424.15
		},
		{
			frequency: 'monthly',
			discount: 15,
			level: 'elite',
			spouse: 'false',
			price: 41.63,
			tcv: 499.56
		},
		{
			frequency: 'annual',
			discount: 20,
			level: 'elite',
			spouse: 'false',
			price: 399.2,
			tcv: 399.2
		},
		{
			frequency: 'annual',
			discount: 50,
			level: 'elite',
			spouse: 'false',
			price: 249.5,
			tcv: 249.5
		},
		{
			frequency: 'annual',
			discount: 100,
			level: 'elite',
			spouse: 'false',
			price: 0,
			tcv: 0
		},
		{
			frequency: 'annual',
			discount: 0,
			level: 'elite',
			spouse: 'true',
			price: 249,
			tcv: 249
		},
		{
			frequency: 'monthly',
			discount: 0,
			level: 'elite',
			spouse: 'true',
			price: 24,
			tcv: 288
		},
		{
			frequency: 'annual',
			discount: 100,
			level: 'elite',
			spouse: 'true',
			price: 0,
			tcv: 0
		},
		{
			frequency: 'annual',
			discount: 0,
			level: 'platinum',
			spouse: 'false',
			price: 399,
			tcv: 399
		},
		{
			frequency: 'monthly',
			discount: 0,
			level: 'platinum',
			spouse: 'false',
			price: 39,
			tcv: 468
		},
		{
			frequency: 'annual',
			discount: 10,
			level: 'platinum',
			spouse: 'false',
			price: 359.1,
			tcv: 359.1
		},
		{
			frequency: 'monthly',
			discount: 10,
			level: 'platinum',
			spouse: 'false',
			price: 35.09,
			tcv: 421.08
		},
		{
			frequency: 'annual',
			discount: 15,
			level: 'platinum',
			spouse: 'false',
			price: 339.15,
			tcv: 339.15
		},
		{
			frequency: 'monthly',
			discount: 15,
			level: 'platinum',
			spouse: 'false',
			price: 33.13,
			tcv: 397.56
		},
		{
			frequency: 'annual',
			discount: 20,
			level: 'platinum',
			spouse: 'false',
			price: 319.2,
			tcv: 319.2
		},
		{
			frequency: 'annual',
			discount: 50,
			level: 'platinum',
			spouse: 'false',
			price: 199.5,
			tcv: 199.5
		},
		{
			frequency: 'annual',
			discount: 100,
			level: 'platinum',
			spouse: 'false',
			price: 0,
			tcv: 0
		},
		{
			frequency: 'annual',
			discount: 0,
			level: 'platinum',
			spouse: 'true',
			price: 199,
			tcv: 199
		},
		{
			frequency: 'monthly',
			discount: 0,
			level: 'platinum',
			spouse: 'true',
			price: 19,
			tcv: 228
		},
		{
			frequency: 'annual',
			discount: 100,
			level: 'platinum',
			spouse: 'true',
			price: 0,
			tcv: 0
		},
		{
			frequency: 'annual',
			discount: 0,
			level: 'gold',
			spouse: 'false',
			price: 299,
			tcv: 299
		},
		{
			frequency: 'monthly',
			discount: 0,
			level: 'gold',
			spouse: 'false',
			price: 29,
			tcv: 348
		},
		{
			frequency: 'annual',
			discount: 10,
			level: 'gold',
			spouse: 'false',
			price: 269.1,
			tcv: 269.1
		},
		{
			frequency: 'monthly',
			discount: 10,
			level: 'gold',
			spouse: 'false',
			price: 26.09,
			tcv: 313.08
		},
		{
			frequency: 'annual',
			discount: 15,
			level: 'gold',
			spouse: 'false',
			price: 254.15,
			tcv: 254.15
		},
		{
			frequency: 'monthly',
			discount: 15,
			level: 'gold',
			spouse: 'false',
			price: 24.63,
			tcv: 295.56
		},
		{
			frequency: 'annual',
			discount: 20,
			level: 'gold',
			spouse: 'false',
			price: 239.2,
			tcv: 239.2
		},
		{
			frequency: 'annual',
			discount: 50,
			level: 'gold',
			spouse: 'false',
			price: 149.5,
			tcv: 149.5
		},
		{
			frequency: 'annual',
			discount: 100,
			level: 'gold',
			spouse: 'false',
			price: 0,
			tcv: 0
		},
		{
			frequency: 'annual',
			discount: 0,
			level: 'gold',
			spouse: 'true',
			price: 149,
			tcv: 149
		},
		{
			frequency: 'monthly',
			discount: 0,
			level: 'gold',
			spouse: 'true',
			price: 14,
			tcv: 168
		},
		{
			frequency: 'annual',
			discount: 100,
			level: 'gold',
			spouse: 'true',
			price: 0,
			tcv: 0
		},
		{
			frequency: 'annual',
			discount: 0,
			level: 'ccm',
			spouse: 'false',
			price: 47,
			tcv: 47
		},
		{
			frequency: 'annual',
			discount: 100,
			level: 'ccm',
			spouse: 'false',
			price: 0,
			tcv: 0
		},
		{
			frequency: 'annual',
			discount: 100,
			level: 'ccm',
			spouse: 'false',
			price: 0,
			tcv: 0
		}
	];

	return (
		tcvs?.find(
			({ frequency, level, spouse, discount }) =>
				frequency === requestedFrequency &&
				level === requestedLevel &&
				spouse === hasSpouse &&
				discount === discountPercentage
		)?.tcv || 0
	);
};
