import { ProductTiers } from './productTiers';

export enum Levels {
	Gold = 'gold',
	Platinum = 'platinum',
	Elite = 'elite'
}

export const tiersForLevel : Record<Levels, ProductTiers> = {
	[Levels.Gold]: ProductTiers.Gold,
	[Levels.Platinum]: ProductTiers.Platinum,
	[Levels.Elite]: ProductTiers.Elite
};
