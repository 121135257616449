export async function queryWithRedundancy<T>(
	query: () => Promise<T>,
	successfulWhen: (t: T) => boolean,
	interval: number,
	attemptLimit: number
): Promise<T> {
	let result = await query();
	let attempts = 0;

	if (successfulWhen(result)) {
		return result;
	} else {
		return new Promise<T>((resolve) => {
			const intervalId = setInterval(async () => {
				result = await query();
				attempts++;

				const shouldResolve = successfulWhen(result) || attempts >= attemptLimit;

				if (shouldResolve) {
					clearInterval(intervalId);
					resolve(result);
				}
			}, interval);
		});
	}
}
