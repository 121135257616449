import { Transaction } from '../../core/domain/transaction/transaction';
import { DismissibleErrors } from '../../core/enums/dismissibleErrors';
import { Messages } from '../../core/enums/messages';
import { Auth } from '../../core/services/auth/auth';

export async function sendAuthEmail(
	email?: string,
	transaction = Transaction.Instance(),
	auth = Auth.Instance()
): Promise<boolean> {
	email = email || transaction.Data.email;
	transaction.Message.Publish(Messages.SendingEmail);
	const verificationResult = await auth.sendVerificationEmail(email);
	transaction.Message.Publish(Messages.Empty);

	if (verificationResult.wasSuccessful) {
		return true;
	} else {
		transaction.Error.Publish(DismissibleErrors.ErrorSendingVerificationEmail);
		return false;
	}
}
