import dayjs from 'dayjs';
import { QueryParams } from '../core/enums/module';
import { getQueryParameters } from './getQueryParameter';

type AffiliateData = {
	id: string | null,
	offerId: string | null,
	lsr: string | null,
	type: string | null,
	created: string | null,
	sub: string | null,
	sub2: string | null,
	sub3: string | null,
	sub4: string | null,
	sub5: string | null,
	clickId: string | null,
	unique1: string | null,
	unique2: string | null,
	unique3: string | null,
	unique4: string | null,
	unique5: string | null
}

export const getAffiliateData = (
	locationSearch = location.search,
	dayJs: () => {
		format: (format: string) => string
	} = dayjs
): AffiliateData => {
	const urlParams = getQueryParameters(locationSearch);

	const affiliateDataToFilter = {
		id: urlParams.get(QueryParams.AffId),
		offerId: urlParams.get(QueryParams.OfferId),
		lsr: urlParams.get(QueryParams.AffLsr),
		type: 'HO',
		created: dayJs().format('YYYY-MM-DD'),
		sub: urlParams.get(QueryParams.AffSub),
		sub2: urlParams.get(QueryParams.AffSub2),
		sub3: urlParams.get(QueryParams.AffSub3),
		sub4: urlParams.get(QueryParams.AffSub4),
		sub5: urlParams.get(QueryParams.AffSub5),
		clickId: urlParams.get(QueryParams.AffClickId),
		unique1: urlParams.get(QueryParams.AffUnique1),
		unique2: urlParams.get(QueryParams.AffUnique2),
		unique3: urlParams.get(QueryParams.AffUnique3),
		unique4: urlParams.get(QueryParams.AffUnique4),
		unique5: urlParams.get(QueryParams.AffUnique5)
	};

	Object.keys(affiliateDataToFilter).forEach(
		(key) => (affiliateDataToFilter[key] == null || affiliateDataToFilter[key] == undefined) && delete affiliateDataToFilter[key]
	);

	return affiliateDataToFilter;
};
