import type { FunnelData, GadSchedule, SchedulerData } from '../../models/module';
import Environments from '@delta-defense/client-environments';
import { Apis } from '@delta-defense/client-environments/environments/enums/apis';
import { Result } from '../../domain/result';
import { IRest, ResponseOf, Rest } from '../rest/rest';

export interface IJasmine {
	getGadSchedule(): Promise<ResponseOf<GadSchedule>>;
	getFunnelData(funnelId: string): Promise<Result<FunnelData>>;
	getSchedulerData(): Promise<Result<SchedulerData>>;
}

export class Jasmine implements IJasmine {
	private static instance: IJasmine | null = null;
	public static Instance(
		rest = Rest.Instance(),
		jasmineApiProxyDomain = Environments.getProxyEndpointForApi(Apis.JasmineApi)
	): IJasmine { return this.instance || (this.instance = new Jasmine(rest, jasmineApiProxyDomain)); }
	public static Destroy = () => Jasmine.instance = null;

	private get gadSchedule() {
		return `${this.jasmineApiProxyDomain}/gadschedule`;
	}

	private constructor(
		private rest: IRest,
		private jasmineApiProxyDomain: string
	) { }

	public async getGadSchedule(): Promise<ResponseOf<GadSchedule>> {
		return await this.rest.Get<GadSchedule>(this.gadSchedule);
	}

	private funnelDataCache: Record<string, ResponseOf<FunnelData> | undefined> = {};
	public async getFunnelData(funnelId: string): Promise<Result<FunnelData>> {
		const funnelDataResponse = this.funnelDataCache[funnelId] ||
			(this.funnelDataCache[funnelId] = await this.rest.Get<FunnelData>(`${this.jasmineApiProxyDomain}/funnel/${funnelId}`));

		const result: Result<FunnelData> = {
			wasSuccessful: funnelDataResponse.ok,
			value: funnelDataResponse.body
		};

		return result;
	}

	private schedulerDataCache: Promise<ResponseOf<SchedulerData>> | null = null;
	public async getSchedulerData(): Promise<Result<SchedulerData>> {
		const schedulerDataResponse = await (this.schedulerDataCache ||
			(this.schedulerDataCache = this.rest.Get<SchedulerData>(`${this.jasmineApiProxyDomain}/schedule_data`)));

		const result: Result<SchedulerData> = {
			wasSuccessful: schedulerDataResponse.ok,
			value: schedulerDataResponse.body
		};

		return result;
	}
}
