import { InteractionEventNames, Locations } from '../../enums/module';
import { InteractionEvent } from '../../eventTypes/module';

export const exitPopFired: InteractionEvent = {
	event_name: InteractionEventNames.ModalFired,
	events: {
		location: Locations.Checkout,
		modal_name: 'Checkout Exit Pop',
		modal_path: '/checkout-exit-pop',
		modal_type: 'offer'
	}
};
