import { Levels } from '../core/enums/levels';

export const getLevel = (
	tier?: string
): Levels | undefined => {
	if (!tier) {
		return undefined;
	}
	let level: Levels | undefined = undefined;

	Object.values(Levels).forEach((lvl) => {
		if (tier?.toLowerCase().includes(lvl)) {
			level = lvl;
		}
	});

	return level;
};
