import { Subscription, SubscriptionProduct } from '../core/models/module';
import { CheckoutTypes } from '../core/services/analytics/enums/checkoutTypes';

// eslint-disable-next-line complexity
export const getCheckoutType = (
	products: {
		primary?: SubscriptionProduct,
		spouse?: SubscriptionProduct
	} = {},
	subscriptions: Subscription[] = []
): CheckoutTypes => {
	let checkoutType = CheckoutTypes.Default;

	if (products && (products.primary || products.spouse)) {
		const mainProduct = products.primary || products.spouse;
		if (mainProduct?.attributes?.tags && mainProduct.attributes.tags.find((tag) => tag.urlSafe === 'membership')) {
			checkoutType = CheckoutTypes.Membership;
		} else if (mainProduct?.attributes?.tags && mainProduct.attributes.tags.find((tag) => tag.urlSafe === 'concealed-carry-magazine')) {
			checkoutType = CheckoutTypes.Magazine;
		}

		if (subscriptions && subscriptions.find((sub) => sub?.attributes?.status === 'active')) {
			checkoutType = CheckoutTypes.Upgrade;
		}
	}

	return checkoutType;
};
