import React, { useEffect, useState } from 'react';
import styles from './ineligiblePromoModal.module.scss';
import { BundlesByTierKeys, returnBundlesByTier } from '../bonusesDrawer/returnBundlesByTier';
import { Levels } from '../../core/enums/levels';
import { BundleProduct } from '../../core/models/bundleProduct';
import { Observable } from 'tsbase/Patterns/Observable/Observable';
import { ITransaction, Transaction } from '../../core/domain/module';
import { DefaultSteps } from '../../configuration/default';
import { Bundle, IBundle } from '../../core/services/module';
import { ProductTiers } from '../../core/enums/productTiers';

const tierToBundles: Partial<Record<ProductTiers, BundlesByTierKeys[]>> = {
	[ProductTiers.Gold]: [BundlesByTierKeys.PlatinumAndGold, BundlesByTierKeys.Gold, BundlesByTierKeys.AllTiers],
	[ProductTiers.Platinum]: [
		BundlesByTierKeys.EliteAndPlatinum,
		BundlesByTierKeys.PlatinumAndGold,
		BundlesByTierKeys.Platinum,
		BundlesByTierKeys.AllTiers
	],
	[ProductTiers.Elite]: [
		BundlesByTierKeys.Elite,
		BundlesByTierKeys.EliteAndPlatinum,
		BundlesByTierKeys.EliteAndGold,
		BundlesByTierKeys.AllTiers
	]
};

export const DisplayIneligiblePromoModalObservable = new Observable<Record<Levels, BundleProduct[]> | undefined>();
export const UserHasAcceptedDefaultPromos = new Observable<boolean>();

let DisplayIneligiblePromoModalObservableRef: string | undefined;

type Props = {
	returnBundlesByTierFunc?: typeof returnBundlesByTier;
	transaction?: ITransaction;
	bundleService?: IBundle;
	useEffectFunc?: typeof useEffect;
	useStateFunc?: typeof useState;
	DisplayObservable?: typeof DisplayIneligiblePromoModalObservable;
	UserHasAcceptedObservable?: typeof UserHasAcceptedDefaultPromos;
};

export const IneligiblePromoModal: React.FunctionComponent<Props> = ({
	returnBundlesByTierFunc = returnBundlesByTier,
	transaction = Transaction.Instance(),
	bundleService = Bundle.Instance(),
	useEffectFunc = useEffect,
	useStateFunc = useState,
	DisplayObservable = DisplayIneligiblePromoModalObservable,
	UserHasAcceptedObservable = UserHasAcceptedDefaultPromos
}) => {
	const [combinedBundleProducts, setCombinedBundleProducts] = useStateFunc<BundleProduct[]>([]);
	const shippingStep = transaction.Steps[DefaultSteps.Shipping];

	useEffectFunc(() => {
		let observableId = '';
		if (!UserHasAcceptedObservable.CurrentIssue) {
			observableId =
				DisplayIneligiblePromoModalObservableRef ||
				(DisplayIneligiblePromoModalObservableRef = DisplayObservable.Subscribe((bundleProducts) => {
					if (bundleProducts) {
						const bundlesByTier = returnBundlesByTierFunc(bundleProducts);
						const userSelectedTier = transaction.Data.purchaseProducts?.primary?.attributes.tier || ProductTiers.Gold;
						const userBundles = tierToBundles[userSelectedTier];
						if (userBundles) {
							const bundleProductsForUserByTier: BundleProduct[] = [];
							userBundles.forEach((k) => {
								if (bundlesByTier[k]) {
									bundleProductsForUserByTier.push(...bundlesByTier[k]);
								}
							});
							setCombinedBundleProducts(bundleProductsForUserByTier);
						}
					}
				}));
		}
		return () => {
			DisplayIneligiblePromoModalObservableRef = undefined;
			DisplayIneligiblePromoModalObservable.Cancel(observableId);
		};
	}, []);

	return combinedBundleProducts.length ? (
		<div className={styles.outerIneligableContainer}>
			<div className={styles.ineligableContainer}>
				<div className={styles.innerContainer}>
					<div className={styles.modalWrap}>
						<div className={styles.cta}>
							<p>Free Upgrade</p>
						</div>
						<div className={styles.innerModalWrap}>
							<div className={styles.borderedContentWrap}>
								<div className={styles.secondaryCta}>
									<p>
										Unfortunately, members living in{' '}
										<b>
											<i>Alaska, California, Connecticut, Hawaii, Illinois, Massachusetts, New Jersey, New York, and Washington D.C.</i>
										</b>{' '}
										are not eligible for this promotion due to shipping restrictions on ammunition.
									</p>
									<p>
										We’ve upgraded you to a package that is eligible in your state! <b>Proceed to claim all FREE bonuses listed below!</b>
									</p>
								</div>
								<div className={styles.bundlesWrap}>
									{combinedBundleProducts.map((b) => {
										return b?.attributes?.image && b?.attributes?.description && b?.attributes?.listPrice ? (
											<>
												<div className={styles.imgWrap}>
													<img src={b.attributes.image} alt="" />
												</div>
												<div className={styles.ctaWrap}>
													<p className={styles.uppercase}>
														<b>Free</b>
													</p>
													<p>{b.attributes.description}</p>
													{!!b.attributes.listPrice && <p>
														<i>
															<b>Valued at ${b.attributes.listPrice}</b>
														</i>
													</p>}
												</div>
											</>
										) : (
											<></>
										);
									})}
								</div>
								<div className={styles.buttonWrap}>
									<button
										className={styles.submitButton}
										id="ineligible_upgrade_button"
										onClick={(e) => {
											e.preventDefault();
											setCombinedBundleProducts([]);
											transaction.useDefaultBundle = true;
											bundleService.setBundleProductsInTransaction(true);
											UserHasAcceptedDefaultPromos.Publish(true);
											shippingStep.Submit();
										}}
									>
										Proceed with <i>FREE</i> Upgrade
									</button>
									<button
										className={styles.exitButton}
										id="ineligible_modal_exit"
										onClick={(e) => {
											e.preventDefault();
											setCombinedBundleProducts([]);
										}}
									>
										x
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	) : (
		<></>
	);
};
