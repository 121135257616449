export enum ZuoraCardPageIds {
  Local = '8ac681e77b1af2c4017b1c542fa640c3',
  Dev = '2c92c0f9774f2b3e0177504a0dbf3373', // orphaned
  Staging = '8ac691da7b1afaac017b1c4be75d3bff',
  Production = '8a1299e77f72d85e017f747f636335c9',
  ProductionPreview = '8a128ba87f1f74fb017f2792fbc40e45'
}

export enum ZuoraAchPageIds {
  Local = '8ac69565939568c10193b0fef9a15312',
  Dev = '',
  Staging = '8ac69565939568c10193b10463f553a1',
  Production = '8a128912939bb0b60193b1087c53498f',
  ProductionPreview = ''
}
