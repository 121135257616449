export enum PromoTypes {
	None = '',
	SigSauerAmmo = 'SigSauerAmmo',
	SigSauerOptic = 'SigSauerOptic',
	Beretta250 = 'Beretta250',
	GiftCard75 = 'GiftCard75',
	BrantleyGilbert = 'BrantleyGilbert',
	PewPew = 'pewPew',
	PrimaryArmsOptic = 'PrimaryArmsOptic',
	SigSauerGiftCard = 'SigSauerGiftCard'
}
