import { ITransaction, Transaction } from '../../../core/domain/module';
import { Field } from '../../../core/domain/transaction/field';
import { Step } from '../../../core/domain/transaction/step';
import { Messages } from '../../../core/enums/module';
import { AccessToken, User } from '../../../core/models/module';
import { Auth, Tracking } from '../../../core/services/module';
import { getAccountMeta } from '../../../utilities/getAccountMeta';
import { FieldTypes } from '../../fieldTypes';
import { minLength, required } from '../../fieldValidations/module';
import { DefaultSteps } from '../../default';

export enum CreateAccountFields {
	Password = 'password',
	PasswordConfirm = 'passwordConfirm'
}

export type CreateAccountStepResult = {
	accessToken?: string;
	accessTokenObject?: AccessToken;
	user?: User;
};

const fields: () => Record<string, Field> = () => {
	const fieldRecord = {
		[CreateAccountFields.Password]: new Field(
			'Password',
			'Password',
			FieldTypes.Password,
			'Password must be at least eight (8) characters.',
			[required, minLength(8)]
		)
	};

	fieldRecord[CreateAccountFields.PasswordConfirm] = new Field(
		'ConfirmPassword',
		'Confirm Password',
		FieldTypes.Password,
		'Passwords must match.',
		[
			(value) => {
				const passwordValue =
					Transaction.Instance().Steps[DefaultSteps.CreateAccount].Fields[CreateAccountFields.Password].GetValue();
				return value === passwordValue;
			}
		]
	);

	return fieldRecord;
};

export const CreateAccount = (
	transaction?: ITransaction,
	auth = Auth.Instance(),
	tracking = Tracking.Instance()
) =>
	new Step<CreateAccountStepResult>(fields(), async () => {
		transaction = transaction || Transaction.Instance();
		transaction.Message.Publish(Messages.CreatingAccount);

		const leadDataToSend = await tracking.getTrackingFields();
		const email = transaction.Data.email;
		const password = transaction.Steps[DefaultSteps.CreateAccount].Fields[CreateAccountFields.Password].GetValue();
		const createAccountResult = await auth.createAccount(email, password, leadDataToSend, getAccountMeta());

		transaction.Message.Publish(Messages.Empty);

		return {
			wasSuccessful: createAccountResult.wasSuccessful,
			value: createAccountResult.value ? {
				user: createAccountResult.value
			} : undefined
		};
	});
