import { IFeatureFlagRepository } from '@delta-defense/feature-flag-service/service/iFeatureFlagRepository';
import { FeatureFlagService } from '@delta-defense/feature-flag-service';
import { DefaultSteps } from '../configuration/default';
import { Routes, QueryParams } from '../core/enums/module';
import { Transaction } from '../core/domain/transaction/transaction';
import { ITransaction } from '../core/domain/transaction/ITransaction';
import { IAuth, Auth, ISubscriptions, Subscriptions, CartAbandonment, ICartAbandonment, BrowserStorage, ISessionStorage, AmacIntegration } from '../core/services/module';
import { Step } from '../core/domain/module';
import { EmailCheckFields } from '../configuration/steps/default/emailCheck';
import { getQueryParameter } from './getQueryParameter';
import { routeUpgradeAndProductIdPurchases } from './routeUpgradeAndProductIdPurchases';
import { getEmailParam } from './getEmailParam';
import { SessionStorageKeys } from '../core/services/browserStorage/keys/sessionStorageKeys';

type Router = {
	push: (url: string) => void;
	replace: (url: string) => void;
};

// eslint-disable-next-line complexity
export const navigateUserToFirstStep = async (
	router: Router,
	mainLocation = location,
	transaction = Transaction.Instance(),
	auth = Auth.Instance(),
	subscriptions = Subscriptions.Instance(),
	features = FeatureFlagService.Instance(),
	session = BrowserStorage.SessionInstance(),
	cartAbandonment = CartAbandonment.Instance()
): Promise<void> => {
	const emailParam = getEmailParam(mainLocation.search);
	const alreadyLoggedIn = !!auth.accessToken;
	const onIgnoredRoute = [
		'/invoice',
		Routes.SignupWelcome,
		Routes.SingleClickPurchase,
		Routes.SSO,
		Routes.PostTransactionUpdatePassword,
		Routes.PostTransactionReferral,
		Routes.CartAbandonment,
		Routes.ProductPTU
	].some((r) => mainLocation.pathname.includes(r));

	if (mainLocation.pathname === Routes.CartAbandonment) {
		await restoreAbandonedCart(alreadyLoggedIn, cartAbandonment, auth, router, mainLocation);
		return;
	}

	if (alreadyLoggedIn && !onIgnoredRoute && resumeSessionTransaction(router, session, mainLocation, transaction)) {
		return;
	}

	const emailCheckStep = transaction.Steps[DefaultSteps.EmailCheck];

	if (emailParam) {
		transaction.Data.email = emailParam;
		emailCheckStep.Fields[EmailCheckFields.Email].SetValue(emailParam);
	}

	if (!alreadyLoggedIn) {
		routeUnAuthedUser(emailCheckStep, router, mainLocation, features, emailParam);
	} else if (alreadyLoggedIn && !onIgnoredRoute) {
		await routeAuthedUser(auth, subscriptions, transaction, router, mainLocation, features, session, emailParam);
	}

	emailCheckStep.Completed = true;
};

const restoreAbandonedCart = async (
	alreadyLoggedIn: boolean,
	cartAbandonment: ICartAbandonment,
	auth: IAuth,
	router: Router,
	mainLocation: Location
) => {
	cartAbandonment.Enable();
	if (alreadyLoggedIn) {
		await cartAbandonment.RestoreAbandonedCart(auth.accessToken);
	} else {
		router.push(`${Routes.Root}${mainLocation.search}`);
		setTimeout(() => {
			navigateUserToFirstStep(router);
		});
	}
};

// eslint-disable-next-line complexity
const resumeSessionTransaction = (
	router: { push: (url: string) => void },
	session: ISessionStorage,
	mainLocation: Location,
	transaction: ITransaction
): boolean => {
	const transactionData = session.getStringValue(SessionStorageKeys.TransactionData);
	const lastKnownRoute = session.getItem(SessionStorageKeys.LastKnownRoute);
	const routes = Object.values(Routes);
	const productId = getQueryParameter(QueryParams.ProductId, mainLocation.search);
	const canResumeWithProductId =
		(productId && lastKnownRoute?.includes(productId)) ||
		(!productId && !lastKnownRoute?.includes(QueryParams.ProductId));
	const ignoredRoutes = [
		Routes.Root,
		Routes.SignupWelcome,
		Routes.SingleClickPurchase,
		Routes.PostTransactionReferral,
		Routes.CartAbandonment,
		Routes.PostTransactionUpdatePassword
	];
	const canResumeTransaction = !!(
		transactionData &&
		lastKnownRoute &&
		canResumeWithProductId &&
		routes.filter((r) => !ignoredRoutes.includes(r)).some((r) => lastKnownRoute.startsWith(r))
	);

	if (canResumeTransaction) {
		transaction.Data = JSON.parse(transactionData);
		const currentRoute = `${mainLocation.pathname}${mainLocation.search}`;

		if (lastKnownRoute && currentRoute !== lastKnownRoute) {
			router.push(lastKnownRoute);
		}
	} else {
		session.clear([SessionStorageKeys.UserOnCartAbandonmentPath]);
	}

	return canResumeTransaction;
};

// eslint-disable-next-line complexity
const routeAuthedUser = async (
	auth: IAuth,
	subscriptions: ISubscriptions,
	transaction: ITransaction,
	router: Router,
	mainLocation: Location,
	features: IFeatureFlagRepository,
	session: ISessionStorage,
	emailParam: string | null,
	amacEnabled = AmacIntegration.isEnabled()
) => {
	const [userResult, customerResult] = await Promise.all([auth.getUser(), subscriptions.getCustomer()]);
	const productId = getQueryParameter(QueryParams.ProductId, mainLocation.search);

	if (!transaction.Data.email) {
		transaction.Data.email = userResult.value?.email || '';
	}

	if (emailParam && userResult.value?.email?.toLowerCase() !== transaction.Data.email.toLowerCase()) {
		auth.logout();
		navigateUserToFirstStep(router, mainLocation, transaction, auth, subscriptions, features, session);
	} else if (
		customerResult.wasSuccessful &&
		customerResult.value?.subscriptions.some((s) => s.attributes.status === 'active')
	) {
		transaction.Data.customerSubscriptions = customerResult.value.subscriptions;
		await transaction.SetProductDataById(productId);

		if (amacEnabled || (!transaction.UpgradingFromLevel && !transaction.SpouseOnlyPurchase && !transaction.IsSameLevelMonthlyToAnnualUpgrade())) {
			router.push(`${Routes.Existing}${mainLocation.search}`);
		} else {
			await routeUpgradeAndProductIdPurchases(transaction, mainLocation.search, router);
		}
	} else if (!!productId) {
		await routeUpgradeAndProductIdPurchases(transaction, mainLocation.search, router);
	} else {
		router.push(`${Routes.SignupLevel}${mainLocation.search}`);
	}
};

const routeUnAuthedUser = (
	emailCheckStep: Step<any>,
	router: Router,
	mainLocation: Location,
	features: IFeatureFlagRepository,
	emailParam: string | null
) => {
	if (emailParam) {
		emailCheckStep.Submission.Order(async (result) => {
			const accountExists = result?.value?.accountExists;
			if (accountExists) {
				router.push(`${Routes.SignupVerification}${mainLocation.search}`);
			} else if (result?.wasSuccessful) {
				router.push(`${Routes.SignupRegistration}${mainLocation.search}`);
			} else {
				router.push(`${Routes.Email}${mainLocation.search}`);
			}
		}, false);

		emailCheckStep.Submit(true);
	} else {
		router.replace(`${Routes.Email}${mainLocation.search}`);
	}
};
