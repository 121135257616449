export enum FieldTypes {
	Email,
	Text,
	Number,
	Password,
	Phone,
	PostalCode,
	Checkbox,
	StateSelect,
	BillingFrequency,
	AddSpouseCheckbox,
	LevelSelectionChart,
	UseCardOnFile,
	VerificationCode,
	AddressLine1,
	AddressLine2,
	City
}

export enum InputTypes {
	Email = 'email',
	Text = 'text',
	Number = 'number',
	Password = 'password',
	Tel = 'tel',
	Checkbox = 'checkbox',
	Custom = 'custom'
}

export const InputTypesFieldTypesRecord: Record<FieldTypes, InputTypes> = {
	[FieldTypes.Email]: InputTypes.Text,
	[FieldTypes.Text]: InputTypes.Text,
	[FieldTypes.Number]: InputTypes.Number,
	[FieldTypes.Password]: InputTypes.Password,
	[FieldTypes.Phone]: InputTypes.Tel,
	[FieldTypes.PostalCode]: InputTypes.Tel,
	[FieldTypes.Checkbox]: InputTypes.Checkbox,
	[FieldTypes.StateSelect]: InputTypes.Custom,
	[FieldTypes.BillingFrequency]: InputTypes.Custom,
	[FieldTypes.AddSpouseCheckbox]: InputTypes.Custom,
	[FieldTypes.LevelSelectionChart]: InputTypes.Custom,
	[FieldTypes.UseCardOnFile]: InputTypes.Custom,
	[FieldTypes.VerificationCode]: InputTypes.Number,
	[FieldTypes.AddressLine1]: InputTypes.Text,
	[FieldTypes.AddressLine2]: InputTypes.Text,
	[FieldTypes.City]: InputTypes.Text
};

export const AutocompleteFieldTypesRecord: Record<FieldTypes, string> = {
	[FieldTypes.Email]: 'email',
	[FieldTypes.Text]: 'on',
	[FieldTypes.Number]: 'on',
	[FieldTypes.Password]: 'new-password',
	[FieldTypes.Phone]: 'tel-national',
	[FieldTypes.PostalCode]: 'postal-code',
	[FieldTypes.Checkbox]: 'on',
	[FieldTypes.StateSelect]: 'address-level1',
	[FieldTypes.BillingFrequency]: 'on',
	[FieldTypes.AddSpouseCheckbox]: 'on',
	[FieldTypes.LevelSelectionChart]: 'on',
	[FieldTypes.UseCardOnFile]: 'on',
	[FieldTypes.VerificationCode]: 'one-time-code',
	[FieldTypes.AddressLine1]: 'address-line1',
	[FieldTypes.AddressLine2]: 'address-line2',
	[FieldTypes.City]: 'address-level2'
};
