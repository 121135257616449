export enum PaymentErrors {
	CreditCardHolderName = 'creditCardHolderName',
	CardExpirationMonth = 'creditCardExpirationMonth',
	CardNumber = 'creditCardNumber',
	CardSecurityCode = 'cardSecurityCode',
	CardExpirationYear = 'creditCardExpirationYear',
	CardPostalCode = 'creditCardPostalCode',
	CardAddress1 = 'creditCardAddress1',
	CardCity = 'creditCardCity',
	CreditCardState = 'creditCardState',
	AccountHolderName = 'achBankAccountName',
	BankName = 'achBankName',
	RoutingNumber = 'achBankABACode',
	AccountNumber = 'achBankAccountNumber',
	BankAccountType = 'achBankAccountType',
	BankAddress = 'achAddress1',
	BankCity = 'achCity',
	BankState = 'achState',
	BankPostalCode = 'achPostalCode'
}

export const getClientPaymentErrorDescription = (error: string): string => {
	const errorDescriptionMap: Record<PaymentErrors, string> = {
		[PaymentErrors.CreditCardHolderName]: 'Name Required',
		[PaymentErrors.CardExpirationMonth]: 'Invalid Card Expiration Month',
		[PaymentErrors.CardNumber]: 'Invalid Card Number',
		[PaymentErrors.CardSecurityCode]: 'Invalid CVV',
		[PaymentErrors.CardExpirationYear]: 'Invalid Card Expiration Year',
		[PaymentErrors.CardPostalCode]: 'Invalid ZIP Code',
		[PaymentErrors.CardAddress1]: 'Invalid Address',
		[PaymentErrors.CardCity]: 'Invalid City',
		[PaymentErrors.CreditCardState]: 'State Required',
		[PaymentErrors.AccountHolderName]: 'Name Required',
		[PaymentErrors.BankName]: 'Bank Name Required',
		[PaymentErrors.RoutingNumber]: 'Invalid Routing Number',
		[PaymentErrors.AccountNumber]: 'Invalid Account Number',
		[PaymentErrors.BankAccountType]: 'Account Type Required',
		[PaymentErrors.BankAddress]: 'Invalid Address',
		[PaymentErrors.BankCity]: 'Invalid City',
		[PaymentErrors.BankState]: 'Invalid State',
		[PaymentErrors.BankPostalCode]: 'Invalid ZIP Code'
	};

	return errorDescriptionMap[error] || error;
};
