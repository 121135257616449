import { ITransaction, Transaction } from '../../../core/domain/module';
import { Field } from '../../../core/domain/transaction/field';
import { Step } from '../../../core/domain/transaction/step';
import { Messages } from '../../../core/enums/module';
import { Auth } from '../../../core/services/module';
import { FieldTypes } from '../../fieldTypes';
import { minLength, required } from '../../fieldValidations/module';
import { DefaultSteps } from '../../default';

export enum UpdatePasswordFields {
	Email = 'email',
	Password = 'password',
	PasswordConfirm = 'passwordConfirm'
}

const fields: () => Record<string, Field> = () => {
	const fieldRecord = {
		[UpdatePasswordFields.Email]: new Field(
			'Email',
			'Email Address',
			FieldTypes.Email,
			'',
			[]
		),
		[UpdatePasswordFields.Password]: new Field(
			'Password',
			'Password',
			FieldTypes.Password,
			'Password must be at least eight (8) characters.',
			[required, minLength(8)]
		)
	};

	fieldRecord[UpdatePasswordFields.PasswordConfirm] = new Field(
		'ConfirmPassword',
		'Confirm Password',
		FieldTypes.Password,
		'Passwords must match.',
		[
			(value) => {
				const passwordValue =
					Transaction.Instance().Steps[DefaultSteps.PostTransactionUpdatePassword].Fields[UpdatePasswordFields.Password].GetValue();
				return value === passwordValue;
			}
		]
	);

	return fieldRecord;
};

export const UpdatePassword = (
	transaction?: ITransaction,
	auth = Auth.Instance()
) =>
	new Step(fields(), async () => {
		transaction = transaction || Transaction.Instance();
		transaction.Message.Publish(Messages.UpdatingPassword);

		const password = transaction.Steps[DefaultSteps.PostTransactionUpdatePassword].Fields[UpdatePasswordFields.Password].GetValue();
		const updatePasswordResult = await auth.updateUser({ password });

		transaction.Message.Publish(Messages.Empty);

		return {
			wasSuccessful: updatePasswordResult.wasSuccessful
		};
	});
