import Environments from '@delta-defense/client-environments';

export const safelyExecute = async (func: () => (void | Promise<void>), onError?: (error) => void, ignoreErrors = false) => {
	try {
		await func();
	} catch (error) {
		onError?.(error);
		if (!Environments.isProduction() && !ignoreErrors) {
			console.error(error);
		}
	}
};
