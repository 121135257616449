import { CreateAccountMeta } from '../core/models/createAccountMeta';

export const getAccountMeta = (
	getAvailablePropertiesFunc = () => window.deltaAnalytics?.aggregateAvailableProperties() || {}
): CreateAccountMeta => {
	const metaKeyDaKeyRecord: Record<string, string> = {
		fbc: 'fbc',
		fbp: 'fbp',
		gaClickID: 'ga_click_id',
		gaClientID: 'ga_client_id',
		twclid: ''
	};
	const availableAttributes = getAvailablePropertiesFunc();
	const meta: CreateAccountMeta = {
		label: location.href
	};

	Object.keys(metaKeyDaKeyRecord).forEach(metaKey => {
		const daKey = metaKeyDaKeyRecord[metaKey];
		if (availableAttributes[daKey]) {
			meta[metaKey] = availableAttributes[daKey];
		}
	});

	return meta;
};
