import { Observable } from 'tsbase/Patterns/Observable/Observable';
import { FieldTypes } from '../../../configuration/fieldTypes';
import { FieldValidation } from './fieldValidation';
import { Transaction } from './transaction';

export class Field {
	constructor(
		public readonly Key: string,
		public readonly Label: string | JSX.Element,
		public readonly Type: FieldTypes,
		public readonly ValidationMessage: string,
		private Validations: FieldValidation[]
	) {
		this.Validity.Publish(true);
	}

	public Clean = true;

	private Value = '';
	public GetValue() {
		return this.Value;
	}
	public SetValue(value: string | boolean) {
		let trimmedValue: string;
		if (typeof value === 'boolean') {
			trimmedValue = value ? 'checked' : '';
		} else {
			trimmedValue = value.trim();
		}

		if (this.Value != trimmedValue) {
			this.Value = trimmedValue;
			if (this.Validate()) {
				this.Clean = false;
			}
		}

		Transaction.Instance().Updates.Publish(true);
	}

	public Validity = new Observable<boolean>();

	public Validate() {
		const validity = this.GetValidity();
		this.Validity.Publish(validity);
		return validity;
	}

	private GetValidity(): boolean {
		const validity = this.Validations.reduce((value, validation) => {
			return !value ? false : validation(this.Value);
		}, true);
		return validity;
	}
}
