import React, { useEffect } from 'react';
import { Transaction } from '../../core/domain/transaction/transaction';
import { BreakingErrors, FeatureFlags, QueryParams } from '../../core/enums/module';
import { Analytics, EventTypes, FormFields, Steps, stepToFormFieldRecord } from '../../core/services/analytics/module';
import { getJSXInnerText } from '../../utilities/getJSXInnerText';
import { getQueryParameters } from '../../utilities/getQueryParameter';
import { SupportPhoneLink } from '../SupportPhoneLink/SupportPhoneLink';
import { BreakingErrorMap } from './breakingErrorMap';
import styles from './ErrorPage.module.scss';
import { FeatureFlagService } from '@delta-defense/feature-flag-service';
import { returnString } from '../../utilities/returnString';

const getRootUrlWithoutProductId = (): string => {
	const queryParams = getQueryParameters();
	queryParams.delete(QueryParams.ProductId);
	return `/?${queryParams.toString()}`;
};

const ErrorPage = ({
	analyticsService = Analytics.Instance(),
	transaction = Transaction.Instance(),
	featureFlagService = FeatureFlagService.Instance()
}) => {
	const activeBreakingError = transaction.Error.CurrentIssue as BreakingErrors;
	const errorBody = activeBreakingError ? BreakingErrorMap.get(activeBreakingError) : undefined;
	const maintenanceModeActive = featureFlagService.IsEnabled(FeatureFlags.ScheduledMaintenance);
	const maintenanceMessage = returnString(
		featureFlagService.GetValue(FeatureFlags.ScheduledMaintenance),
		// eslint-disable-next-line max-len
		'We are currently expecting technical issues. We apologize for the inconvenience. We will reach out to you, once systems are back online. In the mean time, feel free to continue exploring what the USCCA has to offer.'
	);

	useEffect(() => {
		if (activeBreakingError) {
			analyticsService.TrackError({
				event_name: activeBreakingError,
				step: Steps.One,
				events: {
					error_text: getJSXInnerText(BreakingErrorMap.get(activeBreakingError)),
					error_type: EventTypes.Checkout,
					form_field: stepToFormFieldRecord[Steps.One] || FormFields.BeginCheckout
				}
			});
		}
	}, []);

	return (
		<div className={styles.main}>
			<div className={`container ${styles.message}`}>
				{maintenanceModeActive ? (
					<>
						<p>Scheduled Maintenance</p>
						<p>{maintenanceMessage}</p>
					</>
				) : (
					<>
						{errorBody ? (
							<>
								{errorBody}
								<p className={styles.questions}>
									Questions? Please call give us a call at <SupportPhoneLink /> and we would be happy to assist you.
								</p>
							</>
						) : (
							<p>
								We're sorry, there was a technical error and we were unable to process your purchase. If you need further assistance, give
								us a call at <SupportPhoneLink /> or try again later.
							</p>
						)}
					</>
				)}
				{!maintenanceModeActive && (
					<div className={styles.reloadCta}>
						<a href={getRootUrlWithoutProductId()}>Reload / try again</a>
					</div>
				)}
				<a href="https://www.usconcealedcarry.com/" className={styles.back}>
					← Back to www.usconcealedcarry.com
				</a>
			</div>
		</div>
	);
};

export default ErrorPage;
