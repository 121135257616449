import { FeatureFlagService } from '@delta-defense/feature-flag-service';
import { Transaction } from '../../core/domain/module';
import { DismissibleErrors } from '../../core/enums/module';
import { User } from '../../core/models/users';
import { Analytics, InternalEventNames, Steps } from '../../core/services/analytics/module';
import { AmacIntegration, Auth } from '../../core/services/module';
import { sendAuthEmail } from './sendAuthEmail';

export async function processVerification(
	verificationCode,
	email: string,
	password?: string,
	auth = Auth.Instance(),
	analytics = Analytics.Instance(),
	featureFlag = FeatureFlagService.Instance(),
	transaction = Transaction.Instance(),
	sendAuthEmailFunc = sendAuthEmail,
	amacIntegrationEnabled = AmacIntegration.isEnabled(),
	amacIntegrationService = AmacIntegration.Instance()
): Promise<boolean> {
	const verificationResult = await auth.verifyVerification(verificationCode, email);

	if (verificationResult.wasSuccessful) {
		analytics.TrackInternal({
			event_name: InternalEventNames.VerificationCodeSuccess,
			step: Steps.One
		});

		if (amacIntegrationEnabled) {
			amacIntegrationService.Initialize();
		}

		if (password) {
			const userUpdateBody: User = { password };
			const updateUserResult = await auth.updateUser(userUpdateBody);

			if (updateUserResult.wasSuccessful) {
				await analytics.Identify();
				await featureFlag.Identify();
			} else {
				transaction.Error.Publish(DismissibleErrors.ErrorUpdatingPassword);
			}
		}

		return true;
	} else {
		const statusErrorMap = new Map<number, () => void>([
			[
				422,
				() => {
					transaction.Error.Publish(DismissibleErrors.InvalidVerificationCode);
				}
			],
			[
				410,
				() => {
					transaction.Error.Publish(DismissibleErrors.ExpiredVerificationCode);
					sendAuthEmailFunc(email);
					analytics.TrackInternal({
						event_name: InternalEventNames.VerificationCodeResent,
						step: Steps.One
					});
				}
			],
			[
				429,
				() => {
					transaction.Error.Publish(DismissibleErrors.ExpiredVerificationCode);
					sendAuthEmailFunc(email);
					analytics.TrackInternal({
						event_name: InternalEventNames.VerificationCodeResent,
						step: Steps.One
					});
				}
			],
			[
				404,
				() => {
					transaction.Error.Publish(DismissibleErrors.ErrorSubmittingVerificationCode);
					sendAuthEmailFunc(email);
					analytics.TrackInternal({
						event_name: InternalEventNames.VerificationCodeResent,
						step: Steps.One
					});
				}
			]
		]);

		statusErrorMap.get(verificationResult.status || 404)?.();
		return false;
	}
}
