import Environments from '@delta-defense/client-environments';

export function safelyReturn<T>(func: () => T, defaultValue: T): T {
	try {
		return func();
	} catch (error) {
		if (!Environments.isProduction()) {
			console.error(error);
		}
		return defaultValue;
	}
}
