import React, { ReactNode, useEffect } from 'react';
import Head from 'next/head';
import { FeatureFlagService } from '@delta-defense/feature-flag-service';
import { Analytics, EventTypes, FormFields, IAnalytics, Steps } from '../../core/services/analytics/module';
import { BreakingErrors, FeatureFlags } from '../../core/enums/module';
import {
	executeWhenTechnicalDifficultiesModalEnabled,
	toggleTechnicalDifficultiesModal
} from '../../utilities/technicalDifficultiesModalEnabled';
import { Footer } from '../footer/footer';
import { Header } from '../header/header';
import styles from './Layout.module.scss';
import { IFeatureFlagRepository } from '@delta-defense/feature-flag-service/service/iFeatureFlagRepository';


interface LayoutProps {
	children?: ReactNode;
	options?: {
		enableFooterSpacer?: boolean;
		useInitialLoadingStyles?: boolean;
	};
	features?: IFeatureFlagRepository;
	analytics?: IAnalytics;
	useEffectFunc?: typeof useEffect;
	toggleTechnicalDifficultiesModalFunc?: typeof toggleTechnicalDifficultiesModal;
	executeWhenTechnicalDifficultiesModalEnabledFunc?: typeof executeWhenTechnicalDifficultiesModalEnabled
}

const Layout: React.FunctionComponent<LayoutProps> = ({
	children,
	options,
	features = FeatureFlagService.Instance(),
	analytics = Analytics.Instance(),
	useEffectFunc = useEffect,
	toggleTechnicalDifficultiesModalFunc = toggleTechnicalDifficultiesModal,
	executeWhenTechnicalDifficultiesModalEnabledFunc = executeWhenTechnicalDifficultiesModalEnabled
}) => {
	const disableAllLogicRequiringFeatureFlags = !!options?.useInitialLoadingStyles;
	const disabledFeatureFlagService = { IsEnabled: () => false };
	const featureFlagService = disableAllLogicRequiringFeatureFlags ? disabledFeatureFlagService : features;

	useEffectFunc(() => {
		import('@delta-defense/technical-difficulties-modal/components/technicalDifficultiesModal/module');
		if (!disableAllLogicRequiringFeatureFlags) {
			if (featureFlagService.IsEnabled(FeatureFlags.TechnicalDifficulties)) {
				toggleTechnicalDifficultiesModalFunc(true);
			}

			executeWhenTechnicalDifficultiesModalEnabledFunc(() => {
				analytics.TrackError({
					event_name: BreakingErrors.TechnicalDifficulties,
					step: Steps.One,
					events: {
						error_text: 'Technical Difficulties',
						error_type: EventTypes.Checkout,
						form_field: FormFields.BeginCheckout
					}
				});
			});
		}
	}, []);

	return (
		<>
			<Head>
				<meta charSet="utf-8" />
				<meta name="viewport" content="initial-scale=1, width=device-width" />
				<meta name="robots" content="noindex" />
				{featureFlagService.IsEnabled(FeatureFlags.ContentSecurityPolicy) && (
					<meta
						http-equiv="Content-Security-Policy"
						/* eslint-disable-next-line max-len */
						content="default-src 'self' 'unsafe-inline' 'unsafe-eval' data: *.local deltadefense.com *.deltadefense.com deltadefensedev.com *.deltadefensedev.com usconcealedcarry.com *.usconcealedcarry.com usccadev.com *.usccadev.com uscca.com *.uscca.com *.c212.net c212.net alb.reddit.com *.segment.com *.segment.io *.launchdarkly.com *.zuora.com *.facebook.com *.doubleclick.net fonts.gstatic.com fonts.googleapis.com *.cloudflare.com *.cloudflareinsights.com *.googleoptimize.com *.googletagmanager.com *.pendo.io *.pingdom.net *.bing.com *.facebook.net *.googleadservices.com *.redditstatic.com *.google-analytics.com clarity.ms *.clarity.ms *.google.com *.tvsquared.com *.googlesyndication.com *.gettopple.com v2.crocdn.com event.webinarjam.com d3pkntwtp2ukl5.cloudfront.net pixel.mathtag.com click.exacttarget.com googleads.g.doubleclick.net q.quora.com;
					upgrade-insecure-requests;
					frame-ancestors deltadefense.com deltadefensedev.com *.deltadefense.com *.deltadefensedev.com"
					/>
				)}
				<title>Checkout | Delta Defense</title>
				<link rel="preconnect" href="https://fonts.googleapis.com" />
				<link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
				<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap" rel="stylesheet" />
				<link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com" />
			</Head>

			<Header />
			<div className={options?.useInitialLoadingStyles ? styles.contentWrapperWithBackground : styles.contentWrapper}>{children}</div>
			<Footer enableSpacer={!!options?.enableFooterSpacer} />

			<technical-difficulties-modal></technical-difficulties-modal>
		</>
	);
};

export default Layout;
