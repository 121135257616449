import { FieldValidation } from '../../core/domain/module';

export const conditional = (conditionFunc: () => boolean, validation: FieldValidation): FieldValidation => {
	return (value) => {
		if (conditionFunc()) {
			return validation(value);
		}
		return true;
	};
};
