import { AsyncObservable } from 'tsbase/Patterns/Observable/AsyncObservable';
import { Result } from '../result';
import { Field } from './field';
import { Transaction } from './transaction';

export class Step<T = any> {
	public Submission = new AsyncObservable<Result<T>>();

	private _Completed = false;
	public get Completed(): boolean {
		return this._Completed;
	}
	public set Completed(v: boolean) {
		if (v != this._Completed) {
			this._Completed = v;
		}
	}

	constructor(public Fields: Record<string, Field>, private OnSubmit: () => Promise<Result>) {}

	public Validate() {
		return Object.values(this.Fields).reduce((validity, field) => {
			field.Validate();
			return !validity ? false : !!field.Validity.CurrentIssue;
		}, true);
	}

	public async Submit(submitWhenInvalid = false): Promise<void> {
		if (submitWhenInvalid || this.Validate()) {
			const result = await this.OnSubmit();

			await this.Submission.Publish(result);

			if (result.wasSuccessful) {
				if (!this.Completed) {
					this.Completed = true;
					Transaction.Instance().Updates.Publish(true);
				}
			}
		}
	}
}
