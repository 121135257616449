/* eslint-disable max-lines */
import type { Result } from '../../domain/module';
import type { AccessToken, CreateAccountMeta, LeadData, User } from '../../models/module';
import Environments from '@delta-defense/client-environments';
import { Apis } from '@delta-defense/client-environments/environments/enums/apis';
import { loadDataLayer } from '../../../utilities/loadDataLayer';
import { getNationalPhoneNumber } from '../../../utilities/inputMasks/phoneNumberMask';
import { IRest, Rest } from '../rest/rest';
import { CookieKeys, Cookies, ICookies } from '../cookies/cookies';
import { CartAbandonment, ICartAbandonment } from '../cartAbandonment/cartAbandonment';
import { SessionStorageKeys } from '../browserStorage/keys/sessionStorageKeys';
import { BrowserStorage } from '../browserStorage/browserStorage';

export interface IAuth {
	accessToken: string;
	accessTokenObject: AccessToken | null;
	doesAccountExist(email: string): Promise<Result<boolean>>;
	createAccount(email: string, password: string, tracking: LeadData, meta: CreateAccountMeta): Promise<Result<User>>;
	login(email: string, password: string): Promise<Result>;
	logout(): void;
	sendVerificationEmail(email: string): Promise<Result>;
	verifyVerification(code: string, email: string): Promise<Result & { status?: number }>;
	getUser(): Promise<Result<User>>;
	updateUser(user: User): Promise<Result<User>>;
	triggerSetupEmail(): Promise<Result<void>>;
}

export class Auth implements IAuth {
	private static instance: IAuth | null = null;

	public static Instance(
		rest = Rest.Instance(),
		cookies = Cookies.Instance(),
		loadDataLayerFunc: () => void = loadDataLayer,
		cartAbandonment = CartAbandonment.Instance(),
		authProxyDomain = Environments.getProxyEndpointForApi(Apis.Auth)
	): IAuth {
		return (
			this.instance || (this.instance = new Auth(rest, cookies, loadDataLayerFunc, cartAbandonment, authProxyDomain))
		);
	}

	public static Destroy() {
		this.instance = null;
	}

	private verificationId = '';
	private user: User | null = null;

	private _accessToken: string | null = null;
	public get accessToken() {
		if (Date.now() > (this._accessTokenObject?.exp || 0) * 1000) {
			this.logout();
		}

		return this._accessToken || '';
	}

	private _accessTokenObject: AccessToken | null = null;
	public get accessTokenObject() {
		return this._accessTokenObject;
	}

	private constructor(
		private rest: IRest,
		private cookies: ICookies,
		private loadDataLayerFunc: () => void,
		private cartAbandonment: ICartAbandonment,
		private authProxyDomain: string,
		private mainSessionStorage = BrowserStorage.SessionInstance()
	) {
		const accessTokenCookie = cookies.get(CookieKeys.AccessToken);
		if (accessTokenCookie) {
			this.setAccessToken(accessTokenCookie);
		}
	}

	async doesAccountExist(email: string) {
		const response = await this.rest.Post(`${this.authProxyDomain}/users/emails`, JSON.stringify({ email }));
		if (response.ok) {
			return {
				wasSuccessful: true,
				value: true
			};
		}

		if (response.status === 404) {
			return {
				wasSuccessful: true,
				value: false
			};
		}

		return {
			wasSuccessful: false
		};
	}

	async createAccount(email: string, password: string, tracking: LeadData, meta: CreateAccountMeta) {
		const body = {
			email,
			password,
			tracking,
			meta
		};

		const response = await this.rest.Post<any>(`${this.authProxyDomain}/users`, JSON.stringify(body));

		if (response.ok) {
			const accessToken = response.body?.included?.[0]?.attributes?.token;

			if (accessToken) {
				await this.setAccessToken(accessToken, true);
				this.user = response.body.data?.id ? { id: response.body.data?.id, ...response.body.data?.attributes } : null;
				return {
					wasSuccessful: true,
					value: this.user || undefined
				};
			}
		}

		return {
			wasSuccessful: false
		};
	}

	async login(email: string, password: string) {
		const body = {
			email,
			password,
			meta: this.getLoginMeta(email)
		};
		const response = await this.rest.Post<any>(`${this.authProxyDomain}/tokens`, JSON.stringify(body));

		if (response.ok && response.body?.data?.attributes?.token) {
			await this.setAccessToken(response.body.data.attributes.token, true);
			return {
				wasSuccessful: true
			};
		}

		return {
			wasSuccessful: false
		};
	}

	public logout(): void {
		this.cookies.delete(CookieKeys.AccessToken);
		this.cookies.delete(CookieKeys.SSO);
		this._accessToken = null;
		this._accessTokenObject = null;
		this.user = null;
		this.mainSessionStorage.clear([SessionStorageKeys.UserOnCartAbandonmentPath]);
	}

	async sendVerificationEmail(email: string) {
		const response = await this.rest.Post<any>(`${this.authProxyDomain}/verifications`, JSON.stringify({ email }));

		if (response.ok && response.body?.data?.id) {
			this.verificationId = response.body?.data?.id;

			return {
				wasSuccessful: true
			};
		}

		return {
			wasSuccessful: false
		};
	}

	async verifyVerification(code: string, email: string) {
		if (this.verificationId) {
			const response = await this.rest.Post<any>(
				`${this.authProxyDomain}/verifications/${this.verificationId}/tokens`,
				JSON.stringify({
					code,
					meta: this.getLoginMeta(email)
				})
			);

			if (response.ok && response.body?.data?.attributes?.token) {
				await this.setAccessToken(response.body.data.attributes.token, true);
				return {
					wasSuccessful: true
				};
			}

			return {
				wasSuccessful: false,
				status: response.status
			};
		}

		return {
			wasSuccessful: false
		};
	}

	async getUser() {
		if (this.user) {
			return {
				wasSuccessful: true,
				value: this.user
			};
		}

		const response = await this.rest.Get<any>(`${this.authProxyDomain}/users/${this.accessTokenObject?.userId}`, {
			'X-Auth-Token': this.accessToken
		});

		if (response.ok && response.body?.data?.id) {
			this.user = { id: response.body.data.id, ...response.body.data.attributes } as User;

			return {
				wasSuccessful: true,
				value: this.user
			};
		}

		return {
			wasSuccessful: false
		};
	}

	async updateUser(user: User) {
		if (user.phone) {
			user.phone = getNationalPhoneNumber(user.phone);
		}

		const response = await this.rest.Patch<any>(
			`${this.authProxyDomain}/users/${this.accessTokenObject?.userId}`,
			JSON.stringify(user),
			{
				'X-Auth-Token': this.accessToken
			}
		);

		if (response.ok && response.body?.data?.id) {
			this.user = { id: response.body.data.id, ...response.body.data.attributes } as User;

			return {
				wasSuccessful: true,
				value: this.user
			};
		}

		return {
			wasSuccessful: false
		};
	}

	async triggerSetupEmail() {
		const response = await this.rest.Post<any>(
			`${this.authProxyDomain}/setup/users/${this.accessTokenObject?.userId}`,
			'',
			{
				'X-Auth-Token': this.accessToken
			}
		);

		return {
			wasSuccessful: response.ok
		};
	}

	private async setAccessToken(accessToken: string, fireSecondaryEvents = false, mainSessionStorage = BrowserStorage.SessionInstance()): Promise<void> {
		const decodedToken = this.cookies.decodeToken<AccessToken>(accessToken);

		if (Date.now() < (decodedToken?.exp || 0) * 1000) {
			this._accessToken = accessToken;
			this._accessTokenObject = decodedToken;
			this.cookies.set(CookieKeys.AccessToken, accessToken);
			const cartRestored = await this.cartAbandonment.RestoreAbandonedCart(accessToken);

			if (fireSecondaryEvents) {
				this.loadDataLayerFunc();

				if (!cartRestored) {
					mainSessionStorage.clear();
				}
			}
		} else {
			this.logout();
		}
	}

	private getLoginMeta(email: string): Record<string, any> {
		return {
			...window.deltaAnalytics?.aggregateAvailableProperties(),
			email,
			method: 'USCCA',
			platform: 'Checkout'
		};
	}
}
