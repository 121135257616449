import { InputMask } from './inputMask';

export const phoneNumberMask: InputMask = (e) =>
	(e.target as HTMLInputElement).value = getNationalPhoneNumber(
		(e.target as HTMLInputElement).value);

export const getNationalPhoneNumber = (rawPhoneNumber: string): string => {
	rawPhoneNumber = rawPhoneNumber.replace(/\D/g, '');
	let nationalPhoneNumber = '';
	for (let i = 0; i < rawPhoneNumber.length; i++) {
		if (i < 10) {
			if (i == 3 || i == 6) {
				nationalPhoneNumber = nationalPhoneNumber.concat('-');
			}
			nationalPhoneNumber = nationalPhoneNumber.concat(rawPhoneNumber.charAt(i));
		}
	}
	return nationalPhoneNumber;
};
