import React from 'react';
import { IAnalytics, Locations } from '../../core/services/analytics/module';
import styles from './amacSupportLink.module.scss';

type Props = {
	location?: Locations,
	analytics?: IAnalytics,
	additionalEventsData?: Record<string, string>
}

export const AMACSupportLink: React.FunctionComponent<Props> = () => <a className={styles.phoneLink} href="tel:1-888-262-2006">888-262-2006</a>;
