import Environments from '@delta-defense/client-environments';
import { Transaction } from '../core/domain/module';

export const sendToMemberSiteIfMember = async (
	mainWindow = window,
	queryString = '',
	transaction = Transaction.Instance()
) => {
	const isMember = transaction.PrimarySub?.attributes.type === 'membership';
	if (isMember) {
		mainWindow.location.href = `${Environments.getMyUsccaDomain('http://localhost:3001')}${queryString}`;
	}
};
