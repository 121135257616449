import { Result } from '../core/domain/result';
import { ITransaction } from '../core/domain/transaction/ITransaction';
import { BreakingErrors, levelCanUpgradeTo, PaymentFrequencies, ProductTiers, QueryParams, Routes } from '../core/enums/module';
import { SubscriptionProduct } from '../core/models/module';
import { getNextRoute } from './getNextRoute';
import { getQueryParameter } from './getQueryParameter';

export const routeUpgradeAndProductIdPurchases = async (
	transaction: ITransaction,
	locationSearch: string,
	router: { push: (route: string) => void }
) => {
	const productId = getQueryParameter(QueryParams.ProductId, locationSearch);
	const upgradingFromLevel = transaction.UpgradingFromLevel;

	if (productId || upgradingFromLevel || transaction.SpouseOnlyPurchase) {
		const productsResult = await transaction.SetProductDataById(productId);

		if (productsResult.wasSuccessful) {
			let handled = routeToNextPageForProductId(productsResult, transaction, router, locationSearch);
			handled = handled || routeToNextPageForUpgrade(transaction, router, locationSearch);
		} else {
			transaction.Error.Publish(BreakingErrors.FailedToLoadProducts);
		}
	} else {
		router.push(`${Routes.Existing}${locationSearch}`);
	}
};

const routeToNextPageForProductId = (
	productsResult: Result<SubscriptionProduct[]>,
	transaction: ITransaction,
	router: { push: (route: string) => void; },
	locationSearch: string
): boolean => {
	const isLegalProduct = !productsResult.value?.find(p =>
		p.attributes.price === 0 ||
		['silver', 'lifetime'].some(t => p.attributes.tier.toLowerCase().includes(t))
	);
	const ccmProduct = productsResult.value?.find(p => p.attributes.tier.toLowerCase().includes('magazine'));

	if (isLegalProduct && !transaction.PrimarySub && !transaction.SpouseSub) {
		if (ccmProduct) {
			transaction.SetPurchaseProducts(PaymentFrequencies.Annual);
			router.push(`${getNextRoute(Routes.SignupFrequency)}${locationSearch}`);
		} else {
			routeAfterSettingPurchaseProducts(transaction, router, locationSearch);
		}
		return true;
	} else if (!isLegalProduct) {
		transaction.Error.Publish(BreakingErrors.FailedToLoadProducts);
		return true;
	}

	return false;
};

const routeToNextPageForUpgrade = (
	transaction: ITransaction,
	router: { push: (route: string) => void; },
	locationSearch: string
): boolean => {
	const prospectiveProduct = transaction.Data.productData?.find(p => p.attributes.tier && !p.attributes.tier.toLowerCase().includes('spouse'));
	const prospectiveLevel = prospectiveProduct?.attributes.tier as ProductTiers || ProductTiers.Elite;

	const levelUpgrade = !!(transaction.UpgradingFromLevel && levelCanUpgradeTo(transaction.UpgradingFromLevel, prospectiveLevel));
	const canUpgrade = !!transaction.PrimarySub && (levelUpgrade || transaction.IsSameLevelMonthlyToAnnualUpgrade() || transaction.SpouseOnlyPurchase);

	if (canUpgrade) {
		routeAfterSettingPurchaseProducts(transaction, router, locationSearch);
	} else {
		router.push(`${Routes.Existing}${locationSearch}`);
	}

	return true;
};

const routeAfterSettingPurchaseProducts = (
	transaction: ITransaction,
	router: { push: (route: string) => void; },
	locationSearch: string
) => {
	const dataForProductId = transaction.SubscriptionProductForParam;
	const productFrequency = dataForProductId?.attributes.paymentFrequency;
	const currentSubFrequency = transaction.Data.customerSubscriptions?.find(
		s => s.attributes.status === 'active' && s.attributes.paymentFrequency)?.attributes.paymentFrequency;
	const frequency = ([productFrequency, currentSubFrequency].includes(PaymentFrequencies.Annual)) ? PaymentFrequencies.Annual : PaymentFrequencies.Monthly;

	transaction.SetPurchaseProducts(frequency as PaymentFrequencies);

	const nonSpouseOnlyRoute = getNextRoute(Routes.SignupRegistration, locationSearch, transaction);
	const nextRoute = transaction.SpouseOnlyPurchase ? Routes.SignupSpouse : nonSpouseOnlyRoute;
	router.push(`${nextRoute}${locationSearch}`);
};
