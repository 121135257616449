/* eslint-disable max-lines */
import dayjs from 'dayjs';
import { ITransaction, Result, Step, Transaction } from '../../../core/domain/module';
import { Messages, QueryParams, Routes } from '../../../core/enums/module';
import { Preview, Subscription } from '../../../core/models/module';
import { Analytics, CheckoutTypes, IAnalytics, InternalEventNames } from '../../../core/services/analytics/module';
import {
	AmacIntegration,
	Auth,
	BrowserStorage,
	IAuth,
	ILocalStorage,
	IRest,
	ISubscriptions,
	ITracking,
	Rest,
	Subscriptions,
	Tracking
} from '../../../core/services/module';
import { getAffiliateData } from '../../../utilities/getAffiliateData';
import { getCheckoutType } from '../../../utilities/getCheckoutType';
import { getProductsForAnalytics } from '../../../utilities/getProductsForAnalytics';
import { getPurchaseData } from '../../../utilities/getPurchaseData';
import { CheckoutURLSearchParams, getQueryParameters } from '../../../utilities/getQueryParameter';
import { sleep } from '../../../utilities/sleep';
import { SessionStorageKeys } from '../../../core/services/browserStorage/keys/sessionStorageKeys';
import { LocalStorageKeys } from '../../../core/services/browserStorage/keys/localStorageKeys';

export const SubmitOrder = (
	processPaymentFunc = processPayment,
	mainSessionStorage = BrowserStorage.SessionInstance(),
	amacServiceEnabled = AmacIntegration.isEnabled(),
	extendAmacAccountFunc = extendAmacAccount
) =>
	new Step({}, async () => {
		const processPaymentMessage = amacServiceEnabled ? Messages.LoadingAMAC : Messages.ProcessingPayment;
		const result = await processPaymentFunc(processPaymentMessage);

		if (result.wasSuccessful) {
			mainSessionStorage.removeItem(SessionStorageKeys.TransactionData);
			mainSessionStorage.removeItem(SessionStorageKeys.LastKnownRoute);
			if (amacServiceEnabled) {
				await extendAmacAccountFunc();
			}
		}

		return result;
	});

export const extendAmacAccount = async (amacService = AmacIntegration.Instance()) => {
	await amacService.extendAccount();
};

// eslint-disable-next-line complexity
export const processPayment = async (
	message: Messages,
	noReferral = false,
	transaction = Transaction.Instance(),
	analytics = Analytics.Instance(),
	subscriptions = Subscriptions.Instance(),
	auth = Auth.Instance(),
	mainWindow: Window = window,
	publishMessages = true,
	retryTimeout = 10000,
	tracking = Tracking.Instance(),
	rest = Rest.Instance(),
	mainLocalStorage = BrowserStorage.LocalInstance(),
	mainSessionStorage = BrowserStorage.SessionInstance()
): Promise<Result<string>> => {
	publishMessages && transaction.Message.Publish(message);

	const hasFreshPreview = (transaction.Data.previewTime || 0) > Date.now() - 300000;
	const previewData = hasFreshPreview ? transaction.Data.previewData : undefined;

	const result: Result<string> = {
		wasSuccessful: true
	};
	const urlParams = getQueryParameters(mainWindow.location.search);

	let subscriptionDataResult: Result<Subscription> = await createOrUpdateSubscription(
		urlParams,
		transaction,
		noReferral,
		subscriptions,
		tracking,
		previewData
	);

	if (!subscriptionDataResult.wasSuccessful) {
		analytics.TrackInternal({ event_name: InternalEventNames.ResubmittingOrderAfterFailure });
		await sleep(retryTimeout);
		subscriptionDataResult = await createOrUpdateSubscription(
			urlParams,
			transaction,
			true,
			subscriptions,
			tracking,
			previewData,
			true
		);
	}

	if (!subscriptionDataResult.wasSuccessful) {
		result.wasSuccessful = false;
		return result;
	} else {
		publishMessages && transaction.Message.Publish(Messages.FinalizingPurchase);

		const subscription = subscriptionDataResult.value;

		mainSessionStorage.setItem(SessionStorageKeys.PurchasedSubscription, subscription);
		await reIdentifyUser(auth, analytics, subscription);

		const spouse = transaction.Data.spouseInfo;
		const checkoutType = getCheckoutType(
			transaction.Data.purchaseProducts || {},
			transaction.Data.customerSubscriptions || []
		);
		const productsForAnalytics = getProductsForAnalytics(
			transaction.Data.purchaseProducts,
			transaction.Data.bundleProductsDenied ? undefined : transaction.Data.bundleProducts
		);
		const cartData = transaction.Data.previewData;

		const storedEmailInfo = mainLocalStorage.getItem(LocalStorageKeys.UnsubscribeEmail);
		if (storedEmailInfo && storedEmailInfo?.finalEmail !== storedEmailInfo?.initEmail) {
			analytics.TrackInternal({ event_name: InternalEventNames.UsedAltEmail });
			unsubAltEmails(rest, mainLocalStorage);
		}
		trackTransaction(productsForAnalytics, cartData, analytics, checkoutType, subscription);
		updateUrlParams(urlParams, cartData, spouse, productsForAnalytics, subscriptionDataResult, checkoutType);

		result.value = `${Routes.SSO}?${urlParams.toString()}`;
	}

	return result;
};

async function unsubAltEmails(rest: IRest, mainStorage: ILocalStorage) {
	const storedEmailInfo = mainStorage.getItem(LocalStorageKeys.UnsubscribeEmail);
	if (storedEmailInfo && storedEmailInfo.toUnSubOnPurchase.length) {
		const uri = '/api/braze';
		const body = {
			emails: storedEmailInfo.toUnSubOnPurchase
		};
		const response = await rest.Post(uri, JSON.stringify(body));
		if (response.ok) {
			mainStorage.removeItem(LocalStorageKeys.UnsubscribeEmail);
		}
	}
}

async function createOrUpdateSubscription(
	urlParams: CheckoutURLSearchParams,
	transaction: ITransaction,
	noReferral: boolean,
	subscriptions: ISubscriptions,
	tracking: ITracking,
	previewData?: Preview,
	noBundle = false
) {
	const activeSubs = transaction.Data.customerSubscriptions?.filter((sub) => sub?.attributes?.status === 'active');
	const currentSub = activeSubs?.find((sub) => ['membership', 'ccm'].includes(sub?.attributes?.type));
	const affiliateData = getAffiliateData();
	const purchaseData = getPurchaseData(noReferral);
	const membership = purchaseData.membership;
	const msId = urlParams.get(QueryParams.Csr) || '';
	const trackingFields = await tracking.getTrackingFields();
	const bundleId = noBundle || transaction.Data.bundleProductsDenied ? '' : transaction.Data.currentBundle?.id || '';
	const giveawayId = transaction.Data.currentGiveaway?.id || '';

	let subscriptionDataResult: Result<Subscription> | undefined;
	if (currentSub) {
		subscriptionDataResult = await subscriptions.updateSubscription(
			currentSub.id,
			membership,
			purchaseData.magazine,
			trackingFields,
			bundleId,
			giveawayId,
			msId,
			affiliateData,
			previewData
		);
	} else {
		subscriptionDataResult = await subscriptions.createSubscription(
			membership,
			purchaseData.magazine,
			trackingFields,
			bundleId,
			giveawayId,
			msId,
			affiliateData,
			previewData
		);
	}

	return subscriptionDataResult;
}

async function reIdentifyUser(auth: IAuth, analytics: IAnalytics, subscription?: Subscription): Promise<void> {
	const membershipLevel = subscription?.attributes.product;
	const subscriptionStatus = subscription?.attributes.status;
	const memberType = subscription?.attributes.subscribableAttributes.parentId ? 'spouse' : 'primary';
	const memberStartDate = dayjs(subscription?.attributes.termStart).toDate();
	const membershipExpiration = dayjs(subscription?.attributes.termEnd).toDate();
	const cancellationDate = subscription?.attributes.cancelledAt;
	const user = await auth.getUser();
	analytics.Identify(user.value, {
		membershipLevel,
		subscriptionStatus,
		memberType,
		memberStartDate,
		member_start_date: memberStartDate,
		membershipExpiration,
		cancellationDate
	});
}

function trackTransaction(
	productsForAnalytics: any[],
	cartData: Preview | undefined,
	analytics: IAnalytics,
	checkoutType: CheckoutTypes,
	subscription: Subscription | undefined
) {
	if (productsForAnalytics) {
		const total = cartData?.attributes?.total ? (cartData.attributes.total / 100).toFixed(2).toString() : '';
		const tax = cartData?.attributes?.taxAmount ? (cartData.attributes.taxAmount / 100).toFixed(2).toString() : '';
		const revenue = productsForAnalytics
			.map((o) => parseFloat(o.tcv))
			.reduce((a, c) => {
				return a + c;
			})
			.toFixed(2);

		const isMagazinePurchase = checkoutType == CheckoutTypes.Magazine;

		analytics.TrackTransaction(
			{
				ecommerce: {
					currencyCode: 'USD',
					checkoutType: checkoutType,
					purchase: {
						actionField: {
							id: subscription?.attributes.externalId,
							affiliation: isMagazinePurchase ? checkoutType : 'Membership',
							revenue: revenue,
							invoiceAmount: total,
							tax: tax,
							shipping: '0.00'
						},
						products: productsForAnalytics
					}
				}
			},
			cartData
		);
	}
}

function updateUrlParams(
	urlParams: CheckoutURLSearchParams,
	cartData: Preview | undefined,
	spouse: { email?: string; firstName?: string; lastName?: string } | undefined,
	productsForAnalytics: any[],
	subscriptionDataResult: Result<Subscription>,
	checkoutType: CheckoutTypes
) {
	const cartGuid = urlParams.get(QueryParams.CartGuid);
	const encodedCartData = window.btoa(JSON.stringify(cartData));
	const ptuExist = urlParams.get(QueryParams.PtuExist);
	const encodedSpouseData = spouse ? window.btoa(JSON.stringify(spouse)) : '';
	const encodedAnalyticsProducts = window.btoa(
		window.escape(window.encodeURIComponent(JSON.stringify(productsForAnalytics)))
	);
	const levelToSend = productsForAnalytics.find((p) => !!p.level).level.toLowerCase();

	urlParams.set(QueryParams.Cart, encodedCartData);
	urlParams.set(QueryParams.Ps, encodedAnalyticsProducts);
	urlParams.set(QueryParams.PtuExist, ptuExist || '');
	urlParams.set(QueryParams.CartGuid, cartGuid || '');
	urlParams.set(QueryParams.ZExId, subscriptionDataResult.value?.attributes.externalId || '');
	urlParams.set(QueryParams.Level, levelToSend);
	urlParams.set(QueryParams.Ptype, levelToSend);
	urlParams.set(QueryParams.CheckoutType, checkoutType);
	urlParams.set(QueryParams.Spouse, encodedSpouseData);
}
