import { Transaction } from '../core/domain/transaction/transaction';
import { Routes, QueryParams, PaymentFrequencies } from '../core/enums/module';
import { Ptu } from '../core/services/ptu/ptu';
import { getQueryParameter } from './getQueryParameter';
import { BrowserStorage } from '../core/services/browserStorage/browserStorage';
import { SessionStorageKeys } from '../core/services/browserStorage/keys/sessionStorageKeys';
import { LocalStorageKeys } from '../core/services/browserStorage/keys/localStorageKeys';
import { passwordPostCheckoutIsEnabled } from './passwordPostCheckoutIsEnabled';

const NextRouteRecord: Record<Routes, Routes | ''> = {
	[Routes.Root]: '',
	[Routes.Email]: Routes.SignupNextRegistration,
	[Routes.Existing]: Routes.Existing,
	[Routes.SignupNextRegistration]: Routes.SignupRegistration,
	[Routes.SignupRegistration]: Routes.SignupLevel,
	[Routes.SignupVerification]: Routes.SignupLevel,
	[Routes.SignupPassword]: Routes.SignupLevel,
	[Routes.SignupLevel]: Routes.SignupChart,
	[Routes.SignupChart]: Routes.SignupFrequency,
	[Routes.SignupFrequency]: Routes.SignupSpouse,
	[Routes.SignupSpouse]: Routes.SignupNextShipping,
	[Routes.SignupNextShipping]: Routes.SignupShipping,
	[Routes.SignupShipping]: Routes.SignupNextPayment,
	[Routes.SignupNextPayment]: Routes.SignupPayment,
	[Routes.SignupPayment]: Routes.SignupSummary,
	[Routes.SignupSummary]: Routes.PostTransactionReferral,
	[Routes.PostTransactionUpdatePassword]: Routes.PostTransactionReferral,
	[Routes.PostTransactionReferral]: Routes.SignupWelcome,
	[Routes.ProductPTU]: Routes.SignupWelcome,
	[Routes.SignupWelcome]: Routes.SignupWelcome,
	[Routes.SingleClickPurchase]: '',
	[Routes.SSO]: '',
	[Routes.CartAbandonment]: '',
	[Routes.Error]: ''
};

export const getNextRoute = (
	currentRoute: Routes,
	locationSearch = location.search,
	transaction = Transaction.Instance(),
	ptu = Ptu.Instance(),
	mainSessionStorage = BrowserStorage.SessionInstance(),
	localStorageService = BrowserStorage.LocalInstance()
): Routes | '' => {
	const defaultNextRoute = NextRouteRecord[currentRoute];
	const hasProductId = !!getQueryParameter(QueryParams.ProductId, locationSearch);
	const isDirectToCart = !getQueryParameter(QueryParams.PtuExist, locationSearch);
	const funnelId = getQueryParameter(QueryParams.FunnelId, locationSearch);

	const alternativeRouteFunctions: (() => Routes | undefined)[] = [
		() => {
			if (currentRoute === Routes.Email && getQueryParameter(QueryParams.Email, locationSearch)) {
				return Routes.SignupRegistration;
			}
		},
		() =>
			(currentRoute === Routes.SignupRegistration ||
				(currentRoute === Routes.Email && passwordPostCheckoutIsEnabled())) &&
			hasProductId &&
			transaction.Data.purchaseProducts?.primary?.attributes.paymentFrequency === PaymentFrequencies.Annual
				? transaction.SpouseSub
					? Routes.SignupNextShipping
					: Routes.SignupSpouse
				: undefined,
		() =>
			([Routes.SignupRegistration, Routes.SignupPassword, Routes.SignupVerification].includes(currentRoute) ||
				(currentRoute === Routes.Email && passwordPostCheckoutIsEnabled())) &&
			hasProductId
				? Routes.SignupFrequency
				: undefined,
		() =>
			currentRoute === Routes.Email && passwordPostCheckoutIsEnabled()
				? Routes.SignupLevel
				: undefined,
		() => {
			if (
				currentRoute === Routes.SignupFrequency &&
				transaction.Data.purchaseProducts?.primary?.attributes.tier.toLowerCase().includes('magazine')
			) {
				return Routes.SignupNextShipping;
			}
		},
		() => {
			if (currentRoute === Routes.SignupFrequency && transaction.Data.purchaseProducts?.spouse) {
				return Routes.SignupNextShipping;
			}
		},
		() => {
			const ptuWasShown = mainSessionStorage.getItem(SessionStorageKeys.PtuWasShown);

			if (!ptuWasShown) {
				const subscription = mainSessionStorage.getItem(SessionStorageKeys.PurchasedSubscription);

				const ptuUrl = ptu.getPtuUrl(
					transaction.Data.customerObject?.attributes.shippingAddress.state || '',
					subscription?.id || ''
				);
				if (currentRoute === Routes.SignupSummary && ptuUrl) {
					mainSessionStorage.setItem(SessionStorageKeys.PtuWasShown, 'true');
					return ptuUrl as Routes;
				}
			}
		},
		() => {
			if (
				currentRoute === Routes.SignupSummary &&
				localStorageService.getItem(LocalStorageKeys.EmailThatPasswordWasGeneratedFor)
			) {
				return Routes.PostTransactionUpdatePassword;
			}
		},
		() => {
			if (
				[Routes.SignupSummary, Routes.PostTransactionUpdatePassword].includes(currentRoute) &&
				isDirectToCart &&
				funnelId === '3573'
			) {
				return Routes.SignupWelcome;
			}
		},
		() => {
			if (
				[Routes.SignupSummary, Routes.PostTransactionUpdatePassword].includes(currentRoute) &&
				transaction.Data.purchaseProducts?.primary?.attributes.tier.toLowerCase().includes('magazine')
			) {
				return Routes.SignupWelcome;
			}
		},
		() => {
			if ([Routes.SignupSummary, Routes.PostTransactionUpdatePassword].includes(currentRoute) && funnelId === '2813') {
				return Routes.SignupWelcome;
			}
		}
	];

	return [...alternativeRouteFunctions, () => defaultNextRoute].reduce((pv: '' | Routes, cv) => {
		return pv ? pv : cv() || '';
	}, '');
};
