import type { IFeatureFlagRepository } from '@delta-defense/feature-flag-service/service/iFeatureFlagRepository';
import { Environments } from '@delta-defense/client-environments';
import { FeatureFlagService } from '@delta-defense/feature-flag-service';
import { getQueryParameter } from '../../../utilities/getQueryParameter';
import { stateOptions } from '../../../stateOptions';
import { QueryParams } from '../../enums/queryParams';
import { FeatureFlags } from '../../enums/featureFlags';
import { Auth, IAuth } from '../auth/auth';
import { ITracking, Tracking } from '../tracking/tracking';
import { Routes } from '../../enums/routes';
import { ProductTypes } from '../../enums/productTypes';

export interface IPtu {
	getPtuUrl(customerStateAbbreviation: string, subscriptionId: string): string | undefined;
}

export class Ptu implements IPtu {
	private static instance: IPtu | null = null;
	public static Instance(
		getQueryParameterFunc = getQueryParameter,
		features = FeatureFlagService.Instance(),
		auth = Auth.Instance(),
		tracking = Tracking.Instance()
	): IPtu {
		return this.instance || (this.instance = new Ptu(getQueryParameterFunc, features, auth, tracking));
	}
	public static Destroy = () => (Ptu.instance = null);

	private constructor(
		private getQueryParameterFunc: typeof getQueryParameter,
		private features: IFeatureFlagRepository,
		private auth: IAuth,
		private tracking: ITracking
	) {}

	public getPtuUrl(customerStateAbbreviation: string, subscriptionId: string): string | undefined {
		const ptuTypeValue = this.getQueryParameterFunc(QueryParams.PtuType);
		const membershipsAvailable = !!stateOptions.find((state) => state.value == customerStateAbbreviation)
			?.membershipsAvailable;
		const ptuExistsValue = this.getQueryParameterFunc(QueryParams.PtuExist);
		const ptuExistSet = !!ptuExistsValue && ptuExistsValue === '1';
		const productPtuSet = this.features.IsEnabled(FeatureFlags.PtuPhaseOne);
		const useLegacyProductPTU = this.getQueryParameterFunc(QueryParams.UseLegacyProductPTU) && ptuTypeValue === ProductTypes.Product;
		const subscriptionPtuSet = ptuExistSet && membershipsAvailable && ptuTypeValue !== ProductTypes.Product;
		const userId = this.auth.accessTokenObject?.userId;

		if (subscriptionId && userId) {
			if (subscriptionPtuSet || useLegacyProductPTU) {
				return this.buildPtuUrl(subscriptionId, userId);
			} else if (productPtuSet) {
				return Routes.ProductPTU;
			}
		}
	}

	private buildPtuUrl = (subscriptionId: string, userId: string): string => {
		const baseUrl = `${Environments.getDeltaDefenseDomain('https://uscca.local')}/ptu/get-url`;
		const cartGuid = this.getQueryParameterFunc(QueryParams.CartGuid);
		const tID = this.tracking.getTid();

		return `${baseUrl}?cartGuid=${cartGuid}&subscriptionId=${subscriptionId}&uguid=${userId}&tID=${tID}`;
	};
}
