import { InteractionEvent } from '../../eventTypes/interactionEvent';
import { InteractionEventNames, Locations, Steps } from '../../enums/module';
import { RouteStepRecord } from '../../../../../utilities/routeStepRecord';
import { stepToFormFieldRecord } from '../../stepToFormFieldRecord';

export const supportNumberClicked = (
	location: Locations,
	locationPathname = window.location.pathname,
	additionalEventsData?: Record<string, string>
): InteractionEvent => {
	const step = RouteStepRecord[locationPathname] || Steps.One;
	return {
		event_name: InteractionEventNames.CtaClick,
		events: {
			...additionalEventsData,
			click_text: '855-388-4077',
			form_field: stepToFormFieldRecord[step],
			location: location,
			sub_location: `Step ${step}`
		},
		step: step
	};
};
