import React from 'react';
import { IFeatureFlagRepository } from '@delta-defense/feature-flag-service/service/iFeatureFlagRepository';
import { FeatureFlagService } from '@delta-defense/feature-flag-service';
import { Locations } from '../../core/services/analytics/module';
import { FeatureFlags } from '../../core/enums/featureFlags';
import { SupportPhoneLink } from '../SupportPhoneLink/SupportPhoneLink';
import styles from './footer.module.scss';

export const Footer: React.FunctionComponent<{ enableSpacer?: boolean; features?: IFeatureFlagRepository }> = ({
	enableSpacer = false,
	features = FeatureFlagService.Instance()
}) => {
	const stickyBottomMobileEnabled = features.IsEnabled(FeatureFlags.CheckoutMobileSticky);
	return (
		<footer className={styles.footer}>
			<div className={styles.container}>
				<div className={styles.copyRights}>
					<div className={styles.logoAndPhone}>
						<img src="/img/deltadefense-logo.svg" alt="Delta Defense LLC" />
						<div>
							<p>
								<SupportPhoneLink location={Locations.Footer} />
							</p>
						</div>
					</div>
					<hr />
					<div className={styles.terms}>
						<global-footer site="deltadefense"></global-footer>
					</div>
				</div>
				{stickyBottomMobileEnabled && enableSpacer && <div className={styles.spacerForStickyBottomButton}></div>}
			</div>
		</footer>
	);
};
