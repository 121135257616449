import { InteractionEventNames, Locations, Steps } from '../../enums/module';
import { InteractionEvent } from '../../eventTypes/module';
import { stepToFormFieldRecord } from '../../stepToFormFieldRecord';

export const ctaClick = (clickText: string, step: Steps, subLocation?: string, additionalEventsData?: Record<string, string>): InteractionEvent => {
	return {
		event_name: InteractionEventNames.CtaClick,
		step: step,
		events: {
			...additionalEventsData,
			click_text: clickText,
			location: Locations.Content,
			sub_location: subLocation || `Step ${step}`,
			form_field: stepToFormFieldRecord[step]
		}
	};
};
