import { Step } from '../core/domain/transaction/step';
import { LevelSelection } from './steps/default/levelSelection';
import {
	EmailCheck, PasswordLogin, VerificationLogin, EmptyStep,
	CreateAccount, BillingFrequency, SpouseAddOn, AddressInfo,
	Payment, SubmitOrder
} from './steps/default/module';
import { UpdatePassword } from './steps/default/updatePassword';

export enum DefaultSteps {
	EmailCheck = 'emailCheck',
	VerificationLogin = 'verificationLogin',
	PasswordLogin = 'passwordLogin',
	CreateAccountIntro = 'createAccountIntro',
	CreateAccount = 'createAccount',
	LevelSelectionIntro = 'levelSelectionIntro',
	LevelSelection = 'levelSelection',
	BillingFrequency = 'billingFrequency',
	SpouseAddOn = 'spouseAddOn',
	NextShipping = 'nextShipping',
	Shipping = 'shipping',
	NextPayment = 'nextPayment',
	Payment = 'payment',
	Summary = 'summary',
	PostTransactionUpdatePassword = 'postTransactionUpdatePassword',
	PostTransactionReferral = 'postTransactionReferral'
}

export const defaultSteps: () => Record<DefaultSteps, Step> = () => ({
	[DefaultSteps.EmailCheck]: EmailCheck(),
	[DefaultSteps.VerificationLogin]: VerificationLogin(),
	[DefaultSteps.PasswordLogin]: PasswordLogin(),
	[DefaultSteps.CreateAccountIntro]: EmptyStep(),
	[DefaultSteps.CreateAccount]: CreateAccount(),
	[DefaultSteps.LevelSelectionIntro]: EmptyStep(),
	[DefaultSteps.LevelSelection]: LevelSelection(),
	[DefaultSteps.BillingFrequency]: BillingFrequency(),
	[DefaultSteps.SpouseAddOn]: SpouseAddOn(),
	[DefaultSteps.NextShipping]: EmptyStep(),
	[DefaultSteps.Shipping]: AddressInfo(),
	[DefaultSteps.NextPayment]: EmptyStep(),
	[DefaultSteps.Payment]: Payment(),
	[DefaultSteps.Summary]: SubmitOrder(),
	[DefaultSteps.PostTransactionUpdatePassword]: UpdatePassword(),
	[DefaultSteps.PostTransactionReferral]: EmptyStep()
});
