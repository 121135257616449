export function getJSXInnerText(jsx: any): string {
	if (typeof jsx === 'string') {
		return jsx.trim();
	}
	if (typeof jsx?.forEach === 'function') {
		let string = '';
		jsx.forEach((node) => {
			const newText = typeof node === 'string' ? node : getJSXInnerText(node);
			string = `${string.trim()} ${newText.trim()}`;
		});
		return string.trim();
	}
	if (typeof jsx === 'function') {
		try {
			const result = jsx(jsx?.props || {});
			return getJSXInnerText(result);
		} catch {
			// do nothing;
		}
		return '';
	}
	if (jsx?.props?.children) {
		return getJSXInnerText(jsx?.props?.children);
	}
	if (typeof jsx?.type === 'function') {
		return getJSXInnerText(jsx.type(jsx?.props || {}));
	}
	return '';
}
