import React, { ReactNode } from 'react';
import { ITransaction } from '../../core/domain/module';
import { BreakingErrors } from '../../core/enums/breakingErrors';
import { IAnalytics } from '../../core/services/analytics/analytics';
import { EventTypes } from '../../core/services/analytics/module';
import ErrorPage from '../ErrorPage/ErrorPage';
import Layout from '../Layout/Layout';

interface State {
	hasError: boolean;
}

interface Props {
	children?: ReactNode;
	analyticsService: IAnalytics;
	transaction: ITransaction;
}

class ErrorBoundary extends React.Component<Props, State> {
	public analyticsService: IAnalytics;
	public transaction: ITransaction;
	constructor(props) {
		super(props);
		this.state = { hasError: false };
		this.analyticsService = props.analyticsService;
		this.transaction = props.transaction;
	}

	static getDerivedStateFromError(_err: Error) {
		return { hasError: true };
	}

	public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
		this.analyticsService.Track(EventTypes.Error, {
			event_name: 'clientSideError',
			error_name: error.name,
			error_text: error.message,
			error_stack: error.stack?.slice(0, 200),
			user_id: this.transaction.Data.userObject?.id ? this.transaction.Data.userObject?.id : 'undefined'
		});
		if (this.state.hasError) {
			this.transaction.Error.Publish(BreakingErrors.ClientSideError);
		}
	}

	public render() {
		return this.state.hasError ? (
			<>
				<Layout>
					<ErrorPage />
				</Layout>
			</>
		) : (
			this.props.children
		);
	}
}

export default ErrorBoundary;
