/* eslint-disable max-lines */
import Environments from '@delta-defense/client-environments';
import { Apis } from '@delta-defense/client-environments/environments/enums/apis';
import { Result } from '../../domain/result';
import {
	Customer,
	PaymentMethod,
	Preview,
	ProductInvoice,
	Signature,
	Subscription,
	SubscriptionProduct
} from '../../models/module';
import { queryWithRedundancy } from '../../../utilities/queryWithRedundancy';
import { getNationalPhoneNumber } from '../../../utilities/inputMasks/phoneNumberMask';
import { IRest, ResponseOf, Rest } from '../rest/rest';
import { Analytics, IAnalytics, InternalEventNames, Steps } from '../analytics/module';
import { Auth, IAuth } from '../auth/auth';
import { ZuoraAchPageIds, ZuoraCardPageIds } from './pageIds';

export interface ISubscriptions {
	getCustomer(): Promise<Result<{ customer: Customer | null; subscriptions: Subscription[] }>>;
	getCustomerChild(): Promise<Result<Customer>>;
	getDigitalSignature(useAch: boolean): Promise<Result<Signature>>;
	getPreview(membership: any, magazine: any): Promise<Result<Preview>>;
	getProductsByTier(productTier: string): Promise<Result<SubscriptionProduct[]>>;
	getProductsById(productId: string): Promise<Result<SubscriptionProduct[]>>;
	createCustomer(shippingAddress: any, billingAddress: any, primaryPhone?: string): Promise<Result<Customer>>;
	createPaymentMethod(externalId: string): Promise<Result<PaymentMethod>>;
	createSubscription(
		membership: any,
		magazine: any,
		tracking: any,
		bundleId: string,
		giveawayId: string,
		msId: string,
		affiliateData: any,
		previewData?: Preview
	): Promise<Result<Subscription>>;
	updateCustomer(
		customerId: string,
		shippingAddress: any,
		billingAddress: any,
		primaryPhone?: string
	): Promise<Result<Customer>>;
	updateSubscription(
		subscriptionId: string,
		membership: any,
		magazine: any,
		tracking: any,
		bundleId: string,
		giveawayId: string,
		msId: string,
		affiliateData: any,
		previewData?: Preview
	): Promise<Result<Subscription>>;
	getDefaultPaymentMethod(): Promise<Result<PaymentMethod>>;
	purchaseBundle(bundleId: string): Promise<Result<ProductInvoice>>;
}

export type LocalDevStagingEnvironments = {
	isLocal: () => boolean;
	isDev: () => boolean;
	isStaging: () => boolean;
};

export class Subscriptions implements ISubscriptions {
	private static instance: ISubscriptions | null = null;

	public static Instance(
		rest = Rest.Instance(),
		analytics = Analytics.Instance(),
		auth = Auth.Instance(),
		environments: LocalDevStagingEnvironments = {
			isLocal: () => Environments.isLocal(),
			isDev: () => Environments.isDev(),
			isStaging: () => Environments.isStaging()
		},
		subscriptionsProxyDomain = Environments.getProxyEndpointForApi(Apis.Subscriptions)
	): ISubscriptions {
		return this.instance || (this.instance = new Subscriptions(
			rest,
			analytics,
			environments,
			auth,
			subscriptionsProxyDomain));
	}

	public static Destroy() {
		this.instance = null;
	}

	private constructor(
		private rest: IRest,
		private analytics: IAnalytics,
		private environments: LocalDevStagingEnvironments,
		private auth: IAuth,
		private subscriptionsProxyDomain: string
	) { }

	async getCustomer(): Promise<Result<{ customer: Customer | null; subscriptions: Subscription[] }>> {
		const result = await this.rest.Get<{
			data: Customer;
			included: Subscription[];
		}>(
			`${this.subscriptionsProxyDomain}/customers/${encodeURIComponent(
				this.auth.accessTokenObject?.userId || ''
			)}?include[]=subscriptions`,
			{
				'X-Auth-Token': this.auth.accessToken
			}
		);

		if (result.ok && result.body?.data) {
			let subscriptions = result.body.included || [];

			if (result.body.data.attributes.parentId) {
				const subsResponse = await this.rest.Get<{ data: Subscription[] }>(
					`${this.subscriptionsProxyDomain}/customers/${encodeURIComponent(this.auth.accessTokenObject?.userId || '')}/subscriptions`,
					{
						'X-Auth-Token': this.auth.accessToken
					}
				);

				subscriptions = subscriptions.concat(subsResponse.ok && subsResponse.body ? subsResponse.body.data : []);
			}

			return {
				wasSuccessful: true,
				value: {
					customer: result.body.data,
					subscriptions: subscriptions
				}
			};
		} else if (result.status === 404) {
			return {
				wasSuccessful: true,
				value: {
					customer: null,
					subscriptions: []
				}
			};
		}

		return {
			wasSuccessful: false
		};
	}

	async getCustomerChild(): Promise<Result<Customer>> {
		return this.getResultFromResponse(
			await this.rest.Get<{
				data: Customer;
			}>(`${this.subscriptionsProxyDomain}/customers/${encodeURIComponent(this.auth.accessTokenObject?.userId || '')}/child`, {
				'X-Auth-Token': this.auth.accessToken
			})
		);
	}

	async getDigitalSignature(useAch = false): Promise<Result<Signature>> {
		const paymentPageId = this.getPageId(useAch);

		return this.getResultFromResponse(
			await this.rest.Post<{ data: Signature }>(`${this.subscriptionsProxyDomain}/rsa/signature`, JSON.stringify({ paymentPageId })),
			(signature) => {
				return !!(
					signature.id &&
					signature.attributes &&
					signature.attributes.key &&
					signature.attributes.signature &&
					signature.attributes.tenantId &&
					signature.attributes.token &&
					signature.attributes.url
				);
			}
		);
	}

	private getPageId(useAch = false) {
		const pageIds = useAch ? ZuoraAchPageIds : ZuoraCardPageIds;
		let paymentPageId = pageIds.Production;
		if (this.environments.isLocal()) {
			paymentPageId = pageIds.Local;
		} else if (this.environments.isDev()) {
			paymentPageId = pageIds.Dev;
		} else if (this.environments.isStaging()) {
			paymentPageId = pageIds.Staging;
		} else if (location.origin.includes('.deltadefensecom-checkout-gitlab.pages.dev')) {
			paymentPageId = pageIds.ProductionPreview;
		}
		return paymentPageId;
	}

	async getPreview(membership: any, magazine: any): Promise<Result<Preview>> {
		const subscription: any = {};
		if (membership) {
			subscription.membership = membership;
		}
		if (magazine) {
			subscription.magazine = magazine;
		}

		return this.getResultFromResponse(
			await queryWithRedundancy<ResponseOf<{ data: Preview }>>(
				() =>
					this.rest.Post<{ data: Preview }>(`${this.subscriptionsProxyDomain}/subscriptions/preview`, JSON.stringify(subscription), {
						'X-Auth-Token': this.auth.accessToken
					}),
				(response) => response.ok,
				3000,
				1
			)
		);
	}

	async getProductsByTier(productTier: string): Promise<Result<SubscriptionProduct[]>> {
		const result = await this.getResultFromResponse(
			await this.rest.Get<{ data: SubscriptionProduct[] }>(`${this.subscriptionsProxyDomain}/products?tags[]=${productTier}`)
		);

		result.value = result.value?.filter(p => !p.attributes.tags.find(t => ['Group', 'Lifetime'].includes(t.tag)));

		return result;
	}

	async getProductsById(productId: string): Promise<Result<SubscriptionProduct[]>> {
		const productIdBlacklist = [
			'bf08e4cc-70b0-11eb-8d5f-02420a0201b3',
			'c25e665e-87fa-11eb-a668-02420a020cf8',
			'170af6ec-15a2-11ec-86e3-02420a00013f'
		];
		const response = this.getResultFromResponse(
			await this.rest.Get<{ data: SubscriptionProduct[] }>(`${this.subscriptionsProxyDomain}/products/${productId}?include=related`)
		);

		if (response.wasSuccessful) {
			response.value = response.value?.filter((p) => p.attributes.price > 0 && !productIdBlacklist.includes(p.id));
		}

		return response;
	}

	async createCustomer(shippingAddress: any, billingAddress: any, primaryPhone?: string): Promise<Result<Customer>> {
		const requestBody: {
			shippingAddress: any,
			billingAddress: any,
			primaryPhone?: string
		} = {
			shippingAddress,
			billingAddress
		};

		if (primaryPhone) {
			requestBody.primaryPhone = primaryPhone;
		}

		return this.getResultFromResponse(
			await this.rest.Post<{ data: Customer }>(
				`${this.subscriptionsProxyDomain}/customers`,
				JSON.stringify(requestBody),
				{
					'X-Auth-Token': this.auth.accessToken
				}
			)
		);
	}

	async createPaymentMethod(externalId: string): Promise<Result<PaymentMethod>> {
		return this.getResultFromResponse(
			await this.rest.Post<{ data: PaymentMethod }>(
				`${this.subscriptionsProxyDomain}/payment-methods`,
				JSON.stringify({
					externalId,
					isDefault: true
				}),
				{
					'X-Auth-Token': this.auth.accessToken
				}
			)
		);
	}

	async createSubscription(
		membership?: any,
		magazine?: any,
		tracking?: any,
		bundleId?: string,
		giveawayId?: string,
		msId?: string,
		affiliateData?: any,
		previewData?: Preview,
		ignoreReferralCode = false
	): Promise<Result<Subscription>> {
		if (!previewData) {
			const previewResponse = await this.getPreview(membership, magazine);

			if (!previewResponse.wasSuccessful) {
				this.analytics.TrackInternal({
					event_name: InternalEventNames.FailedToGetPreviewForNewSubscription,
					step: Steps.Four
				});
			}
			previewData = previewResponse.value;
		}

		const subscription: any = this.getSubscriptionRequestBody(
			membership,
			magazine,
			tracking,
			bundleId,
			giveawayId,
			msId,
			affiliateData,
			previewData
		);

		const result = await this.rest.Post<any>(`${this.subscriptionsProxyDomain}/subscriptions`, JSON.stringify(subscription), {
			'X-Auth-Token': this.auth.accessToken
		});

		if (result.ok && result.body?.data) {
			return {
				wasSuccessful: true,
				value: result.body.data
			};
		} else if (!ignoreReferralCode && result.status == 422 && membership) {
			delete membership.referralCode;
			return await this.createSubscription(
				membership,
				magazine,
				tracking,
				bundleId,
				giveawayId,
				msId,
				affiliateData,
				previewData,
				true
			);
		}

		return {
			wasSuccessful: false
		};
	}

	async updateCustomer(
		customerId: string,
		shippingAddress: any,
		billingAddress: any,
		primaryPhone?: string
	): Promise<Result<Customer>> {
		const requestBody: {
			shippingAddress: any,
			billingAddress: any,
			primaryPhone?: string
		} = { billingAddress, shippingAddress };

		if (primaryPhone) {
			requestBody.primaryPhone = getNationalPhoneNumber(primaryPhone);
		}

		return this.getResultFromResponse(
			await this.rest.Patch<{ data: Customer }>(
				`${this.subscriptionsProxyDomain}/customers/${encodeURIComponent(customerId)}`,
				JSON.stringify(requestBody),
				{
					'X-Auth-Token': this.auth.accessToken
				}
			)
		);
	}

	async updateSubscription(
		subscriptionId: string,
		membership: any,
		magazine: any,
		tracking: any,
		bundleId: string,
		giveawayId: string,
		msId: string,
		affiliateData: any,
		previewData?: Preview
	): Promise<Result<Subscription>> {
		if (!previewData) {
			const previewResponse = await this.getPreview(membership, magazine);
			if (!previewResponse.wasSuccessful) {
				this.analytics.TrackInternal({
					event_name: InternalEventNames.FailedToGetPreviewForNewSubscription,
					step: Steps.Four
				});
			}
			previewData = previewResponse.value;
		}

		const subscription: any = this.getSubscriptionRequestBody(
			membership,
			magazine,
			tracking,
			bundleId,
			giveawayId,
			msId,
			affiliateData,
			previewData
		);

		return this.getResultFromResponse(
			await this.rest.Put<any>(
				`${this.subscriptionsProxyDomain}/subscriptions/${encodeURIComponent(subscriptionId)}`,
				JSON.stringify(subscription),
				{
					'X-Auth-Token': this.auth.accessToken
				}
			)
		);
	}

	async getDefaultPaymentMethod(): Promise<Result<PaymentMethod>> {
		const endpoint = `${this.subscriptionsProxyDomain}/customers/${this.auth.accessTokenObject?.userId}/payment-methods`;
		const response = await this.rest.Get<{ data: PaymentMethod[] }>(endpoint, {
			'X-Auth-Token': this.auth.accessToken
		});

		const defaultPaymentMethod =
			response.ok && response.body.data
				? response.body.data.find(
					(p) => p.attributes.isDefault === true && (
						p.type === 'ach' ||
						(p.type === 'card' && new Date() < new Date(p.attributes.detail?.expYear || 0, p.attributes.detail?.expMonth || 0 + 1))
					))
				: undefined;

		return {
			wasSuccessful: response.ok && !!defaultPaymentMethod,
			value: defaultPaymentMethod
		};
	}

	async purchaseBundle(bundleId: string): Promise<Result<ProductInvoice>> {
		const endpoint = `${this.subscriptionsProxyDomain}/purchases`;
		const response = await this.rest.Post<{ data: ProductInvoice }>(endpoint, JSON.stringify({ bundleId }), {
			'X-Auth-Token': this.auth.accessToken
		});

		return {
			wasSuccessful: response.ok,
			value: response.body?.data
		};
	}

	private getSubscriptionRequestBody(
		membership: any,
		magazine: any,
		tracking: any,
		bundleId: string | undefined,
		giveawayId: string | undefined,
		msId: string | undefined,
		affiliateData: any,
		previewData: Preview | undefined
	): any {
		const subscriptionTraits = {
			membership,
			magazine,
			tracking,
			bundleId,
			campaignId: giveawayId,
			msId,
			codedToMsId: msId,
			phoneLine: msId && 'Online',
			salesTypeCoding: msId && '-',
			affiliate: affiliateData?.id && affiliateData,
			subtotal: previewData && previewData.attributes?.subtotal,
			taxAmount: previewData && previewData.attributes?.taxAmount,
			total: previewData && previewData.attributes?.total
		};

		const subscription: any = {};
		Object.keys(subscriptionTraits).forEach((key) => {
			if (subscriptionTraits[key]) {
				subscription[key] = subscriptionTraits[key];
			}
		});
		return subscription;
	}

	private getResultFromResponse<T>(response: ResponseOf<{ data: T }>, validation?: (model: T) => boolean): Result<T> {
		if (response.ok && response.body?.data && (validation ? validation(response.body.data) : true)) {
			return {
				wasSuccessful: true,
				value: response.body.data
			};
		}

		return {
			wasSuccessful: false
		};
	}
}
