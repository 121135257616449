import React, { useState, useEffect } from 'react';
import { IFeatureFlagRepository } from '@delta-defense/feature-flag-service/service/iFeatureFlagRepository';
import { Icons } from '../../icons';
import { BonusesDisplayed } from '../bonusesDrawer/bonusesDrawer';
import { BackButton } from '../backButton/backButton';
import styles from './header.module.scss';

/* eslint-disable max-len */
const usccaLogoToolTip =
	'The USCCA helps responsible Americans like you prepare for what happens before, during and after an act of lawful self-defense.';
const deltaDefenseLogoToolTip =
	'Delta Defense, LLC provides sales, marketing, operations and administrative support services to the USCCA and is a licensed insurance agency in all 50 states.';
/* eslint-enable max-len */

type Props = {
	bonusesDisplayed?: typeof BonusesDisplayed,
	BackButtonComponent?: typeof BackButton,
	features?: IFeatureFlagRepository
}

export const Header: React.FunctionComponent<Props> = ({
	bonusesDisplayed = BonusesDisplayed,
	BackButtonComponent = BackButton
}) => {
	const [applyBonusOffset, setApplyBonusOffset] = useState(false);
	let bonusesDisplayedSubId = '';

	useEffect(() => {
		bonusesDisplayedSubId = bonusesDisplayed.Subscribe(b => {
			setApplyBonusOffset(!!b);
		});

		return () => {
			bonusesDisplayed.Cancel(bonusesDisplayedSubId);
		};
	}, []);

	return (
		<>
			<header className={styles.header} role="banner">
				<div className={styles.container}>
					<div>
						<div className={styles.usccaLogo} title={usccaLogoToolTip}>
							{Icons.USCCAShield}
						</div>
					</div>

					<div className={styles.deltaDefenseLogo} title={deltaDefenseLogoToolTip}>
						<em className={styles.tablet}>Presented by</em>
						{Icons.DeltaDefenseLogo}
						<p className="sr-only">Delta Defense LLC</p>

					</div>
				</div>
			</header>

			<BackButtonComponent additionalClass={applyBonusOffset ? styles.bonusDrawerOffset : ''} />
		</>
	);
};
