import { LocalStorageKeys } from './keys/localStorageKeys';
import { SessionStorageKeys } from './keys/sessionStorageKeys';
import { LocalStorageValues } from './values/localStorageValues';
import { SessionStorageValues } from './values/sessionStorageValues';

interface IBrowserStorage {
	setItem: (storageKey: any, storageValue: any) => void;
	getItem: (storageKey: any) => any | null;
	getStringValue: (storageKey: any) => string | null;
	setStringValue: (storageKey: any, value: string) => void;
	removeItem: (storageKey: any) => void;
	clear: (saveThese: any[]) => void;
}

export interface ISessionStorage<V extends Record<SessionStorageKeys, any> = SessionStorageValues>
	extends IBrowserStorage {
	setItem: <T extends SessionStorageKeys>(storageKey: T, storageValue: V[T] | undefined) => void;
	getItem: <T extends SessionStorageKeys>(storageKey: T) => V[T] | null;
	getStringValue: <T extends SessionStorageKeys>(storageKey: T) => string | null;
	setStringValue: (storageKey: SessionStorageKeys, value: string) => void;
	removeItem: (storageKey: SessionStorageKeys) => void;
	clear: (saveThese?: SessionStorageKeys[]) => void;
}

export interface ILocalStorage<V extends Record<LocalStorageKeys, any> = LocalStorageValues> extends IBrowserStorage {
	setItem: <T extends LocalStorageKeys>(storageKey: T, storageValue: V[T] | undefined) => void;
	getItem: <T extends LocalStorageKeys>(storageKey: T) => V[T] | null;
	getStringValue: <T extends LocalStorageKeys>(storageKey: T) => string | null;
	setStringValue: (storageKey: LocalStorageKeys, value: string) => void;
	removeItem: (storageKey: LocalStorageKeys) => void;
	clear: (saveThese?: LocalStorageKeys[]) => void;
}

enum StorageOptions {
	Session = 'sessionStorage',
	Local = 'localStorage'
}

export class BrowserStorage implements IBrowserStorage {
	private keys: LocalStorageKeys[] | SessionStorageKeys[] = [];
	private storage: Storage | null = null;
	private storageOptionHandlers: Record<StorageOptions, (storageOverride?: Storage) => void> = {
		[StorageOptions.Local]: (storageOverride?: Storage) => {
			if (storageOverride) {
				this.storage = storageOverride;
			} else {
				this.storage = typeof localStorage === 'undefined' ? null : localStorage;
			}
			this.keys = Object.values(LocalStorageKeys);
		},
		[StorageOptions.Session]: (storageOverride?: Storage) => {
			if (storageOverride) {
				this.storage = storageOverride;
			} else {
				this.storage = typeof sessionStorage === 'undefined' ? null : sessionStorage;
			}
			this.keys = Object.values(SessionStorageKeys);
		}
	};
	private static sessionStorageInstance: IBrowserStorage | null = null;
	private static localStorageInstance: IBrowserStorage | null = null;
	public static SessionInstance(storageOverride?: Storage): ISessionStorage {
		return (
			(this.sessionStorageInstance as ISessionStorage | null) ||
			(this.sessionStorageInstance = new BrowserStorage(StorageOptions.Session, storageOverride) as ISessionStorage)
		);
	}
	public static LocalInstance(storageOverride?: Storage): ILocalStorage {
		return (
			(this.localStorageInstance as ILocalStorage | null) ||
			(this.localStorageInstance = new BrowserStorage(StorageOptions.Local, storageOverride) as ILocalStorage)
		);
	}

	private constructor(option: StorageOptions, storageOverride?: Storage) {
		this.storageOptionHandlers[option](storageOverride);
	}

	public static Destroy = () => {
		BrowserStorage.localStorageInstance = null;
		BrowserStorage.sessionStorageInstance = null;
	};

	public getItem = (storageKey) => {
		if (this.storage) {
			try {
				const value = this.storage.getItem(storageKey);
				return value ? JSON.parse(value) : null;
			} catch (error) {
				return null;
			}
		}
		return null;
	};

	public setItem = (storageKey, storageValue) => {
		if (this.storage) {
			this.storage.setItem(storageKey, JSON.stringify(storageValue || null));
		}
	};

	public removeItem(storageKey) {
		if (this.storage) {
			this.storage.removeItem(storageKey);
		}
	}

	public getStringValue = (storageKey) => {
		if (this.storage) {
			return this.storage.getItem(storageKey);
		}
		return null;
	};

	public setStringValue = (storageKey, value) => {
		if (this.storage) {
			return this.storage.setItem(storageKey, value);
		}
	};

	public clear = (saveThese: any[] = []) => {
		Object.values(this.keys)
			.filter((k) => !saveThese.includes(k))
			.forEach((key) => this.removeItem(key));
	};
}
