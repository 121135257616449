import type { LeadToken, LeadData } from '../../models/module';
import type { Result } from '../../domain/result';
import Environments from '@delta-defense/client-environments';
import { Apis } from '@delta-defense/client-environments/environments/enums/apis';
import { getQueryParameter } from '../../../utilities/getQueryParameter';
import { safelyReturn } from '../../../utilities/safelyReturn';
import { QueryParams } from '../../enums/queryParams';
import { ITransaction } from '../../domain/transaction/ITransaction';
import { Transaction } from '../../domain/transaction/transaction';
import { CookieKeys, Cookies, ICookies } from '../cookies/cookies';
import { IRest, ResponseOf, Rest } from '../rest/rest';

type LeadTokenResponse = { data?: LeadToken };

export interface ITracking {
	getTid(locationSearch?: string): string;
	getFunnelId(locationSearch?: string): Promise<Result<string>>;
	getTrackingFields(locationSearch?: string): Promise<LeadData>;
	getTrackingFieldsConsideringCookie(): LeadData;
}

export class Tracking implements ITracking {
	private static instance: ITracking | null = null;
	public static Instance(
		rest = Rest.Instance(),
		cookies = Cookies.Instance(),
		getTransaction = () => Transaction.Instance()
	): ITracking { return this.instance || (this.instance = new Tracking(rest, cookies, getTransaction)); }
	public static Destroy = () => Tracking.instance = null;
	private readonly defaultTid = '648b4db589160';

	private leadTokenCache: Record<string, Promise<ResponseOf<LeadTokenResponse>>> = {};

	private constructor(
		private rest: IRest,
		private cookies: ICookies,
		private getTransaction: () => ITransaction
	) { }

	public getTid(locationSearch = location.search): string {
		return getQueryParameter(QueryParams.Tid, locationSearch) ||
			this.getTrackingFieldsConsideringCookie().tID;
	}

	public async getFunnelId(locationSearch = location.search): Promise<Result<string>> {
		const transaction = this.getTransaction();

		let funnelId = transaction.Data.funnelId || getQueryParameter(QueryParams.FunnelId, locationSearch) || '';
		if (funnelId) {
			transaction.Data.funnelId = funnelId;
			return {
				wasSuccessful: true,
				value: funnelId
			};
		}

		const leadToken = await this.getLeadToken();

		try {
			if (leadToken.wasSuccessful && leadToken.value?.attributes?.targetUrl) {
				const targetUrl = new URL(leadToken.value.attributes.targetUrl);
				const apiFunnelPath = '/offers/';
				const pathEndsWithFunnelId = targetUrl.pathname.startsWith(apiFunnelPath) && targetUrl.pathname.split('/').length === 4;
				funnelId = pathEndsWithFunnelId ? targetUrl.pathname.split('/')[2] : '';
				transaction.Data.funnelId = funnelId;
			}
		} catch (error) {
			console.error(error);
		}

		return {
			wasSuccessful: leadToken.wasSuccessful && !!leadToken.value,
			value: funnelId
		};
	}

	public async getTrackingFields(locationSearch = location.search): Promise<LeadData> {
		const tid = this.getTid(locationSearch);
		const cookieLeadFields = this.getTrackingFieldsConsideringCookie();

		if (tid === cookieLeadFields.tID) {
			return cookieLeadFields;
		} else {
			const apiLeadFields = { tID: tid };
			const leadToken = await this.getLeadToken();
			if (leadToken.wasSuccessful && leadToken.value) {
				['origin', 'medium', 'campaign', 'audience', 'targeting', 'pageType', 'type'].forEach(k => {
					leadToken.value?.attributes?.[k] && (apiLeadFields[k] = leadToken.value?.attributes?.[k]);
				});
			}

			return {
				...cookieLeadFields,
				...apiLeadFields
			};
		}
	}

	public getTrackingFieldsConsideringCookie(): LeadData {
		const defaultLeadFields: LeadData = {
			tID: this.defaultTid,
			origin: 'Unknown',
			medium: '-',
			campaign: '-',
			audience: '-',
			targeting: '-',
			pageType: '-',
			type: 'New Account'
		};

		const leadsCookie = this.cookies.get(CookieKeys.Leads);
		const cookieLeadFields = safelyReturn(() => JSON.parse(window.atob(leadsCookie) || '{}'), {});

		return {
			...defaultLeadFields,
			...cookieLeadFields
		};
	}

	private async getLeadToken(): Promise<Result<LeadToken>> {
		const tid = this.getTid();
		const request = this.leadTokenCache[tid] || (this.leadTokenCache[tid] =
			this.rest.Get<LeadTokenResponse>(`${Environments.getProxyEndpointForApi(Apis.Tracking)}/tokens/${tid}`));
		const response = await request;

		return {
			wasSuccessful: response.ok && !!response.body,
			value: response.body?.data
		};
	}

}
