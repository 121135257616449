/* eslint-disable max-lines */
import React, { useEffect, useState } from 'react';
import * as snippet from '@segment/snippet';
import Environments from '@delta-defense/client-environments';
import { CheckoutStartedEvent } from '@delta-defense/frontend-analytics/events/checkoutStartedEvent';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { Transaction } from '../core/domain/module';
import { Analytics, backButtonClicked, Locations } from '../core/services/analytics/module';
import { BreakingErrors, Routes, ClientSafeTokens, Messages, QueryParams, FeatureFlags } from '../core/enums/module';
import { defaultSteps } from '../configuration/default';
import { safelyExecute } from '../utilities/safelyExecute';
import { navigateUserToFirstStep } from '../utilities/navigateUserToFirstStep';
import { trackRouteChangeEvents } from '../utilities/trackRouteChangeEvents';
import { loadDataLayer } from '../utilities/loadDataLayer';
import { initializeFeatureFlagService } from '../utilities/initializeFeatureFlagService';
import { doWhen } from '../utilities/doWhen';
import { executeWithinBoundary } from '../utilities/executeWithinBoundary';
import { getQueryParameters } from '../utilities/getQueryParameter';
import ErrorModal from '../components/ErrorModal/ErrorModal';
import { Loading } from '../components/Loading/Loading';
import { BonusesDrawer } from '../components/bonusesDrawer/bonusesDrawer';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import Layout from '../components/Layout/Layout';
import { SignUpRoutes } from '../signUpRoutes';
import '../styles/styles.scss';
import { AmacIntegration } from '../core/services/module';

function getSegmentSnippet() {
	const analyticsWriteKey = Environments.isProduction() ? ClientSafeTokens.SegmentProdWriteKey : ClientSafeTokens.SegmentPreProdWriteKey;

	const opts = {
		apiKey: analyticsWriteKey,
		page: false
	};

	const snippetWithDataLayer = snippet.min(opts);
	return snippetWithDataLayer;
}

// eslint-disable-next-line complexity
export default function CheckoutApp({
	Component,
	pageProps,
	featureFlagService = initializeFeatureFlagService(),
	analytics = Analytics.Instance(),
	loadDataLayerFunc = loadDataLayer,
	router = useRouter(),
	transaction = Transaction.Instance()
}) {
	const [featureFlagsLoaded, setFeatureFlagsLoaded] = useState(false);
	const [onSignUpRoute, setOnSignUpRoute] = useState(false);
	const browserContext = !!globalThis.window;
	const userHasPurchased = !!(typeof location !== 'undefined' && getQueryParameters().get(QueryParams.SubscriptionId));

	useEffect(() => {
		import('../../node_modules/@delta-defense/address-validation-service/components/useValidatedAddressModal/bundle');
		import('../../node_modules/@delta-defense/address-validation-service/components/addressPredictionField/bundle');
		let errorSub: string;
		const getTransactionError = () => transaction.Error;
		const setErrorSub = () => {
			errorSub = getTransactionError().Subscribe((e) => {
				if (Object.values(BreakingErrors).includes(e as BreakingErrors)) {
					router.push(`/error${location.search}`);
				}
			});
		};
		const handleEventsOnRouteChange = () => {
			setOnSignUpRoute(SignUpRoutes.includes(location.pathname as Routes));
			trackRouteChangeEvents(location.pathname as Routes);
		};

		safelyExecute(() => {
			if (!location.pathname.includes('callback')) {
				let attempts = 0;
				doWhen(
					() => {
						// eslint-disable-next-line no-eval
						eval(getSegmentSnippet());
					},
					() => {
						attempts++;
						return !!window.deltaAnalytics || attempts >= 50;
					}
				);
			}
		});

		if (
			location.pathname.includes('callback') ||
			location.pathname.includes('sso') ||
			location.pathname.includes('single-click-purchase')
		) {
			document.body.classList.add('blank');
		}

		executeWithinBoundary(async () => {
			if (!userHasPurchased) {
				transaction.Message.Publish(Messages.LoadingCheckout);
			}
			await featureFlagService.Initialize();
			transaction.Message.Publish(Messages.Empty);
			Transaction.Destroy();

			transaction = Transaction.Instance(defaultSteps());
			if (typeof window !== 'undefined') {
				await AmacIntegration.Instance().Initialize();
			}
			await navigateUserToFirstStep(router);
			router.events.on('routeChangeComplete', handleEventsOnRouteChange);

			window.addEventListener('popstate', (popEvent) => {
				analytics.TrackInteraction(backButtonClicked(Locations.Browser));

				if (!popEvent.state?.url) {
					history.back();
				}
			});

			if (!Environments.isProduction()) {
				window['transaction'] = transaction;
				window['featureFlag'] = featureFlagService;
			}

			setFeatureFlagsLoaded(true);
			setErrorSub();
		});

		return () => {
			getTransactionError().Cancel(errorSub);
			router.events.off('routeChangeComplete', handleEventsOnRouteChange);
		};
	}, []);

	useEffect(() => {
		if (featureFlagsLoaded) {
			safelyExecute(() => {
				loadDataLayerFunc();
				if (featureFlagService.IsEnabled(FeatureFlags.ScheduledMaintenance) && window.deltaAnalytics) {
					window.deltaAnalytics.addProperty('scheduled_maintenance', true);
				}
				if (![Routes.SignupWelcome, Routes.PostTransactionReferral].some((r) => location.pathname.includes(r))) {
					const checkoutStartedEvent = new CheckoutStartedEvent({
						products: []
					});
					analytics.TrackEvent(checkoutStartedEvent);
				}
			});
		}
	}, [featureFlagsLoaded]);

	const showLoadingState = !!globalThis?.location && !location.href.includes(Routes.SingleClickPurchase);

	return (
		<>
			<Head>
				<script dangerouslySetInnerHTML={{ __html: 'window.deltaContainerOptions = { alsoAddStylesToBody: true };' }}></script>
				<script type="text/javascript" src="https://cdn.usconcealedcarry.com/scripts/bundles/delta-scripts-container/1.*.*.js?1"></script>
			</Head>

			<Loading
				forcedMessage={featureFlagsLoaded ? undefined : userHasPurchased ? Messages.FinalizingPurchase : Messages.LoadingCheckout}
				disableBackground={!!!featureFlagsLoaded}
			/>
			{!browserContext ||
				(featureFlagsLoaded ? (
					<>
						<ErrorBoundary transaction={Transaction.Instance()} analyticsService={Analytics.Instance()}>
							<Component {...pageProps} featureFlagLoaded={featureFlagsLoaded} />
						</ErrorBoundary>
						{onSignUpRoute && <BonusesDrawer />}
						<ErrorModal />
					</>
				) : (
					<>
						{showLoadingState && (
							<>
								<Layout options={userHasPurchased ? {} : { useInitialLoadingStyles: true }}></Layout>
							</>
						)}
					</>
				))}

			<script type="text/javascript" src="https://apisandboxstatic.zuora.com/Resources/libs/hosted/1.3.1/zuora-min.js"></script>
			<noscript>
				<div className="noscript">
					<svg xmlns="http://www.w3.org/2000/svg" width="70" height="56" viewBox="0 0 70 56">
						<path
							id="display-slash"
							// eslint-disable-next-line max-len
							d="M59.5,3.5A3.5,3.5,0,0,1,63,7V38.5a3.3,3.3,0,0,1-.079.393l3.007,2.374A6.97,6.97,0,0,0,66.5,38.5V7a7,7,0,0,0-7-7H13.657L18.09,3.5Zm-8.75,49H45.227l-2.074-6.915L38.611,42H10.5A3.5,3.5,0,0,1,7,38.5V17.042L3.5,14.279V38.5a7,7,0,0,0,7,7H26.873l-2.1,7H19.25a1.75,1.75,0,0,0,0,3.5h31.5a1.75,1.75,0,0,0,0-3.5Zm-22.323,0,2.1-7h8.946l2.1,7Zm40.908.376L2.835.374A1.751,1.751,0,0,0,.665,3.122l66.5,52.5a1.751,1.751,0,1,0,2.17-2.748Z"
							transform="translate(0 0)"
							fill="#003a70"
						/>
					</svg>
					<h1>JavaScript is disabled.</h1>
					<p>
						We're sorry, this website requires JavaScript to be enabled. If disabled, some features will not work as expected.<br></br>
						<br></br>For immediate assistance, please call us at&nbsp;
						<a href="tel:+800-674-9779">800-674-9779</a>.
					</p>
					<style jsx>{`
						.noscript {
							width: 342px;
							font-family: 'Roboto', sans-serif;
							display: flex;
							flex-direction: column;
							align-items: center;
							justify-content: center;
							margin-left: auto;
							margin-right: auto;
							height: 65vh;
						}

						h1 {
							color: #121212;
							letter-spacing: 0.32px;
							color: #121212;
							opacity: 1;
							font-weight: normal;
							margin: 21.4px 0px;
							font-size: 2rem;
						}

						p {
							margin-top: 10px;
							color: #747678;
							font:
								normal normal normal 18px/26px 'Roboto',
								sans-serif;
						}

						a {
							text-decoration: none;
							color: #0474e0;
						}

						@media (min-width: 576px) {
							h1 {
								font-weight: normal !important;
								margin: 21.4px 0px !important;
								font-size: 2rem !important;
							}
						}
					`}</style>
				</div>
			</noscript>
		</>
	);
}
