/* eslint-disable complexity */
import { Step, Field, ITransaction, Transaction } from '../../../core/domain/module';
import { Messages } from '../../../core/enums/messages';
import { Analytics, InternalEventNames, Steps } from '../../../core/services/analytics/module';
import { FieldTypes } from '../../fieldTypes';
import { required, maxLength, minLength } from '../../fieldValidations/module';
import { DefaultSteps } from '../../default';
import { processVerification } from '../../utilities/module';

export enum VerificationLoginFields {
	Code = 'code'
}

const fields = {
	[VerificationLoginFields.Code]: new Field(
		VerificationLoginFields.Code,
		'Verification Code',
		FieldTypes.VerificationCode,
		'A six digit verification code is required.',
		[required, minLength(6), maxLength(6)]
	)
};

export const VerificationLogin = (
	analytics = Analytics.Instance(),
	processVerificationFunc = processVerification,
	transaction?: ITransaction
) =>
	new Step(fields, async () => {
		transaction = transaction || Transaction.Instance();

		analytics.TrackInternal({
			event_name: InternalEventNames.VerificationCodeEntered,
			step: Steps.One
		});

		const fields = transaction.Steps[DefaultSteps.VerificationLogin].Fields;
		const verificationCode = fields[VerificationLoginFields.Code].GetValue();

		transaction.Message.Publish(Messages.LoggingIn);
		const result = await processVerificationFunc(verificationCode, transaction.Data.email);
		transaction.Message.Publish(Messages.Empty);

		return {
			wasSuccessful: result
		};
	});
