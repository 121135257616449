/* eslint-disable max-lines */
import { IFeatureFlagRepository } from '@delta-defense/feature-flag-service/service/iFeatureFlagRepository';
import { FeatureFlagService } from '@delta-defense/feature-flag-service';
import { AddressValidation } from '@delta-defense/address-validation-service/services/addressValidation/addressValidation';
import { Address } from '@delta-defense/address-validation-service/models/address';
import { Field, FieldValidation, ITransaction, Result, Step, Transaction } from '../../../core/domain/module';
import { DismissibleErrors, FeatureFlags, Messages, Regex } from '../../../core/enums/module';
import { StateAbbr } from '../../../core/enums/stateAbbreviations';
import { Customer } from '../../../core/models/module';
import { User } from '../../../core/models/users';
import { Analytics, IAnalytics } from '../../../core/services/analytics/module';
import { Auth, IAuth } from '../../../core/services/auth/auth';
import { ISubscriptions, Subscriptions } from '../../../core/services/module';
import { stateOptions } from '../../../stateOptions';
import { FieldTypes } from '../../fieldTypes';
import { required, maxLength, pattern, conditional } from '../../fieldValidations/module';
import { DefaultSteps } from '../../default';
import { queryWithRedundancy } from '../../../utilities/queryWithRedundancy';
import { usersCurrentBundleIncompatibleWithState } from '../../utilities/usersCurrentBundleIncompatibleWithState';

type AddressData = {
	address: string;
	address_2?: string;
	city: string;
	state: string;
	postal_code: string;
	first_name?: string;
	last_name?: string;
	phone_number?: string;
};

export enum AddressInfoFields {
	FirstName = 'firstName',
	LastName = 'lastName',
	ShippingAddress = 'shippingAddress',
	ShippingCity = 'shippingCity',
	ShippingState = 'shippingState',
	ShippingZip = 'shippingZip',
	Phone = 'phone',
	BillingSameAsShipping = 'billingSameAsShipping',
	BillingAddress = 'billingAddress',
	BillingCity = 'billingCity',
	BillingState = 'billingState',
	BillingZip = 'billingZip',
	BillingAddressTwo = 'billingAddressTwo'
}

const isBillingDifferentFromShipping = (transaction = Transaction.Instance()) => {
	return (
		transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.BillingSameAsShipping].GetValue() !== 'checked'
	);
};

const billingValidations: (validations: FieldValidation[], transaction?: ITransaction) => FieldValidation[] = (
	validations,
	transaction
) => {
	return validations.map((validation) => {
		return conditional(() => isBillingDifferentFromShipping(transaction), validation);
	});
};

export const AddressInfo = (
	auth = Auth.Instance(),
	analytics = Analytics.Instance(),
	subscriptions = Subscriptions.Instance(),
	featureFlag = FeatureFlagService.Instance(),
	transaction?: ITransaction,
	usersCurrentBundleIncompatibleWithStateFunc = usersCurrentBundleIncompatibleWithState
) =>
	new Step(
		{
			[AddressInfoFields.FirstName]: new Field(
				AddressInfoFields.FirstName,
				'First Name',
				FieldTypes.Text,
				'First name is required.',
				[required, maxLength(40)]
			),
			[AddressInfoFields.LastName]: new Field(
				AddressInfoFields.LastName,
				'Last Name',
				FieldTypes.Text,
				'Last name is required.',
				[required, maxLength(80)]
			),
			[AddressInfoFields.ShippingAddress]: new Field(
				AddressInfoFields.ShippingAddress,
				'Address 1',
				FieldTypes.AddressLine1,
				'Address 1 is required.',
				[required, maxLength(255)]
			),
			[AddressInfoFields.ShippingCity]: new Field(
				AddressInfoFields.ShippingCity,
				'City',
				FieldTypes.City,
				'City is required.',
				[required, maxLength(40)]
			),
			[AddressInfoFields.ShippingState]: new Field(
				AddressInfoFields.ShippingState,
				'State',
				FieldTypes.StateSelect,
				'State is required.',
				[required]
			),
			[AddressInfoFields.ShippingZip]: new Field(
				AddressInfoFields.ShippingZip,
				'ZIP Code',
				FieldTypes.PostalCode,
				'5 digit ZIP Code is required.',
				[required, pattern(Regex.ZipCode)]
			),
			[AddressInfoFields.Phone]: new Field(
				AddressInfoFields.Phone,
				'Phone Number',
				FieldTypes.Phone,
				'Enter a 10 digit phone number.',
				[pattern(Regex.Phone)]
			),
			[AddressInfoFields.BillingSameAsShipping]: new Field(
				AddressInfoFields.BillingSameAsShipping,
				'Use this for my billing address.',
				FieldTypes.Checkbox,
				'',
				[]
			),
			[AddressInfoFields.BillingAddress]: new Field(
				AddressInfoFields.BillingAddress,
				'Address 1',
				FieldTypes.AddressLine1,
				'Address 1 is required.',
				billingValidations([required, maxLength(255)], transaction)
			),
			[AddressInfoFields.BillingAddressTwo]: new Field(
				AddressInfoFields.BillingAddressTwo,
				'Address 2 (not required)',
				FieldTypes.AddressLine2,
				'Address 2 cannot exceed 255 characters.',
				billingValidations([maxLength(255)], transaction)
			),
			[AddressInfoFields.BillingCity]: new Field(
				AddressInfoFields.BillingCity,
				'City',
				FieldTypes.City,
				'City is required.',
				billingValidations([required, maxLength(40)], transaction)
			),
			[AddressInfoFields.BillingState]: new Field(
				AddressInfoFields.BillingState,
				'State',
				FieldTypes.StateSelect,
				'State is required.',
				billingValidations([required], transaction)
			),
			[AddressInfoFields.BillingZip]: new Field(
				AddressInfoFields.BillingZip,
				'ZIP Code',
				FieldTypes.PostalCode,
				'5 digit ZIP Code is required.',
				billingValidations([required, pattern(Regex.ZipCode)], transaction)
			)
		},
		async () => {
			transaction = transaction || (Transaction.Instance() as ITransaction);

			const result: {
				wasSuccessful: boolean;
				value: {
					updateUserValue?: User;
					customerDataValue?: Customer;
					shippingData?: AddressData;
					billingData?: AddressData;
				};
			} = {
				wasSuccessful: false,
				value: {}
			};

			result.wasSuccessful = validatePurchaseState(transaction).wasSuccessful;

			if (result.wasSuccessful) {
				transaction.Message.Publish(Messages.SavingAddress);
				const updateUserResult = await updateUser(transaction, auth, analytics, featureFlag);
				result.wasSuccessful = updateUserResult.wasSuccessful;
				result.value.updateUserValue = updateUserResult.value;
				transaction.Data.userObject = updateUserResult.value;
			}

			if (result.wasSuccessful) {
				const customerObject = transaction.Data.customerObject;
				const shippingData = getShippingDataFromFields(transaction);

				const addressValidationService = AddressValidation.Instance();
				addressValidationService.EnabledWhen = () => featureFlag.IsEnabled(FeatureFlags.AddressValidation);
				const bestAddress = await addressValidationService.GetBestAddress(
					{
						address1: shippingData.address,
						address2: '',
						city: shippingData.city,
						state: shippingData.state,
						postalCode: shippingData.postal_code
					},
					() => {
						transaction?.Message.Publish(Messages.Empty);
					},
					() => {
						transaction?.Message.Publish(Messages.SavingAddress);
					}
				);
				if (bestAddress) {
					shippingData.address = bestAddress.address1;
					shippingData.city = bestAddress.city;
					shippingData.state = bestAddress.state;
					shippingData.postal_code = bestAddress.postalCode;
					setBestAddressInFields(transaction, bestAddress);
				} else {
					return {
						wasSuccessful: false,
						value: {
							cancelledAddressModal: true
						}
					};
				}
				const currentPromoBundleIncompatibleWithState = await usersCurrentBundleIncompatibleWithStateFunc(shippingData.state as StateAbbr);
				if (currentPromoBundleIncompatibleWithState) {
					return {
						wasSuccessful: false,
						value: {
							fellBackToDefaultBundles: true
						}
					};
				}

				const billingData = getBillingDataFromFields(transaction);

				result.value.shippingData = shippingData;
				result.value.billingData = billingData;
				if (customerObject && customerObject.id) {
					const updateCustomerResult = await updateCustomer(
						customerObject,
						shippingData,
						billingData,
						subscriptions,
						transaction
					);
					result.wasSuccessful = updateCustomerResult.wasSuccessful;
					result.value.customerDataValue = updateCustomerResult.value;
					transaction.Data.customerObject = updateCustomerResult.value;
				} else {
					const createCustomerResult = await createCustomer(shippingData, billingData, subscriptions, transaction);
					result.value.customerDataValue = createCustomerResult.value;
					result.wasSuccessful = createCustomerResult.wasSuccessful;
					transaction.Data.customerObject = createCustomerResult.value;
				}
			}

			transaction.Message.Publish(Messages.Empty);
			return result;
		}
	);

const validatePurchaseState = (transaction: ITransaction): Result => {
	const result = {
		wasSuccessful: true
	};

	const mainProduct = transaction.Data.purchaseProducts?.primary || transaction.Data.purchaseProducts?.spouse;
	const purchaseState = stateOptions.find(
		(state) =>
			state.value ==
			(transaction as ITransaction).Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.ShippingState].GetValue()
	);

	if (
		mainProduct?.attributes.tags.find((tag) => tag.urlSafe == 'membership') &&
		purchaseState?.membershipsAvailable == false
	) {
		result.wasSuccessful = false;
		transaction.Error.Publish(DismissibleErrors.InvalidMembershipLocation);
	}

	return result;
};

const updateUser = async (
	transaction: ITransaction,
	auth: IAuth,
	analytics: IAnalytics,
	featureFlag: IFeatureFlagRepository
): Promise<Result<User>> => {
	const result: Result<User> = {
		wasSuccessful: true
	};

	const userUpdateBody: Partial<{
		name: string;
		surname: string;
		phone: string;
	}> = {
		name: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.FirstName].GetValue(),
		surname: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.LastName].GetValue(),
		phone: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.Phone].GetValue()
	};

	if (!!transaction.UpgradingFromLevel) {
		delete userUpdateBody.name;
		delete userUpdateBody.surname;
	}

	const updateUserResult = await auth.updateUser(userUpdateBody);

	if (updateUserResult.wasSuccessful && updateUserResult.value) {
		result.value = updateUserResult.value;
		userUpdateBody.phone && window.deltaAnalytics?.submitLead({
			email: transaction.Data.email,
			phone: userUpdateBody.phone
		});
		await analytics.Identify(updateUserResult.value);
		await featureFlag.Identify();
	} else {
		result.wasSuccessful = false;
		transaction.Error.Publish(DismissibleErrors.ErrorUpdatingUser);
	}

	return result;
};

const updateCustomer = async (
	customerObject: Customer,
	shippingData: AddressData,
	billingData: AddressData,
	subscriptions: ISubscriptions,
	transaction: ITransaction
): Promise<Result<Customer>> => {
	const result: Result<Customer> = {
		wasSuccessful: true
	};

	const oldBilling = customerObject.attributes.billingAddress;
	const oldShipping = customerObject.attributes.shippingAddress;

	if (
		JSON.stringify(shippingData) !== JSON.stringify(oldShipping) ||
		JSON.stringify(billingData) !== JSON.stringify(oldBilling)
	) {
		const shippingAddress = {
			address1: shippingData.address,
			city: shippingData.city,
			state: shippingData.state,
			postalCode: shippingData.postal_code,
			country: 'USA'
		};

		const billingAddress = {
			address1: billingData.address,
			address2: billingData.address_2,
			city: billingData.city,
			state: billingData.state,
			postalCode: billingData.postal_code,
			country: 'USA'
		};

		const customerDataResult = await queryWithRedundancy<Result<Customer>>(
			() => subscriptions.updateCustomer(
				customerObject.id,
				shippingAddress,
				billingAddress,
				shippingData?.phone_number
			),
			t => !!(t?.wasSuccessful && t?.value), 3000, 1);

		if (customerDataResult.wasSuccessful && customerDataResult.value) {
			transaction.Data.customerObject = customerDataResult.value;
			result.value = transaction.Data.customerObject;
		} else {
			result.wasSuccessful = false;
			transaction.Error.Publish(DismissibleErrors.ErrorUpdatingCustomer);
		}
	}

	return result;
};

const createCustomer = async (
	shippingData: AddressData,
	billingData: AddressData,
	subscriptions: ISubscriptions,
	transaction: ITransaction
): Promise<Result<Customer>> => {
	const result: Result<Customer> = {
		wasSuccessful: true
	};

	const shippingAddress = {
		address1: shippingData.address,
		city: shippingData.city,
		state: shippingData.state,
		postalCode: shippingData.postal_code,
		country: 'USA'
	};

	const billingAddress = {
		address1: billingData.address,
		address2: billingData.address_2,
		city: billingData.city,
		state: billingData.state,
		postalCode: billingData.postal_code,
		country: 'USA'
	};

	const customerDataResult = await subscriptions.createCustomer(
		shippingAddress,
		billingAddress,
		shippingData.phone_number
	);

	if (customerDataResult.wasSuccessful && customerDataResult.value) {
		transaction.Data.customerObject = customerDataResult.value;
		result.value = transaction.Data.customerObject;
	} else {
		result.wasSuccessful = false;
		transaction.Error.Publish(DismissibleErrors.ErrorCreatingCustomer);
	}

	return result;
};

const getShippingDataFromFields = (transaction: ITransaction) => {
	return {
		first_name: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.FirstName].GetValue(),
		last_name: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.LastName].GetValue(),
		address: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.ShippingAddress].GetValue(),
		city: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.ShippingCity].GetValue(),
		state: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.ShippingState].GetValue(),
		postal_code: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.ShippingZip].GetValue(),
		phone_number: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.Phone].GetValue()
	};
};

export const setBestAddressInFields = (
	transaction: ITransaction,
	bestAddress: Address | null,
	documentObj = document
) => {

	if (bestAddress) {
		const billingSameAsShipping =
			transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.BillingSameAsShipping].GetValue() === 'checked';

		const inputFieldKeys = {
			address1: billingSameAsShipping ? AddressInfoFields.ShippingAddress : AddressInfoFields.BillingAddress,
			city: billingSameAsShipping ? AddressInfoFields.ShippingCity : AddressInfoFields.BillingCity,
			state: billingSameAsShipping ? AddressInfoFields.ShippingState : AddressInfoFields.BillingState,
			postalCode: billingSameAsShipping ? AddressInfoFields.ShippingZip : AddressInfoFields.BillingZip
		};

		const inputElements = {
			address1: <HTMLInputElement | null>documentObj.getElementById(inputFieldKeys.address1),
			city: <HTMLInputElement | null>documentObj.getElementById(inputFieldKeys.city),
			state: <HTMLInputElement | null>documentObj.getElementById(inputFieldKeys.state),
			postalCode: <HTMLInputElement | null>documentObj.getElementById(inputFieldKeys.postalCode)
		};

		Object.keys(inputElements).forEach((k) => {
			const element = inputElements[k];
			if (element && bestAddress[k]) {
				element.value = bestAddress[k];
				element.dispatchEvent(new Event('change'), { target: {value: bestAddress[k]}});
			}
		});
	}
};


const getBillingDataFromFields = (transaction: ITransaction) => {
	return transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.BillingSameAsShipping].GetValue() === 'checked' ? {
		address: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.ShippingAddress].GetValue(),
		city: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.ShippingCity].GetValue(),
		state: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.ShippingState].GetValue(),
		postal_code: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.ShippingZip].GetValue()
	} : {
		address: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.BillingAddress].GetValue(),
		address_2: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.BillingAddressTwo].GetValue(),
		city: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.BillingCity].GetValue(),
		state: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.BillingState].GetValue(),
		postal_code: transaction.Steps[DefaultSteps.Shipping].Fields[AddressInfoFields.BillingZip].GetValue()
	};
};
