import * as jsCookie from 'js-cookie';
import { Environments } from '@delta-defense/client-environments';
import { safelyReturn } from '../../../utilities/safelyReturn';

export type CookieInterface = {
	get(key: string, attributes?: object): string;
	remove(key: string, attributes?: object): void;
	set(key: string, value: string, attributes?: object): void;
};

export enum CookieKeys {
	AccessToken = 'uscca_access',
	SSO = 'uscca_sso',
	Leads = 'uscca_lead_fields',
	Referral = 'uscca_referral',
	CheckoutTime = 'uscca_checkout_time',
}

export interface ICookies {
	get(key: CookieKeys): string;
	set(key: CookieKeys, value: string): void;
	delete(key: CookieKeys): void;
	decodeToken<T>(token: string): T | null;
}

export class Cookies implements ICookies {
	private static instance: ICookies | null = null;

	public static Instance(cookies = jsCookie as CookieInterface): ICookies {
		return this.instance || (this.instance = new Cookies(cookies));
	}

	public static Destroy() {
		this.instance = null;
	}

	private constructor(private cookies: CookieInterface) { }

	public get(key: CookieKeys): string {
		return this.cookies.get(key) || '';
	}

	public set(key: CookieKeys, value: string): void {
		this.cookies.set(key, value, { expires: 365, domain: Environments.getDeltaDefenseCookieDomain() });
	}

	public delete(key: CookieKeys): void {
		this.cookies.remove(key, { domain: Environments.getDeltaDefenseCookieDomain() });
	}

	public decodeToken = <T>(token: string): T | null => {
		return safelyReturn<T | null>(() => {
			const encodedValue = token.split('.')[1];
			return JSON.parse(window.atob(encodedValue));
		}, null);
	};
}
