import { FeatureFlagService } from '@delta-defense/feature-flag-service';
import { Transaction } from '../core/domain/module';
import { QueryParams, Routes } from '../core/enums/module';
import { getQueryParameter } from './getQueryParameter';

const RouteBackLinkRecord: Record<Routes, Routes | ''> = {
	[Routes.Root]: '',
	[Routes.Email]: '',
	[Routes.Existing]: '',
	[Routes.SignupVerification]: Routes.Email,
	[Routes.SignupPassword]: Routes.SignupVerification,
	[Routes.SignupNextRegistration]: Routes.Email,
	[Routes.SignupRegistration]: Routes.SignupNextRegistration,
	[Routes.SignupLevel]: '',
	[Routes.SignupChart]: Routes.SignupLevel,
	[Routes.SignupFrequency]: Routes.SignupChart,
	[Routes.SignupSpouse]: Routes.SignupFrequency,
	[Routes.SignupNextShipping]: Routes.SignupSpouse,
	[Routes.SignupShipping]: Routes.SignupNextShipping,
	[Routes.SignupNextPayment]: Routes.SignupShipping,
	[Routes.SignupPayment]: Routes.SignupNextPayment,
	[Routes.SignupSummary]: Routes.SignupPayment,
	[Routes.ProductPTU]: '',
	[Routes.SignupWelcome]: '',
	[Routes.SingleClickPurchase]: '',
	[Routes.SSO]: '',
	[Routes.PostTransactionUpdatePassword]: '',
	[Routes.PostTransactionReferral]: '',
	[Routes.CartAbandonment]: '',
	[Routes.Error]: ''
};

export const getRouteBackLink = (
	currentRoute: Routes,
	features = FeatureFlagService.Instance(),
	locationSearch = location.search,
	transaction = Transaction.Instance()
): Routes | '' => {
	const defaultBackLink = RouteBackLinkRecord[currentRoute];
	const productIdSet = !!getQueryParameter(QueryParams.ProductId, locationSearch);

	const shouldDisableBackLinkFunctions: (() => boolean)[] = [
		() => currentRoute === Routes.SignupFrequency && !!getQueryParameter(QueryParams.ProductId, locationSearch),
		() => currentRoute === Routes.SignupSpouse && transaction.SpouseOnlyPurchase,
		() => currentRoute === Routes.SignupSpouse && transaction.IsSameLevelMonthlyToAnnualUpgrade(),
		() => currentRoute === Routes.SignupNextShipping &&
			transaction.Data.purchaseProducts !== undefined &&
			transaction.Data.purchaseProducts?.primary?.attributes.tier === 'Concealed Carry Magazine',
		() => currentRoute === Routes.PostTransactionReferral,
		() => currentRoute === Routes.SignupNextShipping &&
			!!transaction.SpouseSub &&
			transaction.IsSameLevelMonthlyToAnnualUpgrade()
	];

	const alternativeBackLinkFunctions: (() => Routes | false)[] = [
		() => (currentRoute === Routes.SignupSpouse && !!transaction.UpgradingFromLevel ? Routes.SignupChart : false),
		() =>
			transaction.SpouseOnlyPurchase && [Routes.SignupNextShipping, Routes.SignupShipping].includes(currentRoute)
				? Routes.SignupSpouse
				: false,
		() => {
			if (
				[Routes.SignupNextShipping].includes(currentRoute) &&
				!!transaction.UpgradingFromLevel && !!transaction.SpouseSub
			) {
				return productIdSet ? Routes.SignupFrequency : Routes.SignupChart;
			}
			return false;
		}
	];

	const shouldDisableBackLink = !!shouldDisableBackLinkFunctions.find(f => f());
	const backLink = alternativeBackLinkFunctions.find(f => f())?.() || defaultBackLink;
	return shouldDisableBackLink ? '' : backLink;
};
