export enum QueryParams {
	AmacGuid = 'amac_account_guid',
	Level = 'level',
	Tid = 'tID',
	Email = 'email',
	ProductId = 'productId',
	ProductType = 'productType',
	FunnelId = 'funnel_id',
	ReferralCode = 'referralCode',
	ReferringMemberName = 'member_name',
	Spouse = 'spouse',
	Type = 'type',
	Ps = 'ps',
	ErrorPurchasingPtu = 'errorPurchasingPtu',
	PtuExist = 'ptuExist',
	PtuBundleId = 'ptuBundleId',
	PtuType = 'ptuType',
	SubscriptionId = 'subscriptionId',
	CartGuid = 'cartGuid',
	Csr = 'csr',
	Cart = 'cart',
	ZExId = 'zExId',
	Ptype = 'ptype',
	CheckoutType = 'checkoutType',
	Features = 'features',
	AffId = 'aff_id',
	OfferId = 'offer_id',
	AffLsr = 'aff_lsr',
	AffSub = 'aff_sub',
	AffSub2 = 'aff_sub2',
	AffSub3 = 'aff_sub3',
	AffSub4 = 'aff_sub4',
	AffSub5 = 'aff_sub5',
	AffClickId = 'aff_click_id',
	AffUnique1 = 'aff_unique1',
	AffUnique2 = 'aff_unique2',
	AffUnique3 = 'aff_unique3',
	AffUnique4 = 'aff_unique4',
	AffUnique5 = 'aff_unique5',
	Mid = 'mid',
	J = 'j',
	SfmcSub = 'sfmc_sub',
	L = 'l',
	U = 'u',
	Jb = 'jb',
	CtaText = 'ctaText',
	UseLegacyProductPTU = 'useLegacyProductPTU'
}
