import { DisplayIneligiblePromoModalObservable } from '../../components/ineligiblePromoModal/ineligiblePromoModal';
import { Transaction } from '../../core/domain/module';
import { StateAbbr } from '../../core/enums/stateAbbreviations';
import { StateRestricted } from '../../core/enums/stateRestricted';
import { stateSpecificRestrictedFunnels } from '../../core/services/bundle/funnelConfigurations';
import { Bundle, Tracking } from '../../core/services/module';
import { stateOptions } from '../../stateOptions';

export async function usersCurrentBundleIncompatibleWithState(
	state: StateAbbr,
	tracking = Tracking.Instance(),
	bundleService = Bundle.Instance(),
	transaction = Transaction.Instance()
) {
	const funnelId = await tracking.getFunnelId();
	const stateHasAmmunitionRestrictions = stateOptions
		.filter((s) => s.restrictions?.includes(StateRestricted.AmmunitionShipping))
		.find((s) => s.value === state);
	if (
		stateHasAmmunitionRestrictions &&
		funnelId.wasSuccessful &&
		funnelId.value &&
		stateSpecificRestrictedFunnels[StateRestricted.AmmunitionShipping].includes(funnelId.value)
	) {
		const userProceededWithDefaultBundle = transaction.useDefaultBundle;
		const defaultBundle = await bundleService.getDefaultBundleProductsForAllLevels();
		if (defaultBundle.wasSuccessful && defaultBundle.value && !userProceededWithDefaultBundle) {
			DisplayIneligiblePromoModalObservable.Publish(defaultBundle.value);
			return true;
		}
	}
	return false;
}
