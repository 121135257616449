import { doWhen } from './doWhen';

type TechnicalDifficultiesModalEnabledSubscription = {
	Subscribe: (func: (enabled: boolean) => void) => string;
	Publish(enabled: boolean): void;
};

const getModalObservable = (mainDocument: Document) => mainDocument.querySelector('technical-difficulties-modal')
	?.['Enabled'] as TechnicalDifficultiesModalEnabledSubscription | undefined;

export const toggleTechnicalDifficultiesModal = (enabled: boolean, mainDocument = document) => {
	doWhen(() => {
		const technicalDifficultiesModalSubscription = getModalObservable(mainDocument);
		(technicalDifficultiesModalSubscription as TechnicalDifficultiesModalEnabledSubscription).Publish(enabled);
	}, () => !!getModalObservable(mainDocument));
};

export const executeWhenTechnicalDifficultiesModalEnabled = (func: () => void, mainDocument = document) => {
	doWhen(() => {
		const technicalDifficultiesModalSubscription = getModalObservable(mainDocument);
		(technicalDifficultiesModalSubscription as TechnicalDifficultiesModalEnabledSubscription).Subscribe((enabled) => {
			enabled ? func() : null;
		});
	}, () => !!getModalObservable(mainDocument));
};
