import React from 'react';
import { BreakingErrors } from '../../core/enums/breakingErrors';

export const BreakingErrorMap = new Map<BreakingErrors, JSX.Element>([
	[
		BreakingErrors.FailedToLoadProducts,
		<>
			<p>There was a technical error while loading your checkout experience. We sincerely apologize for the inconvenience.</p>
		</>
	],
	[
		BreakingErrors.FailedToLoadUserData,
		<>
			<p>There was a technical error while loading your account information. We sincerely apologize for the inconvenience.</p>
		</>
	],
	[
		BreakingErrors.FailedToLoadCustomerData,
		<>
			<p>There was a technical error while loading your account information. We sincerely apologize for the inconvenience.</p>
		</>
	],
	[
		BreakingErrors.ClientSideError,
		<>
			<p>There was client error while performing your checkout experience. We sincerely apologize for the inconvenience.</p>
		</>
	]
]);
