export enum Messages {
	Empty = '',
	LoadingCheckout = 'Loading your secure checkout',
	LoadingShippingAndBilling = 'Loading Shipping & Billing',
	LoadingYourInvoice = 'Loading your invoice',
	CheckingPaymentMethods = 'Checking for existing payment methods...',
	AddingYourPayment = 'Adding Payment Method...',
	LoadingPaymentSystem = 'Loading Payment System',
	SavingAddress = 'Saving Address',
	CreatingAccount = 'Creating Account...',
	UpdatingPassword = 'Updating your password...',
	CheckingYourEmail = 'Checking your email',
	LoadingProductsForLevel = 'Loading products for level',
	LoadingCustomerInformation = 'Loading customer information',
	LoggingIn = 'Logging In...',
	ProcessingPayment = 'Processing Payment...',
	FinalizingPurchase = 'Finalizing Purchase',
	SendingEmail = 'Sending Email...',
	LoadingBundleProducts = 'Loading Bundle Products',
	LoadingAMAC = 'Loading AMAC account extension',
}
