import { InternalEventNames, EventTypes } from '../analytics/enums/module';
import { IAnalytics, Analytics } from '../analytics/analytics';
import { getQueryParameters } from '../../../utilities/getQueryParameter';
import { QueryParams } from '../../enums/queryParams';
import { IRest, Rest } from '../rest/rest';
import { EventData } from './eventData';
import { SessionStorageKeys } from '../browserStorage/keys/sessionStorageKeys';
import { BrowserStorage, ISessionStorage } from '../browserStorage/browserStorage';

export interface ICartAbandonment {
	Enabled: boolean;
	Enable(): void;
	Disable(): void;
	RestoreAbandonedCart(accessToken: string): Promise<boolean>;
}

export class CartAbandonment implements ICartAbandonment {
	private static instance: ICartAbandonment | null = null;
	public static Instance(
		getWindow = () => globalThis.window,
		rest = Rest.Instance(),
		getAnalytics = () => Analytics.Instance(),
		mainSessionStorage = BrowserStorage.SessionInstance()
	): ICartAbandonment {
		return this.instance || (this.instance = new CartAbandonment(getWindow, rest, getAnalytics, mainSessionStorage));
	}
	public static Destroy = () => (CartAbandonment.instance = null);

	public get Enabled(): boolean {
		return this.mainSessionStorage.getItem(SessionStorageKeys.UserOnCartAbandonmentPath) === 'true';
	}

	private constructor(
		private getWindow: () => typeof globalThis.window,
		private rest: IRest,
		private getAnalytics: () => IAnalytics,
		private mainSessionStorage: ISessionStorage
	) { }

	public Enable(): void {
		this.mainSessionStorage.setItem(SessionStorageKeys.UserOnCartAbandonmentPath, 'true');
	}

	public Disable(): void {
		this.mainSessionStorage.removeItem(SessionStorageKeys.UserOnCartAbandonmentPath);
	}

	public async RestoreAbandonedCart(accessToken: string): Promise<boolean> {
		if (this.shouldRestoreCart(accessToken)) {
			this.Disable();

			const uri = `/api/segment/events?include=${EventTypes.StateOfCart}&limit=1`;
			const response = await this.rest.Get<{
				data: EventData<{
					checkoutLastKnownRoute: string;
					checkoutTransactionData: string;
				}>[];
			}>(uri, {
				'X-Auth-Token': accessToken
			});

			if (response.ok && response.body?.data?.[0]) {
				const lastStateOfCartEvent = response.body.data[0];
				const newLastKnownRouteValue = this.getExistingRouteWithUpdatedTid(
					lastStateOfCartEvent.properties.checkoutLastKnownRoute
				);
				try {
					this.mainSessionStorage.setItem(SessionStorageKeys.LastKnownRoute, newLastKnownRouteValue);
					this.mainSessionStorage.setStringValue(SessionStorageKeys.TransactionData, lastStateOfCartEvent.properties.checkoutTransactionData);
					this.getAnalytics().TrackInternal({ event_name: InternalEventNames.SuccessfullyRestoredCart });
					this.getWindow().location.href = newLastKnownRouteValue;
					return true;
				} catch (error) {
					this.getAnalytics().TrackInternal({ event_name: InternalEventNames.FailedToRestoreCart });
				}
			} else {
				this.getAnalytics().TrackInternal({ event_name: InternalEventNames.FailedToRestoreCart });
			}
		}
		return false;
	}

	private shouldRestoreCart(accessToken?: string): boolean {
		return !!accessToken && this.Enabled;
	}

	private getExistingRouteWithUpdatedTid(lastKnownRoute: string): string {
		const newParams = getQueryParameters(this.getWindow().location.search);
		const lastKnownParams = getQueryParameters(lastKnownRoute.indexOf('?') ? lastKnownRoute.split('?')[1] : '');

		if (lastKnownRoute.includes('?')) {
			Array.from(newParams.keys()).forEach((key) => {
				const value = newParams.get(key as QueryParams);
				if (value) {
					lastKnownParams.set(key as QueryParams, value);
				}
			});

			return `${lastKnownRoute.split('?')[0]}?${lastKnownParams.toString()}`;
		} else if (Array.from(newParams.keys()).length) {
			return `${lastKnownRoute}?${newParams.toString()}`;
		} else {
			return lastKnownRoute;
		}
	}
}
