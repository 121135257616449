import React from 'react';
import { Analytics, IAnalytics, Locations, supportNumberClicked } from '../../core/services/analytics/module';
import styles from './SupportPhoneLink.module.scss';

type Props = {
	location?: Locations,
	analytics?: IAnalytics,
	additionalEventsData?: Record<string, string>
}

export const SupportPhoneLink: React.FunctionComponent<Props> = ({
	location,
	analytics = Analytics.Instance(),
	additionalEventsData
}) => <a className={styles.phoneLink} onClick={() => {
	if (location) {
		analytics.TrackInteraction(supportNumberClicked(location, undefined, additionalEventsData));
	}
}} href="tel:1-855-388-4077">855-388-4077</a>;
